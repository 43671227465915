import axios from "axios";
const url = process.env.VUE_APP_BACKEND_URL + '/api/v1/wallet';
export class WalletApi {

    public async getCoin() {
        try {
            const call = await axios.get(url + '/get-coin', {
                withCredentials: true
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getTransactions(ref: string, token: string, symbol?: string) {
        try {
            const call = await axios.get(url + '/transactions', {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token },
                params: {
                    ref,
                    symbol
                }
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getTransactionsTelegram(ref: string, data: any, symbol?: string) {
        try {
            const call = await axios.get(url + '/telegram-transactions', {
                withCredentials: true,
                headers: data,
                params: {
                    ref,
                    symbol
                }
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getFee() {
        try {
            const call = await axios.get(url + '/fee', {
                withCredentials: true
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getWallet(token: string) {
        try {
            const call = await axios.get(url + '/get-wallet', {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token }
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getWalletTelegram(data: any) {
        try {
            const call = await axios.get(url + '/telegram-get-wallet', {
                withCredentials: true,
                headers: data
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getCoinChain(coinId: string) {
        try {
            const call = await axios.get(url + '/get-coin-chain', {
                withCredentials: true,
                params: {
                    coinId
                }
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async deposit(coinChainId: string, token: string) {
        try {
            const call = await axios.get(url + '/deposit', {
                withCredentials: true,
                params: {
                    coinChainId
                },
                headers: { 'Authorization': 'Bearer ' + token }
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async depositTelegram(coinChainId: string, data: any) {
        try {
            const call = await axios.get(url + '/telegram-deposit', {
                withCredentials: true,
                params: {
                    coinChainId
                },
                headers: data
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async swap(symbolFrom: string, symbolTo: string, amountFrom: number, token: string) {
        try {
            const call = await axios.post(url + '/swap', { symbolFrom, symbolTo, amountFrom }, {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token }
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async swapTelegram(symbolFrom: string, symbolTo: string, amountFrom: number, data: any) {
        try {
            const call = await axios.post(url + '/telegram-swap', { symbolFrom, symbolTo, amountFrom }, {
                withCredentials: true,
                headers: data
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async transfers(symbolId: string, amount: string, email: string, description: string, token: string) {
        try {
            const call = await axios.post(url + '/transfers', { symbolId, amount, email, description }, {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token }
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async transfersTelegram(symbolId: string, amount: string, email: string, description: string, data: any) {
        try {
            const call = await axios.post(url + '/telegram-transfers', { symbolId, amount, email, description }, {
                withCredentials: true,
                headers: data
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async withdraw(symbolId: string, amount: string, address: string, chainId: string, token: string) {
        try {
            const call = await axios.post(url + '/withdraw', { symbolId, amount, address, chainId }, {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token }
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async withdrawTelegram(symbolId: string, amount: string, address: string, chainId: string, data: any) {
        try {
            const call = await axios.post(url + '/telegram-withdraw', { symbolId, amount, address, chainId }, {
                withCredentials: true,
                headers: data
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }
}