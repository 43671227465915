var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-sheet',{staticClass:"text-start",staticStyle:{"background-color":"var(--color-bg-card)"},attrs:{"width":!_vm.typeMap ? '100%' : _vm.size.onSize <= 450 ? _vm.size.onSize : 450}},[_c('v-sheet',[_c('v-toolbar',{staticClass:"none-boxShadow",attrs:{"height":"40","color":"var(--color-bg-card)"}},[_c('v-btn',{staticStyle:{"border-radius":"50% !important"},attrs:{"color":"white","fab":"","depressed":"","small":"","width":"var(--size-icon-x-large)","height":"var(--size-icon-x-large)"},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-toolbar-title',{staticClass:"fs-x-large font-weight-bold d-flex justify-center",staticStyle:{"width":"90%"}},[_vm._v(" "+_vm._s(_vm.lang["TEXT_89"])+" "+_vm._s(_vm.coin.symbol)+" ")])],1),_c('v-sheet',{staticClass:"scroll-list--token",style:({
        height: _vm.size.height - 100 + 'px',
        borderRadius: '30px 30px 0px 0px',
      }),attrs:{"color":"white"}},[_c('div',{staticClass:"pa-3"},[_c('div',{staticClass:"d-flex justify-center"},[_c('div',[_c('v-btn',{staticClass:"text-none border-radius--default fs font-weight-semibold",style:({
                zIndex: '2',
              }),attrs:{"depressed":"","color":"var(--color-blue-v2)","dark":"","width":"150"}},[_vm._v(" Crypto ")]),_c('v-btn',{staticClass:"text-none fs font-weight-semibold",style:({
                marginLeft: '-15px',
                borderRadius:
                  '0px var(--border-radius-default) var(--border-radius-default) 0px !important',
              }),attrs:{"depressed":"","color":"#F1F3F8","width":"150","disabled":""}},[_vm._v(" Cash ")]),_c('div',{staticClass:"mt-3 fs-small font-weight-medium text-center"},[_c('v-icon',{attrs:{"size":"var(--size-icon-small)"}},[_vm._v("mdi-history")]),_vm._v(" Transaction ")],1)],1)]),_c('v-sheet',{staticClass:"border-radius--default mt-3",attrs:{"color":"var(--color-bg-card)"}},[_c('div',{staticClass:"pa-3"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.lang["TEXT_80"])+":")])]),_c('v-col',{staticClass:"text--opacity_06 text-end",attrs:{"cols":"6"}}),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"8"}},[_c('v-text-field',{style:({
                    borderRadius: '20px !important',
                    width: '95%',
                    backgroundColor: '#FFFFFF !important',
                  }),attrs:{"type":"number","hide-details":"","outlined":"","placeholder":"0.00","hide-spin-buttons":"","background-color":"white","dense":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{staticClass:"text-none font-weight-medium font-family--poppins",style:({
                        marginTop: '-4px !important',
                        borderRadius: '20px !important',
                      }),attrs:{"depressed":"","small":"","height":"32"},on:{"click":function($event){return _vm.max()}}},[_vm._v(" "+_vm._s(_vm.lang["TEXT_64"])+" ")])]},proxy:true}]),model:{value:(_vm.amountFrom),callback:function ($$v) {_vm.amountFrom=$$v},expression:"amountFrom"}}),_c('div',{staticClass:"mt-3 fs-small"},[_c('span',{staticClass:"font-weight-regular text-o-8"},[_vm._v(_vm._s(_vm.lang["TEXT_24"])+":")]),_c('span',{staticClass:"font-weight-bold",style:(`color: var(--color-success)`)},[_vm._v(_vm._s(_vm.formatPrice(_vm.coin?.myAmount))+" "+_vm._s(_vm.coin.symbol))])])],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"text-none",style:({
                    borderRadius: '30px !important',
                    border: '1px solid #eaecf0 !important',
                    height: '40px !important',
                  }),attrs:{"depressed":"","width":"100%","color":"white"},on:{"click":function($event){return _vm.chooseToken('transfers')}}},[_c('v-avatar',{attrs:{"size":"var(--size-icon-x-large)"}},[_c('v-img',{attrs:{"contain":"","src":_vm.coin.image}})],1),_c('span',{staticClass:"ml-3 font-weight-bold fs-small"},[_vm._v(" "+_vm._s(_vm.coin.symbol)+" ")]),_c('v-icon',{staticClass:"ml-3",attrs:{"size":"var(--size-icon-large)"}},[_vm._v(" mdi-chevron-down ")])],1),_c('div',{staticClass:"mt-2 fs-small text-end"},[_c('span',{staticClass:"font-weight-regular"},[_vm._v(" "+_vm._s(_vm.lang["TEXT_149"])+" ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(Number(_vm.coin.minimumTransfers) > 0 ? _vm.formatPrice( Number(_vm.coin.minimumTransfers) * _vm.coin.price ) : _vm.lang["TEXT_94"])+" "+_vm._s(_vm.coin.symbol)+" ")])])],1)],1)],1)]),_c('v-sheet',{staticClass:"border-radius--default mt-3",attrs:{"color":"var(--color-bg-card)"}},[_c('div',{staticClass:"pa-3"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.lang["TEXT_76"]))])]),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12"}},[_c('v-text-field',{style:({
                    borderRadius: '20px !important',
                    backgroundColor: '#FFFFFF !important',
                  }),attrs:{"hide-details":"","outlined":"","placeholder":_vm.lang['TEXT_79'],"dense":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{staticClass:"mt-4",style:({
                    borderRadius: '20px !important',
                    backgroundColor: '#FFFFFF !important',
                  }),attrs:{"placeholder":_vm.lang['TEXT_81'],"outlined":"","hide-details":"","dense":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1)],1)]),_c('div',{staticClass:"mt-3 fs"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"font-weight-regular",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.lang["TEXT_152"])+": ")]),_c('v-col',{staticClass:"text-end",attrs:{"cols":"8"}},[_c('span',{staticClass:"font-weight-semibold",style:({ color: 'var(--color-success)' })},[_vm._v(" "+_vm._s(_vm.statusCode?.fee_transfers > 0 ? _vm.formatPrice(_vm.returnFeeTransfer()) + " " + _vm.coin.symbol : _vm.lang["TEXT_85"])+" ")]),(_vm.statusCode?.fee_transfers > 0)?_c('span',{staticClass:"text-o-7"},[_vm._v(" ~ $"+_vm._s(_vm.statusCode?.fee_transfers > 0 ? _vm.formatPrice(Number(_vm.returnFeeTransfer()) * _vm.coin.price) : "")+" ")]):_vm._e()]),_c('v-col',{staticClass:"font-weight-regular",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.lang["TEXT_86"])+": ")]),_c('v-col',{staticClass:"text-end",attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(Number(_vm.amountFrom) - _vm.returnFeeTransfer())+" "+_vm._s(_vm.coin.symbol)+" ")])],1)],1),_c('v-btn',{staticClass:"mt-3 text-none border-radius--mixed font-size-large",style:({ color: 'white', borderRadius: '15px' }),attrs:{"block":"","depressed":"","color":"var(--color-wl-sub)","x-large":"","disabled":_vm.disabled().active || _vm.isLoading},on:{"click":function($event){return _vm.transfers()}}},[_vm._v(_vm._s(_vm.isLoading ? _vm.lang["TEXT_73"] : _vm.lang["TEXT_66"]))]),_c('div',{staticClass:"mt-2 error--text text-center"},[_vm._v(" "+_vm._s(_vm.disabled().message)+" ")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }