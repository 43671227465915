var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"square xyz-in",style:({
    boxShadow: 'none !important',
    borderRadius:
      _vm.typeMap == 'wallet' || _vm.typeMap == 'game' ? '15px 15px 0px 0px' : '15px',
  }),attrs:{"xyz":"inherit down","color":"var(--color-bg-card)","width":_vm.typeMap == 'wallet' || _vm.typeMap == 'game' ? '100%' : '70%'}},[_c('v-toolbar',{staticClass:"none-boxShadow",style:({
      borderRadius: '15px',
    }),attrs:{"color":"var(--color-bg-card)"}},[_c('v-toolbar-title',{staticClass:"fs-x-large font-weight-bold d-flex justify-center text-center text-black",style:({
        width: '100%',
      })},[_vm._v(" "+_vm._s(_vm.lang["TEXT_99"])+" "+_vm._s(_vm.typeMap)+" ")])],1),_c('v-sheet',{staticClass:"pa-2",style:({
      borderRadius:
        _vm.typeMap == 'wallet' || _vm.typeMap == 'game'
          ? '15px 15px 0px 0px'
          : '15px',
    }),attrs:{"color":"white"}},[_c('v-sheet',{staticClass:"fs-small text-black",style:({
        borderRadius: '10px',
      }),attrs:{"color":"rgb(0, 87, 255, 0.2)"}},[_c('div',{staticClass:"pa-4 text-start font-manrope"},[_vm._v(" "+_vm._s(_vm.lang["TEXT_293"])+" ")])]),_c('div',{staticClass:"scroll-list--token font-manrope"},[_c('div',[(_vm.isLoadingCoinChain)?_c('v-row',{attrs:{"no-gutters":""}},_vm._l((3),function(n){return _c('v-col',{key:n,staticClass:"mt-1",attrs:{"cols":"12"}},[_c('v-skeleton-loader',{staticClass:"text--opacity_04",attrs:{"type":"image","height":"90"}})],1)}),1):_c('div',_vm._l((_vm.coinChains),function(item){return _c('v-sheet',{key:item._id,staticClass:"border-radius--s-default mt-3 cursor-pointer border text-start text-black",staticStyle:{"background-color":"white !important"},style:(item._id == _vm.chain._id ? 'border: 1px solid gray !important' : ''),on:{"click":function($event){return _vm.chooseChain(item)}}},[_c('div',{staticClass:"pa-3"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start align-center",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"font-size-large"},[_c('v-avatar',{attrs:{"size":"15"}},[_c('v-img',{attrs:{"src":item.chain.image}})],1),_c('span',{staticClass:"ml-2 font-weight-semibold"},[_vm._v(_vm._s(item.chain.symbol))]),_c('span',{staticClass:"font-weight-semibold text-gray1 ml-2"},[_vm._v(_vm._s(item.chain.name))])],1)]),(_vm.method != 'deposit')?_c('v-col',{staticClass:"fs-small text-gray1 mt-2",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.lang["TEXT_92"])+": "+_vm._s(item.chain.feeUSD ? _vm.formatPrice(item.chain.feeUSD * _vm.coin.price) + " " + item.coin.symbol : _vm.lang["TEXT_85"])+" ")]):_vm._e(),(_vm.method != 'deposit')?_c('v-col',{staticClass:"fs-small text-gray1 mt-2 text-end",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.lang["TEXT_292"])+" ")]):_vm._e()],1)],1)],1)],1)])}),1)],1)]),_c('div',{staticClass:"d-flex justify-center mt-3"},[_c('v-avatar',{staticClass:"cursor-pointer",style:({
          borderRadius: '10px !important',
        }),attrs:{"tile":"","size":"35","color":"var(--color-black1)"},on:{"click":function($event){return _vm.closeChain()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }