import axios from "axios";
const url = process.env.VUE_APP_BACKEND_URL + '/api/v1/game-mining';
export class GameMiningApi {

    public async getFruit(typeFruit: string, token: string) {
        try {
            const call = await axios.get(url + '/get-fruit', {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token },
                params: { typeFruit }
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getFruitTelegram(typeFruit: string, data: any) {
        try {
            const call = await axios.get(url + '/telegram-get-fruit', {
                withCredentials: true,
                headers: data,
                params: { typeFruit }
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getNftOwn(token: string) {
        try {
            const call = await axios.get(url + '/get-nft-own', {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token },
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getNFT() {
        try {
            const call = await axios.get(url + '/get-nft', {
                withCredentials: true,
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getTask(token: string) {
        try {
            const call = await axios.get(url + '/get-task', {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token },
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getTaskTelegram(data: any) {
        try {
            const call = await axios.get(url + '/telegram-get-task', {
                withCredentials: true,
                headers: data,
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async checkTask(_id: string, token: string) {
        try {
            const call = await axios.post(url + '/check-task', { _id }, {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token },
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async checkTaskTelegram(_id: string, data: any) {
        try {
            const call = await axios.post(url + '/telegram-check-task', { _id }, {
                withCredentials: true,
                headers: data,
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getBalanceFruit(token: string) {
        try {
            const call = await axios.get(url + '/get-balance-fruit', {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token },
                params: {}
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getBalanceFruitTelegram(data: any) {
        try {
            const call = await axios.get(url + '/telegram-get-balance-fruit', {
                withCredentials: true,
                headers: data
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getMissionGame(token: string) {
        try {
            const call = await axios.get(url + '/get-mission-game', {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token },
                params: {}
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getMissionGameTelegram(data: any) {
        try {
            const call = await axios.get(url + '/telegram-get-mission-game', {
                withCredentials: true,
                headers: data,
                params: {}
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getShopFruit(token: string) {
        try {
            const call = await axios.get(url + '/get-shop-fruit', {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token },
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getShopFruitTelegram(data: any) {
        try {
            const call = await axios.get(url + '/telegram-get-shop-fruit', {
                withCredentials: true,
                headers: data,
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getMyListing(token: string) {
        try {
            const call = await axios.get(url + '/get-my-listing', {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token },
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getMyListingTelegram(data: any) {
        try {
            const call = await axios.get(url + '/telegram-get-my-listing', {
                withCredentials: true,
                headers: data
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getRank(token: string) {
        try {
            const call = await axios.get(url + '/get-rank', {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token },
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async pickFruit(_id: string, index: number, token: string) {
        try {
            const call = await axios.post(url + '/pick-fruit', { _id, index }, {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token },
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async pickFruitTelegram(_id: string, index: number, data: any) {
        try {
            const call = await axios.post(url + '/telegram-pick-fruit', { _id, index }, {
                withCredentials: true,
                headers: data
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async TelegramStartGame(data: any) {
        try {
            const call = await axios.post(url + '/telegram-start-game', {}, {
                withCredentials: true,
                headers: { ...data },
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async openBox(token: string) {
        try {
            const call = await axios.post(url + '/open-box', {}, {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token },
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async buyBox(amount: number | string, _idSymbol: string, token: string) {
        try {
            const call = await axios.post(url + '/buy-box', { _idSymbol, amount }, {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token },
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async remoteMarketplace(_id: string, token: string) {
        try {
            const call = await axios.post(url + '/remote-marketplace', { _id }, {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token },
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async remoteMarketplaceTelegram(_id: string, data: any) {
        try {
            const call = await axios.post(url + '/telegram-remote-marketplace', { _id }, {
                withCredentials: true,
                headers: data,
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async receiveBonusTask(_id: string, token: string) {
        try {
            const call = await axios.post(url + '/receive-bonus-task', { _id }, {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token },
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async receiveBonusTaskTelegram(_id: string, data: any) {
        try {
            const call = await axios.post(url + '/receive-bonus-task', { _id }, {
                withCredentials: true,
                headers: data,
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async buyFruit(fruit: string, token: string) {
        try {
            const call = await axios.post(url + '/buy-fruit', { fruit }, {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token },
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async buyFruitTelegram(fruit: string, data: any) {
        try {
            const call = await axios.post(url + '/telegram-buy-fruit', { fruit }, {
                withCredentials: true,
                headers: data,
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getMarketplace(fruit: string, token: string) {
        try {
            const call = await axios.get(url + '/get-marketplace', {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token },
                params: {
                    fruit
                }
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getMarketplaceTelegram(fruit: string, data: any) {
        try {
            const call = await axios.get(url + '/telegram-get-marketplace', {
                withCredentials: true,
                headers: data,
                params: {
                    fruit
                }
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async sell(fruit: string, myPrice: string, amount: string, token: string) {
        try {
            const call = await axios.post(url + '/sell', { fruit, myPrice, amount }, {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token }
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async sellTelegram(fruit: string, myPrice: string, amount: string, data: any) {
        try {
            const call = await axios.post(url + '/telegram-sell', { fruit, myPrice, amount }, {
                withCredentials: true,
                headers: data
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async buy(_id: string, amount: string, token: string) {
        try {
            const call = await axios.post(url + '/buy', { _id, amount }, {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token }
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async buyTelegram(_id: string, amount: string, data: any) {
        try {
            const call = await axios.post(url + '/telegram-buy', { _id, amount }, {
                withCredentials: true,
                headers: data
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async sendMission(_id: string, token: string) {
        try {
            const call = await axios.post(url + '/send-mission', { _id }, {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token },
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async sendMissionTelegram(_id: string, data: any) {
        try {
            const call = await axios.post(url + '/telegram-send-mission', { _id }, {
                withCredentials: true,
                headers: data,
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }
}