var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-sheet',{staticClass:"text-start",staticStyle:{"background-color":"var(--color-bg-card)"},attrs:{"width":!_vm.typeMap ? '100%' : _vm.size.onSize <= 450 ? _vm.size.onSize : 450}},[_c('v-sheet',[_c('v-toolbar',{staticClass:"none-boxShadow",attrs:{"height":"40","color":"var(--color-bg-card)"}},[_c('v-btn',{attrs:{"color":"white","fab":"","depressed":"","width":"var(--size-icon-x-large)","height":"var(--size-icon-x-large)"},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-toolbar-title',{staticClass:"fs-x-large font-weight-bold d-flex justify-center",style:({
          width: '90%',
        })},[_vm._v(" "+_vm._s(_vm.lang["TEXT_15"])+" ")])],1)],1),_c('v-sheet',{staticStyle:{"border-radius":"30px 30px 0px 0px"},attrs:{"color":"white"}},[_c('div',{staticClass:"pa-3"},[_c('div',{staticClass:"d-flex justify-center"},[_c('div',[_c('v-btn',{staticClass:"text-none border-radius--default fs font-weight-semibold",style:({
              zIndex: '2',
            }),attrs:{"depressed":"","color":"var(--color-blue-v2)","dark":"","width":"150"}},[_vm._v(" Crypto ")]),_c('v-btn',{staticClass:"text-none fs font-weight-semibold",style:({
              marginLeft: '-15px',
              borderRadius:
                '0px var(--border-radius-default) var(--border-radius-default) 0px !important',
            }),attrs:{"depressed":"","color":"#F1F3F8","width":"150","disabled":""}},[_vm._v(" Cash ")]),_c('div',{staticClass:"mt-3 fs-small font-weight-medium text-center"},[_c('v-icon',{attrs:{"size":"var(--size-icon-small)"}},[_vm._v("mdi-history")]),_vm._v(" Transaction ")],1)],1)]),_c('v-text-field',{staticClass:"mt-3",attrs:{"placeholder":_vm.lang['TEXT_296'],"outlined":"","dense":"","hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-fade-transition',{attrs:{"leave-absolute":""}},[_c('div',{staticClass:"pa-1"},[_c('img',{attrs:{"width":"15","height":"15","loading":"lazy","src":require("../../assets/wallet/search.png")}})])])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"scroll-list--token",style:({
            height: _vm.size.height / 1.5 + 'px',
          })},[(_vm.filterBy(_vm.coins_, _vm.search).length === 0)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.lang["TEXT_291"]))])]):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.filterBy(_vm.coins_, _vm.search)),function(item){return _c('v-col',{key:item._id,staticClass:"mt-1",attrs:{"cols":"12"},on:{"click":function($event){return _vm.changeLinkChooseToken(item, _vm.typeChoose)}}},[_c('v-card',{staticClass:"no-box-shadow hover-choose",style:({
                  borderRadius: '15px',
                }),attrs:{"link":""}},[_c('div',{staticClass:"pa-2"},[_c('ItemCoinComponent',{attrs:{"image":item.image,"name":item.name,"symbol":item.symbol,"price":item.price,"CHANGEPCT24HOUR":item.CHANGEPCT24HOUR,"formatPrice":_vm.formatPrice,"myAmount":item.myAmount,"lang":_vm.lang,"type":"choose"}})],1)])],1)}),1),_c('v-sheet',{attrs:{"height":"100"}})],1)])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }