<template>
  <div>
    <div class="fs-large font-weight-semibold">Promo and Discount</div>
    <swiper-container
      slides-per-view="1.4"
      space-between="10"
      speed="500"
      loop="true"
      css-mode="true"
      class="mt-3"
    >
      <swiper-slide>
        <v-img
          :style="{
            borderRadius: '10px',
          }"
          src="../../assets/wallet/slider1.png"
        ></v-img>
      </swiper-slide>
      <swiper-slide
        ><v-img
          :style="{
            borderRadius: '10px',
          }"
          src="../../assets/wallet/slider2.png"
        ></v-img
      ></swiper-slide>
    </swiper-container>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  data: () => ({
    //
  }),
});
</script>

