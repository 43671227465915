<template>
  <div class="pa-3">
    <v-row no-gutters>
      <v-col cols="8">
        <div class="d-flex align-center">
          <div class="fs-large font-weight-semibold">
            {{ lang["TEXT_146"] }}
          </div>
          <v-btn
            dark
            depressed
            color="var(--color-blue-v2)"
            class="ml-3 text-none border-radius--default fs"
            >Tokens</v-btn
          >

          <v-badge overlap color="red" content="soon">
            <v-btn
              depressed
              rounded=""
              disabled
              color="white"
              :style="{
                border: '1px solid #EAECF0 !important',
              }"
              class="ml-3 text-none border-radius--default fs"
              >NFTs</v-btn
            >
          </v-badge>
        </div>
      </v-col>

      <v-col cols="4" class="d-flex align-center justify-end">
        <v-icon @click="changeMap('manage-token')" class="cursor-pointer" size="var(--size-icon-x-large)" color="black">
          mdi-plus-circle-outline
        </v-icon>
      </v-col>
    </v-row>

    <div class="mt-3">
      <v-row no-gutters v-if="isLoadingCoins">
        <v-col cols="12" class="mt-3" v-for="n in 10" :key="n">
          <v-skeleton-loader
            type="image"
            height="60"
            class="text--opacity_04"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <div v-else>
        <div xyz="fade up out-down stagger">
          <v-sheet
            style="border-radius: 20px"
            color="var(--color-bg-card)"
            v-for="item in coins"
            :key="item._id"
            class="mt-3 cursor-pointer"
            @click="mapDetails(item)"
          >
            <div class="pa-3 square xyz-in">
              <ItemCoinComponent
                :image="item.image"
                :name="item.name"
                :symbol="item.symbol"
                :price="item.price"
                :CHANGEPCT24HOUR="item.CHANGEPCT24HOUR"
                :formatPrice="formatPrice"
                :myAmount="item.myAmount"
                :lang="lang"
                :showBalance="showBalance"
              />
            </div>
          </v-sheet>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import ItemCoinComponent from "@/components/wallet/item/ItemCoin.vue";

export default Vue.extend({
  props: [
    "lang",
    "formatPrice",
    "coins",
    "mapDetails",
    "isLoadingCoins",
    "showBalance",
    "changeMap",
  ],
  components: {
    ItemCoinComponent,
  },
  data: () => ({
    //
  }),
});
</script>

