<template>
  <v-sheet
    class="border"
    :style="{
      borderRadius: '15px',
    }"
  >
    <div class="pa-3">
      <div class="fs-large font-weight-semibold">Latest news</div>

      <v-row class="mt-2">
        <v-col cols="12" v-for="n in 2" :key="n">
          <v-row>
            <v-col cols="5">
              <v-img
                height="100%"
                contain
                src="../../assets/wallet/news.png"
              ></v-img>
            </v-col>
            <v-col cols="7" class="d-flex justify-center align-center">
              <div>
                <div class="font-weight-medium">
                  Analysts project 32% upside for Coinbase
                </div>
                <div class="mt-3">
                  <span
                    :style="{
                      color: 'var(--color-wl-sub)',
                    }"
                    >Finbold</span
                  >
                  <v-avatar color="#494D58" size="5" class="ml-2"></v-avatar>
                  <span
                    class="ml-2"
                    :style="{
                      color: '#494D58',
                    }"
                    >47min ago</span
                  >
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-sheet>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  data: () => ({
    //
  }),
});
</script>

