<template>
  <v-sheet
    :width="!typeMap ? '100%' : size.onSize <= 450 ? size.onSize : 450"
    class="text-start"
    style="background-color: var(--color-bg-card)"
  >
    <v-sheet>
      <v-toolbar
        height="40"
        color="var(--color-bg-card)"
        class="none-boxShadow"
      >
        <v-btn
          style="border-radius: 50% !important"
          color="white"
          fab
          depressed
          width="var(--size-icon-x-large)"
          height="var(--size-icon-x-large)"
          @click="close()"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title
          class="fs-x-large font-weight-bold d-flex justify-center"
          style="width: 90%"
          >{{ lang["TEXT_3"] }}</v-toolbar-title
        >
      </v-toolbar>
    </v-sheet>

    <v-sheet color="white" style="border-radius: 30px 30px 0px 0px">
      <div class="pa-3">
        <div class="d-flex justify-center">
          <div>
            <v-btn
              depressed
              color="var(--color-blue-v2)"
              dark
              width="150"
              :style="{
                zIndex: '2',
              }"
              class="text-none border-radius--default fs font-weight-semibold"
            >
              Crypto
            </v-btn>
            <v-btn
              depressed
              color="#F1F3F8"
              width="150"
              disabled
              :style="{
                marginLeft: '-15px',
                borderRadius:
                  '0px var(--border-radius-default) var(--border-radius-default) 0px !important',
              }"
              class="text-none fs font-weight-semibold"
            >
              Cash
            </v-btn>

            <div class="mt-3 fs-small font-weight-medium text-center">
              <v-icon size="var(--size-icon-small)">mdi-history</v-icon>
              Transaction
            </div>
          </div>
        </div>

        <v-sheet
          class="scroll-list--token"
          :style="{
            height: size.height / 1.5 + 'px',
          }"
        >
          <v-sheet
            :style="{
              borderRadius: '20px',
            }"
            color="var(--color-bg-card)"
            class="mt-3"
          >
            <div class="pa-3">
              <v-row no-gutters>
                <v-col cols="6">
                  <div class="font-weight-bold mt-3">
                    {{
                      lang["TEXT_148"]?.replace("_symbol", symbolFrom.symbol)
                    }}:
                  </div>
                </v-col>
                <v-col cols="8" class="mt-3">
                  <v-text-field
                    type="number"
                    hide-details=""
                    outlined
                    placeholder="0.00"
                    hide-spin-buttons
                    v-model="amountFrom"
                    @input="amountFromInput()"
                    dense
                    :style="{
                      borderRadius: '20px !important',
                      width: '95%',
                      backgroundColor: '#FFFFFF !important',
                    }"
                  >
                    <template v-slot:append>
                      <v-btn
                        @click="max()"
                        depressed
                        small
                        height="32"
                        :style="{
                          marginTop: '-4px !important',
                          borderRadius: '20px !important',
                        }"
                        class="text-none font-weight-medium font-family--poppins"
                      >
                        {{ lang["TEXT_64"] }}
                      </v-btn>
                    </template>
                  </v-text-field>

                  <div class="mt-3 fs-small">
                    <span class="text-o-8 font-weight-regular"
                      >{{ lang["TEXT_24"] }}:</span
                    >
                    <span
                      :style="`color: var(--color-success)`"
                      class="font-weight-bold"
                      >{{ formatPrice(symbolFrom?.myAmount) }}
                      {{ symbolFrom.symbol }}</span
                    >
                  </div>
                </v-col>
                <v-col cols="4" class="mt-3">
                  <v-btn
                    depressed
                    width="100%"
                    color="white"
                    class="text-none"
                    @click="chooseTokenPage('from')"
                    :style="{
                      borderRadius: '30px !important',
                      border: '1px solid #eaecf0 !important',
                      height: '40px !important',
                    }"
                  >
                    <v-avatar size="var(--size-icon-large)">
                      <v-img
                        contain
                        src="../../assets/game_mining/token.png"
                        v-if="symbolFrom.symbol == 'GOF'"
                      ></v-img>
                      <v-img contain :src="symbolFrom.image" v-else></v-img>
                    </v-avatar>
                    <span class="ml-3 font-weight-bold fs-small">
                      {{ symbolFrom.symbol }}
                    </span>
                    <v-icon size="var(--size-icon-large)" class="ml-3"
                      >mdi-chevron-down</v-icon
                    >
                  </v-btn>
                  <div class="mt-3 fs-small">
                    <span class="font-weight-regular">
                      {{ lang["TEXT_149"] }}
                    </span>
                    <span class="font-weight-bold">
                      {{
                        Number(symbolFrom.minimumSwap) > 0
                          ? formatPrice(
                              Number(symbolFrom.minimumSwap) * symbolFrom.price
                            )
                          : lang["TEXT_94"]
                      }}
                      {{ symbolFrom.symbol }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-sheet>

          <center style="margin-top: -25px">
            <v-btn
              @click="swapFromTo()"
              fab
              depressed
              large
              color="var(--color-wl-sub)"
              :style="{
                borderRadius: '20px !important',
                border: '7px solid white',
              }"
            >
              <v-img
                width="var(--size-icon-large)"
                height="var(--size-icon-large)"
                contain
                src="../../assets/wallet/icon_swap2.png"
              ></v-img>
            </v-btn>
          </center>

          <v-sheet
            :style="{
              borderRadius: '20px',
              marginTop: '-32px',
            }"
            color="var(--color-bg-card)"
          >
            <div class="pa-3">
              <v-row no-gutters>
                <v-col cols="6">
                  <div class="font-weight-bold">
                    {{ lang["TEXT_148"]?.replace("_symbol", symbolTo.symbol) }}:
                  </div>
                </v-col>

                <v-col cols="8" class="mt-3">
                  <v-text-field
                    type="number"
                    hide-details=""
                    :style="{
                      borderRadius: '20px !important',
                      width: '95%',
                      backgroundColor: '#FFFFFF !important',
                    }"
                    outlined
                    dense
                    background-color="white"
                    placeholder="0.00"
                    hide-spin-buttons
                    v-model="amountTo"
                    @input="amountToInput()"
                  >
                    <template v-slot:append>
                      <v-btn
                        :style="{
                          marginTop: '-4px !important',
                          borderRadius: '20px !important',
                        }"
                        @click="maxTo()"
                        depressed
                        small
                        height="32"
                        class="text-none font-weight-medium font-family--poppins"
                      >
                        {{ lang["TEXT_64"] }}
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="4" class="mt-3 d-flex justify-start align-center">
                  <v-btn
                    depressed
                    x-large
                    color="white"
                    width="100%"
                    class="text-none"
                    :style="{
                      borderRadius: '30px !important',
                      border: '1px solid #eaecf0 !important',
                      height: '40px !important',
                    }"
                    @click="chooseTokenPage('to')"
                  >
                    <v-avatar size="var(--size-icon-large)">
                      <v-img
                        contain
                        src="../../assets/game_mining/token.png"
                        v-if="symbolTo.symbol == 'GOF'"
                      ></v-img>
                      <v-img contain :src="symbolTo.image" v-else></v-img>
                    </v-avatar>
                    <span class="ml-3 font-weight-bold fs-small">
                      {{ symbolTo.symbol }}
                    </span>
                    <v-icon size="var(--size-icon-large)" class="ml-2"
                      >mdi-chevron-down</v-icon
                    >
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <div class="mt-3 fs-small">
                    <span class="text-o-8">{{ lang["TEXT_68"] }}</span>
                    <span>
                      <span class="ml-1 font-weight-semibold">1</span>
                      <span class="ml-1 font-weight-regular">{{
                        symbolFrom.symbol
                      }}</span>
                      <span class="ml-1">=</span>
                      <span class="ml-1 font-weight-semibold">
                        {{ (symbolFrom.price / symbolTo.price).toFixed(8) }}
                      </span>
                      <span class="ml-1 font-weight-regular">
                        {{ symbolTo.symbol }}
                      </span>
                    </span>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-sheet>

          <div class="mt-3 fs">
            <v-row no-gutters>
              <v-col cols="6" class="font-weight-regular">
                {{ lang["TEXT_150"] }}:
              </v-col>
              <v-col cols="6" class="text-end font-weight-semibold">
                {{ lang["TEXT_151"] }}
              </v-col>
            </v-row>
          </div>

          <v-btn
            block
            depressed
            class="mt-3 text-none border-radius--mixed font-size-large"
            color="var(--color-wl-sub)"
            x-large
            :style="{ color: 'white', borderRadius: '15px' }"
            :disabled="disabledSwap().active || isLoadingSwap"
            @click="swap()"
            >{{ isLoadingSwap ? lang["TEXT_73"] : lang["TEXT_66"] }}</v-btn
          >
          <div class="mt-2 error--text text-center font-weight-semibold">
            {{ disabledSwap().message }}
          </div>
        </v-sheet>
      </div>
    </v-sheet>

    <v-bottom-sheet
      v-model="dialogChooseToken"
      persistent
      v-if="typeMap == 'wallet' || typeMap == 'game'"
    >
      <v-sheet height="120%" color="transparent">
        <center>
          <v-sheet max-width="450" color="transparent">
            <ChooseTokenViewComponent
              :dialogChooseToken="dialogChooseToken"
              :lang="lang"
              :formatPrice="formatPrice"
              :clickChooseToken="clickChooseToken"
              :typeChooseToken="typeChooseToken"
              :coins="coins"
              :size="size"
              :typeMap="typeMap"
              :closeToken="closeToken"
            />
          </v-sheet>
        </center>
      </v-sheet>
    </v-bottom-sheet>

    <v-overlay
      v-else
      :value="dialogChooseToken"
      class="d-flex justify-center align-center text-center"
      :style="{
        zIndex: '1000',
      }"
    >
      <center>
        <ChooseTokenViewComponent
          :dialogChooseToken="dialogChooseToken"
          :lang="lang"
          :formatPrice="formatPrice"
          :clickChooseToken="clickChooseToken"
          :typeChooseToken="typeChooseToken"
          :coins="coins"
          :size="size"
          :typeMap="typeMap"
          :closeToken="closeToken"
        />
      </center>
    </v-overlay>
  </v-sheet>
</template>

<script lang="ts">
import Vue from "vue";
import ItemCoinComponent from "@/components/wallet/item/ItemCoin.vue";

import ChooseTokenViewComponent from "@/components/view/ChooseToken.vue";
import { WalletApi } from "@/api/WALLET";
const walletApi = new WalletApi();
export default Vue.extend({
  props: [
    "chooseToken",
    "lang",
    "coin",
    "coinChains",
    "formatPrice",
    "size",
    "isLoadingCoinChain",
    "close",
    "refreshTokenAction",
    "notificationCall",
    "updateBalance",
    "coins",
    "fee",
    "typeMap",
    "typeMapPage",
    "statusCode",
    "getHashTelegram",
  ],
  components: {
    ItemCoinComponent,
    ChooseTokenViewComponent,
  },
  data: () => ({
    symbolFrom: {} as any,
    symbolTo: {} as any,
    amountFrom: "" as any,
    amountTo: "" as any,
    isLoadingSwap: false,
    dialogChooseToken: false,
    typeChooseToken: "",
    coins_: [] as any,
  }),
  mounted() {
    const coinSwap = this.coins.filter((x: any) => x.isSwap);
    if (coinSwap.length <= 1) {
      this.close();
      return;
    }

    this.symbolFrom = coinSwap[0];
    this.symbolTo = coinSwap[1];
  },
  methods: {
    closeToken() {
      this.dialogChooseToken = !this.dialogChooseToken;
    },
    chooseTokenPage(type: string) {
      const coins = this.coins;
      this.coins_ = coins.filter(
        (x: any) =>
          x.symbol != this.symbolFrom.symbol && x.symbol != this.symbolTo.symbol
      );
      this.typeChooseToken = type;
      this.dialogChooseToken = true;
    },
    clickChooseToken(item: any) {
      if (this.typeChooseToken == "from") {
        this.symbolFrom = item;
      } else {
        this.symbolTo = item;
      }
      this.amountFrom = "";
      this.amountTo = "";
      this.dialogChooseToken = false;
    },
    swapFromTo() {
      const amountFrom = this.amountFrom;
      const amountTo = this.amountTo;

      const symbolFrom = this.symbolFrom;
      const symbolTo = this.symbolTo;

      this.amountFrom = amountTo;
      this.amountTo = amountFrom;

      this.symbolFrom = symbolTo;
      this.symbolTo = symbolFrom;

      this.amountFromInput();
      this.amountToInput();
    },
    disabledSwap() {
      if (!this.amountFrom) {
        return {
          active: true,
          message: this.lang["TEXT_108"],
        };
      }

      if (this.symbolFrom.myAmount < Number(this.amountFrom)) {
        return {
          active: true,
          message: this.lang["TEXT_107"],
        };
      }

      return {
        active: false,
      };
    },
    async swap() {
      this.isLoadingSwap = true;
      try {
        let call: any;

        if (this.typeMapPage == "telegram") {
          call = await walletApi.swapTelegram(
            this.symbolFrom.symbol,
            this.symbolTo.symbol,
            this.amountFrom,
            this.getHashTelegram()
          );
        } else {
          const accessToken = await this.refreshTokenAction();
          call = await walletApi.swap(
            this.symbolFrom.symbol,
            this.symbolTo.symbol,
            this.amountFrom,
            accessToken
          );
        }

        if (call.success) {
          this.notificationCall(
            this.lang[call.message]
              .replace("_symbolFrom", this.symbolFrom.symbol)
              .replace("_symbolTo", this.symbolTo.symbol)
              .replace("_amountFrom", call.data.amountFrom)
              .replace("_amountTo", call.data.amountTo)
          );
          this.updateBalance(this.symbolFrom.symbol, call.data.amountFrom, "-");
          this.updateBalance(this.symbolTo.symbol, call.data.amountTo, "+");
          this.amountFrom = "";
          this.amountTo = "";
        } else {
          this.notificationCall(
            this.lang[call.message]
              .replace("_symbol", this.symbolFrom?.symbol || "")
              .replace(
                "_maximumswap",
                this.formatPrice(
                  this.symbolFrom?.minimumSwap * this.symbolFrom?.price
                )
              )
          );
        }
        this.isLoadingSwap = false;
      } catch (error) {
        this.isLoadingSwap = false;
      }
    },
    max() {
      this.amountFrom = this.symbolFrom.myAmount;
      this.amountFromInput();
    },
    maxTo() {
      this.amountTo = this.symbolTo.myAmount;
      this.amountToInput();
    },
    returnFeeSwap() {
      if (this.statusCode.fee_swap > 0) {
        return Number(this.statusCode.fee_swap);
      } else {
        return 0;
      }
    },
    amountFromInput() {
      const amountFrom =
        Number(this.amountFrom) * (this.symbolFrom.price / this.symbolTo.price);

      this.amountTo = Number(amountFrom) * ((100 - this.returnFeeSwap()) / 100);
    },
    amountToInput() {
      const amountTo =
        this.amountTo * (this.symbolTo.price / this.symbolFrom.price);
      this.amountFrom = amountTo * ((100 - this.returnFeeSwap()) / 100);
    },
  },
});
</script>

