<template>
  <v-card
    class="square xyz-in fs"
    xyz="inherit down"
    color="var(--color-bg-card)"
    :width="typeMap == 'wallet' || typeMap == 'game' ? '100%' : '80%'"
    :style="{
      boxShadow: 'none !important',
      borderRadius:
        typeMap == 'wallet' || typeMap == 'game' ? '15px 15px 0px 0px' : '15px',
    }"
  >
    <v-toolbar
      :style="{
        borderRadius: '15px 15px 0px 0px',
      }"
      color="var(--color-bg-card)"
      class="none-boxShadow"
    >
      <v-toolbar-title
        class="fs-x-large font-weight-bold d-flex justify-center text-black"
        style="width: 100%"
      >
        {{ lang["TEXT_1"] }}
      </v-toolbar-title>
    </v-toolbar>
    <v-sheet
      class="pa-2"
      :style="{
        borderRadius: '15px',
        backgroundColor: 'white !important',
      }"
    >
      <v-sheet
        class="cursor-pointer border-radius--s-default none-boxShadow border text-black text-start"
        xyz="fade down-5 flip-up"
        @click="chooseToken('withdraw')"
        style="background-color: white !important"
      >
        <div class="pa-3">
          <v-row>
            <v-col cols="2" class="d-flex justify-center align-center">
              <v-avatar size="30">
                <v-img src="../../assets/game_mining/send1.png"></v-img>
              </v-avatar>
            </v-col>
            <v-col cols="8">
              <div>
                <div class="font-weight-semibold fs-large">
                  {{ lang["TEXT_74"] }}
                </div>
                <div class="font-weight-regular">
                  {{ lang["TEXT_75"] }}
                </div>
              </div>
            </v-col>
            <v-col class="d-flex justify-end align-center">
              <div>
                <v-icon color="var(--color-black1)">mdi-chevron-right</v-icon>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-sheet>

      <v-sheet
        class="cursor-pointer border-radius--s-default none-boxShadow mt-3 border text-black text-start"
        @click="chooseToken('transfers')"
        xyz="fade down-4 flip-up"
        style="background-color: white !important"
      >
        <div class="pa-3">
          <v-row>
            <v-col cols="2" class="d-flex justify-center align-center">
              <v-avatar size="30">
                <v-img src="../../assets/game_mining/send2.png"></v-img>
              </v-avatar>
            </v-col>
            <v-col cols="8">
              <div class="font-weight-semibold fs-large">
                {{ lang["TEXT_76"] }}
              </div>
              <div class="font-weight-regular fs">
                {{ lang["TEXT_77"] }}
              </div>
            </v-col>
            <v-col class="d-flex justify-end align-center">
              <div>
                <v-icon color="var(--color-black1)">mdi-chevron-right</v-icon>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-sheet>

      <div class="d-flex justify-center mt-3">
        <v-avatar
          tile
          size="35"
          color="var(--color-black1)"
          class="cursor-pointer"
          @click="clickNavSend()"
          :style="{
            borderRadius: '10px !important',
          }"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-avatar>
      </div>
    </v-sheet>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: ["lang", "chooseToken", "clickNavSend", "typeMap"],
  data: () => ({
    //
  }),
});
</script>

