var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"square xyz-in fs",style:({
    boxShadow: 'none !important',
    borderRadius:
      _vm.typeMap == 'wallet' || _vm.typeMap == 'game' ? '15px 15px 0px 0px' : '15px',
  }),attrs:{"xyz":"inherit down","color":"var(--color-bg-card)","width":_vm.typeMap == 'wallet' || _vm.typeMap == 'game' ? '100%' : '80%'}},[_c('v-toolbar',{staticClass:"none-boxShadow",style:({
      borderRadius: '15px 15px 0px 0px',
    }),attrs:{"color":"var(--color-bg-card)"}},[_c('v-toolbar-title',{staticClass:"fs-x-large font-weight-bold d-flex justify-center text-black",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.lang["TEXT_1"])+" ")])],1),_c('v-sheet',{staticClass:"pa-2",style:({
      borderRadius: '15px',
      backgroundColor: 'white !important',
    })},[_c('v-sheet',{staticClass:"cursor-pointer border-radius--s-default none-boxShadow border text-black text-start",staticStyle:{"background-color":"white !important"},attrs:{"xyz":"fade down-5 flip-up"},on:{"click":function($event){return _vm.chooseToken('withdraw')}}},[_c('div',{staticClass:"pa-3"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"2"}},[_c('v-avatar',{attrs:{"size":"30"}},[_c('v-img',{attrs:{"src":require("../../assets/game_mining/send1.png")}})],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('div',[_c('div',{staticClass:"font-weight-semibold fs-large"},[_vm._v(" "+_vm._s(_vm.lang["TEXT_74"])+" ")]),_c('div',{staticClass:"font-weight-regular"},[_vm._v(" "+_vm._s(_vm.lang["TEXT_75"])+" ")])])]),_c('v-col',{staticClass:"d-flex justify-end align-center"},[_c('div',[_c('v-icon',{attrs:{"color":"var(--color-black1)"}},[_vm._v("mdi-chevron-right")])],1)])],1)],1)]),_c('v-sheet',{staticClass:"cursor-pointer border-radius--s-default none-boxShadow mt-3 border text-black text-start",staticStyle:{"background-color":"white !important"},attrs:{"xyz":"fade down-4 flip-up"},on:{"click":function($event){return _vm.chooseToken('transfers')}}},[_c('div',{staticClass:"pa-3"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"2"}},[_c('v-avatar',{attrs:{"size":"30"}},[_c('v-img',{attrs:{"src":require("../../assets/game_mining/send2.png")}})],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"font-weight-semibold fs-large"},[_vm._v(" "+_vm._s(_vm.lang["TEXT_76"])+" ")]),_c('div',{staticClass:"font-weight-regular fs"},[_vm._v(" "+_vm._s(_vm.lang["TEXT_77"])+" ")])]),_c('v-col',{staticClass:"d-flex justify-end align-center"},[_c('div',[_c('v-icon',{attrs:{"color":"var(--color-black1)"}},[_vm._v("mdi-chevron-right")])],1)])],1)],1)]),_c('div',{staticClass:"d-flex justify-center mt-3"},[_c('v-avatar',{staticClass:"cursor-pointer",style:({
          borderRadius: '10px !important',
        }),attrs:{"tile":"","size":"35","color":"var(--color-black1)"},on:{"click":function($event){return _vm.clickNavSend()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }