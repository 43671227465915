<template>
  <v-sheet
    :width="!typeMap ? '100%' : size.onSize <= 450 ? size.onSize : 450"
    class="text-start"
    style="background-color: var(--color-bg-card)"
  >
    <v-sheet>
      <v-toolbar
        height="40"
        color="var(--color-bg-card)"
        class="none-boxShadow"
      >
        <v-btn
          style="border-radius: 50% !important"
          color="white"
          fab
          depressed
          small
          width="var(--size-icon-x-large)"
          height="var(--size-icon-x-large)"
          @click="close()"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title
          class="fs-x-large font-weight-bold d-flex justify-center"
          style="width: 90%"
        >
          {{ lang["TEXT_89"] }} {{ coin.symbol }}
        </v-toolbar-title>
      </v-toolbar>

      <v-sheet
        color="white"
        class="scroll-list--token"
        :style="{
          height: size.height - 100 + 'px',
          borderRadius: '30px 30px 0px 0px',
        }"
      >
        <div class="pa-3">
          <div class="d-flex justify-center">
            <div>
              <v-btn
                depressed
                color="var(--color-blue-v2)"
                dark
                width="150"
                :style="{
                  zIndex: '2',
                }"
                class="text-none border-radius--default fs font-weight-semibold"
              >
                Crypto
              </v-btn>
              <v-btn
                depressed
                color="#F1F3F8"
                width="150"
                disabled
                :style="{
                  marginLeft: '-15px',
                  borderRadius:
                    '0px var(--border-radius-default) var(--border-radius-default) 0px !important',
                }"
                class="text-none fs font-weight-semibold"
              >
                Cash
              </v-btn>

              <div class="mt-3 fs-small font-weight-medium text-center">
                <v-icon size="var(--size-icon-small)">mdi-history</v-icon>
                Transaction
              </div>
            </div>
          </div>
          <v-sheet
            class="border-radius--default mt-3"
            color="var(--color-bg-card)"
          >
            <div class="pa-3">
              <v-row no-gutters>
                <v-col cols="6">
                  <div class="font-weight-bold">{{ lang["TEXT_80"] }}:</div>
                </v-col>
                <v-col cols="6" class="text--opacity_06 text-end"> </v-col>
                <v-col cols="8" class="mt-3">
                  <v-text-field
                    type="number"
                    hide-details=""
                    outlined
                    placeholder="0.00"
                    hide-spin-buttons
                    background-color="white"
                    v-model="amountFrom"
                    dense
                    :style="{
                      borderRadius: '20px !important',
                      width: '95%',
                      backgroundColor: '#FFFFFF !important',
                    }"
                  >
                    <template v-slot:append>
                      <v-btn
                        @click="max()"
                        depressed
                        small
                        height="32"
                        :style="{
                          marginTop: '-4px !important',
                          borderRadius: '20px !important',
                        }"
                        class="text-none font-weight-medium font-family--poppins"
                      >
                        {{ lang["TEXT_64"] }}
                      </v-btn>
                    </template>
                  </v-text-field>

                  <div class="mt-3 fs-small">
                    <span class="font-weight-regular text-o-8"
                      >{{ lang["TEXT_24"] }}:</span
                    >
                    <span
                      :style="`color: var(--color-success)`"
                      class="font-weight-bold"
                      >{{ formatPrice(coin?.myAmount) }} {{ coin.symbol }}</span
                    >
                  </div>
                </v-col>
                <v-col cols="4" class="mt-3">
                  <v-btn
                    depressed
                    class="text-none"
                    width="100%"
                    color="white"
                    @click="chooseToken('transfers')"
                    :style="{
                      borderRadius: '30px !important',
                      border: '1px solid #eaecf0 !important',
                      height: '40px !important',
                    }"
                  >
                    <v-avatar size="var(--size-icon-x-large)">
                      <v-img contain :src="coin.image"></v-img>
                    </v-avatar>
                    <span class="ml-3 font-weight-bold fs-small">
                      {{ coin.symbol }}
                    </span>
                    <v-icon size="var(--size-icon-large)" class="ml-3">
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>

                  <div class="mt-2 fs-small text-end">
                    <span class="font-weight-regular">
                      {{ lang["TEXT_149"] }}
                    </span>
                    <span class="font-weight-bold">
                      {{
                        Number(coin.minimumTransfers) > 0
                          ? formatPrice(
                              Number(coin.minimumTransfers) * coin.price
                            )
                          : lang["TEXT_94"]
                      }}
                      {{ coin.symbol }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-sheet>

          <v-sheet
            class="border-radius--default mt-3"
            color="var(--color-bg-card)"
          >
            <div class="pa-3">
              <v-row no-gutters>
                <v-col cols="12">
                  <div class="font-weight-bold">{{ lang["TEXT_76"] }}</div>
                </v-col>
                <v-col cols="12" class="mt-3">
                  <v-text-field
                    hide-details=""
                    outlined
                    :placeholder="lang['TEXT_79']"
                    v-model="email"
                    dense
                    :style="{
                      borderRadius: '20px !important',
                      backgroundColor: '#FFFFFF !important',
                    }"
                  ></v-text-field>

                  <v-text-field
                    :placeholder="lang['TEXT_81']"
                    class="mt-4"
                    outlined
                    hide-details=""
                    v-model="description"
                    dense
                    :style="{
                      borderRadius: '20px !important',
                      backgroundColor: '#FFFFFF !important',
                    }"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-sheet>

          <div class="mt-3 fs">
            <v-row no-gutters>
              <v-col cols="4" class="font-weight-regular">
                {{ lang["TEXT_152"] }}:
              </v-col>
              <v-col cols="8" class="text-end">
                <span
                  class="font-weight-semibold"
                  :style="{ color: 'var(--color-success)' }"
                >
                  {{
                    statusCode?.fee_transfers > 0
                      ? formatPrice(returnFeeTransfer()) + " " + coin.symbol
                      : lang["TEXT_85"]
                  }}
                </span>
                <span class="text-o-7" v-if="statusCode?.fee_transfers > 0">
                  ~ ${{
                    statusCode?.fee_transfers > 0
                      ? formatPrice(Number(returnFeeTransfer()) * coin.price)
                      : ""
                  }}
                </span>
              </v-col>

              <v-col cols="4" class="font-weight-regular">
                {{ lang["TEXT_86"] }}:
              </v-col>
              <v-col cols="8" class="text-end">
                {{ Number(amountFrom) - returnFeeTransfer() }}
                {{ coin.symbol }}
              </v-col>
            </v-row>
          </div>

          <v-btn
            block
            depressed
            class="mt-3 text-none border-radius--mixed font-size-large"
            color="var(--color-wl-sub)"
            :style="{ color: 'white', borderRadius: '15px' }"
            x-large
            :disabled="disabled().active || isLoading"
            @click="transfers()"
            >{{ isLoading ? lang["TEXT_73"] : lang["TEXT_66"] }}</v-btn
          >

          <div class="mt-2 error--text text-center">
            {{ disabled().message }}
          </div>
        </div>
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<script lang="ts">
import Vue from "vue";
import { WalletApi } from "@/api/WALLET";
const walletApi = new WalletApi();
export default Vue.extend({
  props: [
    "lang",
    "coin",
    "close",
    "formatPrice",
    "fee",
    "refreshTokenAction",
    "notificationCall",
    "updateBalance",
    "chooseToken",
    "size",
    "typeMap",
    "typeMapPage",
    "statusCode",
    "getHashTelegram"
  ],
  data: () => ({
    amountFrom: "",
    email: "",
    description: "",
    isLoading: false,
  }),
  methods: {
    async transfers() {
      this.isLoading = true;

      let call: any;
      if (this.typeMapPage == "telegram") {
        call = await walletApi.transfersTelegram(
          this.coin._id,
          this.amountFrom,
          this.email,
          this.description,
          this.getHashTelegram()
        );
      } else {
        const accessToken = await this.refreshTokenAction();

        call = await walletApi.transfers(
          this.coin._id,
          this.amountFrom,
          this.email,
          this.description,
          accessToken
        );
      }

      if (call.success) {
        this.notificationCall(
          this.lang[call.message]
            ?.replaceAll("_symbol", this.coin.symbol)
            .replaceAll("_amount", call.data.amountReceive)
            .replaceAll("_email", this.email)
            .replaceAll(
              "_fee",
              call.data.amountFee > 0
                ? call.data.amountFee
                : this.lang["TEXT_85"]
            )
        );

        this.updateBalance(this.coin.symbol, this.amountFrom, "-");

        this.email = "";
        this.description = "";
        this.amountFrom = "";
        this.isLoading = false;
      } else {
        this.notificationCall(this.statusCode[call.message] ?? call.message);
        this.isLoading = false;
      }
    },
    max() {
      this.amountFrom = this.coin.myAmount;
    },
    returnFeeTransfer() {
      return (
        Number(this.amountFrom) * (Number(this.statusCode?.fee_transfers) / 100)
      );
    },
    disabled() {
      if (!this.amountFrom) {
        return {
          active: true,
          message: this.lang["TEXT_108"],
        };
      }

      if (!this.email) {
        return {
          active: true,
          message: this.lang["TEXT_113"],
        };
      }

      if (this.description.length > 50) {
        return {
          active: true,
          message: this.lang["TEXT_114"],
        };
      }

      if (Number(this.coin.minimumTransfers) > 0) {
        const fee = Number(this.coin.minimumTransfers) * this.coin.price;
        if (Number(this.amountFrom) < fee) {
          return {
            active: true,
            message: this.lang["TEXT_109"]
              .replace(
                "_amount",
                Number(this.coin.minimumTransfers) * this.coin.price
              )
              .replace("_symbol", this.coin.symbol),
          };
        }
      }

      if (this.coin.myAmount < Number(this.amountFrom)) {
        return {
          active: true,
          message: this.lang["TEXT_107"],
        };
      }

      return {
        active: false,
      };
    },
  },
});
</script>

