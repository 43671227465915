import axios from "axios";
const url = process.env.VUE_APP_BACKEND_URL + '/api/v1/auth';
export class AuthApi {

    public async getProfile(token: string) {
        try {
            const call = await axios.get(url + '/get-profile', {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token }
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getProfileTelegram(data: any) {
        try {
            const call = await axios.post(url + '/telegram-get-profile', {}, {
                withCredentials: true,
                headers: { ...data },
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async logOut(token: string) {
        try {
            const call = await axios.get(url + '/log-out', {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token }
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getStatusCode(lang = 'vi') {
        try {
            const call = await axios.get(url + '/get-status-code', {
                withCredentials: true,
                params: { lang }
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getRef(token: string) {
        try {
            const call = await axios.get(url + '/get-ref', {
                withCredentials: true,
                headers: { 'Authorization': 'Bearer ' + token }
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async getRefTelegram(data: any) {
        try {
            const call = await axios.get(url + '/telegram-get-ref', {
                withCredentials: true,
                headers: data
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async loginGoogle(token: string, ref: string) {
        try {
            const call = await axios.post(url + '/login/google', { token, ref }, {
                withCredentials: true
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public async refreshToken() {
        try {
            const call = await axios.post(url + '/refresh-token', null, {
                withCredentials: true,
                headers: {
                    "tktk": localStorage.getItem("tktk")
                }
            });
            return call.data;
        } catch (error: any) {
            return error.response.data
        }
    }

    public isAccessTokenExpired(token: string) {
        if (!token) return true;

        const payload = JSON.parse(atob(token.split(".")[1]));
        const expirationTime = payload.exp * 1000;
        return Date.now() > expirationTime;
    };
}