<template>
  <v-sheet class="border-radius--default" color="#0057FF" dark >
    <div class="pa-4">
      <div class="d-flex justify-start align-center">
        <v-img
          class="rounded-circle"
          max-width="var(--size-avatar)"
          max-height="var(--size-avatar)"
          src="../../assets/game_mining/telegram.png"
          v-if="typeMapPage == 'telegram'"
        ></v-img>
        <v-img
          class="rounded-circle"
          max-width="var(--size-avatar)"
          max-height="var(--size-avatar)"
          :src="user.picture"
          v-else
        ></v-img>
        <div class="ml-3">
          <div class="d-flex">
            <div class="fs-small">{{ lang["TEXT_147"] }}</div>
            <v-img
              contain
              class="ml-3"
              max-width="12"
              src="@/assets/wallet/hello.png"
            ></v-img>
          </div>
          <div class="font-weight-semibold">
            {{typeMapPage == 'telegram' ? user.last_name + " " + user.first_name : user.name }}
          </div>
        </div>
      </div>
      <v-row class="white--text mt-3">
        <v-col cols="8" class="font-family--plus-jakarta-sans">
          <div class="d-flex">
            <div>{{ lang["TEXT_5"] }}</div>
            <div class="ml-2 cursor-pointer" @click="showBalanceFunc()">
              <v-icon size="15" v-if="showBalance">mdi-eye</v-icon>
              <v-icon size="15" v-else>mdi-eye-off</v-icon>
            </div>
          </div>
          <h1
            xyz="fade flip-up front-0"
            class="d-flex font-weight-bold square xyz-in"
          >
            {{ showBalance ? formatPrice(totalUSDT) : '************' }}
            <v-btn outlined class="ml-3 mt-3" text small depressed>
              USD
              <v-icon size="var(--size-icon)" class="ml-1">
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </h1>
        </v-col>
        <v-col cols="4">
          <v-img
            class="float-end"
            contain
            max-width="var(--size-icon-large)"
            src="@/assets/wallet/reset.png"
          ></v-img>
        </v-col>
        <v-col cols="12" class="font-family--poppins">
          <div class="d-flex mt-3 align-center">
            <v-img
              max-width="var(--size-icon)"
              height="var(--size-icon)"
              src="@/assets/wallet/arrow_up.png"
            ></v-img>
            <div class="ml-1 fs-large">$1,816</div>
            <div class="ml-1 fs-small">Today’s Profit</div>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-sheet>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: ["formatPrice", "lang", "totalUSDT", "user", "showBalance", "showBalanceFunc", "typeMapPage"],
  data: () => ({
    
  }),
});
</script>

