<template>
  <v-card
    :style="{
      boxShadow: 'none !important',
      borderRadius: '0px 0px 0px 0px !important',
    }"
    class="fs font-manrope"
    :height="
      typeMap == 'wallet' || typeMap == 'game' ? size.height : size.height / 1.2
    "
    max-width="100%"
    :width="size.onSize <= 450 ? size.onSize : 450"
  >
    <div
      class="scroll-list--token"
      :style="{
        height: size.height + 'px',
      }"
    >
      <div v-if="map == 'home'">
        <v-sheet color="#071B60" dark>
          <div style="padding: 15px 10px">
            <v-row no-gutters>
              <v-col
                cols="6"
                class="text-start d-flex justify-start align-center"
              >
                <v-avatar
                  v-if="typeMap == 'game'"
                  color="white"
                  size="25"
                  @click="pushLink(typeMapPage == 'telegram' ? '' : 'home')"
                  class="cursor-pointer"
                >
                  <v-icon color="#1e2329">mdi mdi-chevron-left</v-icon>
                </v-avatar>

                <v-img
                  v-else
                  contain
                  max-width="60%"
                  src="../../assets/wallet/logo.png"
                ></v-img>
              </v-col>

              <v-col cols="6">
                <div class="d-flex float-end">
                  <v-img
                    contain
                    max-width="var(--size-icon-x-large)"
                    max-height="var(--size-icon-x-large)"
                    src="@/assets/wallet/gift.png"
                  ></v-img>
                  <div
                    style="border: 1px solid #0057ff; width: 47px"
                    class="d-flex justify-center align-center ml-2 border-radius--small"
                  >
                    <v-img
                      contain
                      max-width="var(--size-icon-small)"
                      height="var(--size-icon-small)"
                      src="@/assets/wallet/icon_chat.png"
                    ></v-img>

                    <v-img
                      contain
                      class="ml-2"
                      max-width="var(--size-icon-small)"
                      height="var(--size-icon-small)"
                      src="@/assets/wallet/icon_notification.png"
                    ></v-img>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-sheet>

        <div
          class="scroll-list--token"
          :style="{
            height: size.height + 'px',
          }"
        >
          <v-sheet
            color="var(--color-blue)"
            class="white--text"
            style="border-radius: 0px 0px 20px 20px !important"
          >
            <div class="d-flex justify-center align-center pt-3 pb-3">
              <v-btn small class="text-none font-weight-semibold" depressed>
                <span class="pr-6">{{ lang["TEXT_294"] }}</span>
              </v-btn>
              <v-badge overlap color="red" content="soon">
                <v-btn
                  small
                  class="text-none font-weight-semibold"
                  :style="{
                    marginLeft: '-35px',
                  }"
                  height="30"
                  dark
                  color="#0057FF"
                  depressed
                >
                  <span>{{ lang["TEXT_295"] }}</span>
                </v-btn>
              </v-badge>
            </div>

            <v-sheet
              color="var(--color-blue)"
              style="border-radius: 0px 0px 20px 20px !important"
            >
              <div class="pl-3 pr-3 pb-3">
                <TotalViewComponent
                  :showBalance="showBalance"
                  :showBalanceFunc="showBalanceFunc"
                  :user="user"
                  :totalUSDT="totalUSDT"
                  :formatPrice="formatPrice"
                  :lang="lang"
                  :typeMapPage="typeMapPage"
                />
              </div>
            </v-sheet>
          </v-sheet>

          <v-sheet color="white">
            <v-row
              class="d-flex mt-5 justify-center align-center text-center"
              no-gutters
            >
              <v-col
                cols="3"
                v-for="(item, i) in [
                  {
                    name: lang['TEXT_1'],
                    icon: 'mdi-arrow-up',
                    value: 'send',
                  },
                  {
                    name: lang['TEXT_2'],
                    icon: 'mdi-arrow-down',
                    value: 'deposit',
                  },
                  {
                    name: lang['TEXT_3'],
                    icon: 'mdi-swap-horizontal-variant',
                    value: 'swap',
                  },
                  {
                    name: lang['TEXT_4'],
                    icon: 'mdi-history',
                    value: 'history',
                  },
                ]"
                :key="i"
                @click="changeButtonHome(item.value)"
              >
                <v-btn
                  depressed
                  fab
                  class="square xyz-in"
                  color="var(--color-blue-v2)"
                  width="50"
                  height="50"
                  dark
                >
                  <v-avatar size="20" tile>
                    <v-img
                      contain
                      v-if="item.value == 'send'"
                      src="@/assets/wallet/icon_send.png"
                    ></v-img>

                    <v-img
                      contain
                      v-else-if="item.value == 'deposit'"
                      src="@/assets/wallet/icon_deposit.png"
                    ></v-img>

                    <v-img
                      contain
                      v-else-if="item.value == 'swap'"
                      src="@/assets/wallet/icon_swap.png"
                    ></v-img>

                    <v-avatar contain v-else>
                      <v-icon size="25">{{ item.icon }}</v-icon>
                    </v-avatar>
                  </v-avatar>
                </v-btn>
                <div class="mt-2 font-weight-medium">
                  {{ item.name }}
                </div>
              </v-col>
            </v-row>
          </v-sheet>

          <div class="pa-3 mt-3">
            <TotalTransactionViewComponent :typeMap="typeMap" />
          </div>

          <div class="pa-3" v-if="typeMap === 'wallet'">
            <SliderViewComponent />
          </div>

          <v-sheet class="mt-3">
            <MyTokenViewComponent
              :lang="lang"
              :formatPrice="formatPrice"
              :coins="coins"
              :mapDetails="mapDetails"
              :isLoadingCoins="isLoadingCoins"
              :showBalance="showBalance"
              :changeMap="changeMap"
            />
          </v-sheet>

          <v-sheet class="mt-3" v-if="typeMap === 'wallet'">
            <div class="pa-1">
              <NewsViewComponent />
            </div>
          </v-sheet>

          <v-sheet height="200" v-if="typeMap === 'wallet'"></v-sheet>
        </div>
      </div>

      <div
        v-else-if="map == 'details'"
        class="scroll-list--token"
        :style="{
          height: size.height - 100 + 'px',
        }"
      >
        <DetailsComponent
          :coin="coin"
          :lang="lang"
          :formatPrice="formatPrice"
          :formatDate="formatDate"
          :returnDesc="returnDesc"
          :isLoading="isLoadingTransaction"
          :transactions="transactions"
          :method="methodTransaction"
          :size="size"
          :changeMap="changeMap"
          :changeActionTransaction="changeActionTransaction"
          :typeMap="typeMap"
          :statusCode="statusCode"
          :typeMapPage="typeMapPage"
          :getHashTelegram="getHashTelegram"
        />
      </div>

      <div v-else-if="map == 'choose_token'">
        <ChooseTokenComponent
          :isLoading="isLoadingCoins"
          :lang="lang"
          :size="size"
          :coins="coins"
          :formatPrice="formatPrice"
          :close="close"
          :changeLinkChooseToken="changeLinkChooseToken"
          :typeChoose="typeChoose"
          :typeMap="typeMap"
        />
      </div>

      <div v-else-if="map == 'transfers'">
        <TransfersComponent
          :lang="lang"
          :size="size"
          :coin="coin"
          :formatPrice="formatPrice"
          :fee="fee"
          :refreshTokenAction="refreshTokenAction"
          :notificationCall="notificationCallWallet"
          :updateBalance="updateBalance"
          :chooseToken="chooseToken"
          :close="close"
          :typeMap="typeMap"
          :statusCode="statusCode"
          :typeMapPage="typeMapPage"
          :getHashTelegram="getHashTelegram"
        />
      </div>

      <div v-else-if="map == 'withdraw'">
        <WithdrawComponent
          :lang="lang"
          :size="size"
          :coin="coin"
          :formatPrice="formatPrice"
          :fee="fee"
          :refreshTokenAction="refreshTokenAction"
          :notificationCall="notificationCallWallet"
          :updateBalance="updateBalance"
          :chooseToken="chooseToken"
          :close="close"
          :coinChains="coinChains"
          :isLoadingCoinChain="isLoadingCoinChain"
          :typeMap="typeMap"
          :statusCode="statusCode"
          :typeMapPage="typeMapPage"
          :getHashTelegram="getHashTelegram"
        />
      </div>

      <div v-else-if="map == 'deposit'">
        <DepositComponent
          :lang="lang"
          :size="size"
          :coin="coin"
          :formatPrice="formatPrice"
          :fee="fee"
          :refreshTokenAction="refreshTokenAction"
          :notificationCall="notificationCallWallet"
          :updateBalance="updateBalance"
          :chooseToken="chooseToken"
          :close="close"
          :coinChains="coinChains"
          :isLoadingCoinChain="isLoadingCoinChain"
          :copy="copy"
          :coins="coins"
          :typeMap="typeMap"
          :statusCode="statusCode"
          :typeMapPage="typeMapPage"
          :getHashTelegram="getHashTelegram"
        />
      </div>

      <div v-else-if="map == 'open_nft'">
        <OpenNFTComponent
          :lang="lang"
          :size="size"
          :coin="coin"
          :formatPrice="formatPrice"
          :fee="fee"
          :refreshTokenAction="refreshTokenAction"
          :notificationCall="notificationCallWallet"
          :updateBalance="updateBalance"
          :chooseToken="chooseToken"
          :close="close"
          :coinChains="coinChains"
          :isLoadingCoinChain="isLoadingCoinChain"
          :copy="copy"
          :coins="coins"
          :statusCode="statusCode"
          :updateUser="updateUser"
          :user="user"
          :typeMap="typeMap"
        />
      </div>

      <div v-else-if="map == 'swap'">
        <SwapComponent
          :lang="lang"
          :size="size"
          :coin="coin"
          :formatPrice="formatPrice"
          :fee="fee"
          :refreshTokenAction="refreshTokenAction"
          :notificationCall="notificationCallWallet"
          :updateBalance="updateBalance"
          :chooseToken="chooseToken"
          :close="close"
          :coinChains="coinChains"
          :isLoadingCoinChain="isLoadingCoinChain"
          :copy="copy"
          :coins="coins"
          :typeMap="typeMap"
          :statusCode="statusCode"
          :typeMapPage="typeMapPage"
          :getHashTelegram="getHashTelegram"
        />
      </div>

      <div v-else-if="map == 'manage-token'">
        <ManageTokenHomeComponent
          :lang="lang"
          :size="size"
          :coins="coins"
          :formatPrice="formatPrice"
          :close="close"
        />
      </div>

      <v-sheet height="300" v-if="!typeMap"></v-sheet>
    </div>

    <v-overlay :absolute="absolute" :value="navNotification">
      <v-card
        color="white"
        :style="{
          boxShadow: 'none !important',
          borderRadius: '15px',
        }"
      >
        <div class="pa-6 black--text">
          <div>
            {{ titleNotification }}
          </div>

          <div class="d-flex justify-center mt-3">
            <v-avatar
              tile
              size="35"
              color="var(--color-black1)"
              class="cursor-pointer"
              @click="navNotification = false"
              :style="{
                borderRadius: '10px !important',
              }"
            >
              <v-icon color="white">mdi-close</v-icon>
            </v-avatar>
          </div>
        </div>
      </v-card>
    </v-overlay>

    <v-bottom-sheet
      v-model="navSend"
      persistent
      v-if="typeMap == 'wallet' || typeMap == 'game'"
    >
      <v-sheet height="120%" color="transparent">
        <center>
          <v-sheet max-width="450" color="transparent">
            <ChooseSendViewComponent
              :lang="lang"
              :chooseToken="chooseToken"
              :clickNavSend="clickNavSend"
              :typeMap="typeMap"
            />
          </v-sheet>
        </center>
      </v-sheet>
    </v-bottom-sheet>

    <v-overlay
      v-else
      :absolute="absolute"
      :value="navSend"
      class="d-flex justify-center align-center text-center"
    >
      <center>
        <ChooseSendViewComponent
          :lang="lang"
          :chooseToken="chooseToken"
          :clickNavSend="clickNavSend"
        />
      </center>
    </v-overlay>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";

import DetailsComponent from "@/components/walletLite/Details.vue";
import ChooseTokenComponent from "@/components/walletLite/ChooseToken.vue";
import TransfersComponent from "@/components/walletLite/Transfers.vue";
import WithdrawComponent from "@/components/walletLite/Withdraw.vue";
import DepositComponent from "@/components/walletLite/Deposit.vue";
import SwapComponent from "@/components/walletLite/Swap.vue";
import OpenNFTComponent from "@/components/walletLite/OpenNFT.vue";
import ManageTokenHomeComponent from "@/components/walletLite/ManageTokenHome.vue";

import TotalViewComponent from "@/components/view/Total.vue";
import TotalTransactionViewComponent from "@/components/view/TotalTransaction.vue";
import MyTokenViewComponent from "@/components/view/MyToken.vue";
import NewsViewComponent from "@/components/view/News.vue";
import SliderViewComponent from "@/components/view/Slider.vue";
import ChooseSendViewComponent from "@/components/view/ChooseSend.vue";

import { WalletApi } from "@/api/WALLET";
const walletApi = new WalletApi();

export default Vue.extend({
  props: [
    "user",
    "lang",
    "refreshTokenAction",
    "formatPrice",
    "size",
    "formatDate",
    "copy",
    "mapMain",
    "dialogWallet",
    "statusCode",
    "updateUser",
    "height",
    "typeMap",
    "pushLink",
    "typeMapPage",
    "getHashTelegram",
  ],
  data: () => ({
    showBalance: false as any,
    absolute: false,
    navSend: false,
    navNotification: false,
    isLoadingCoins: true,
    isLoadingTransaction: true,
    coins: [] as any,
    totalUSDT: 0,
    map: "home",
    coin: {} as any,
    transactions: [] as any,
    methodTransaction: "all",
    typeChoose: "",
    titleNotification: "",
    fee: {} as any,
    coinChains: [],
    isLoadingCoinChain: true,
    animationBottom: "square xyz-in",
  }),
  watch: {
    dialogWallet() {
      this.map = "open_nft";
    },
  },
  components: {
    ManageTokenHomeComponent,
    SliderViewComponent,
    MyTokenViewComponent,
    TotalTransactionViewComponent,
    TotalViewComponent,
    SwapComponent,
    DetailsComponent,
    ChooseTokenComponent,
    TransfersComponent,
    WithdrawComponent,
    DepositComponent,
    OpenNFTComponent,
    NewsViewComponent,
    ChooseSendViewComponent,
  },
  async created() {
    const showBalance = localStorage.getItem("show-balance");
    if (showBalance == "show") {
      this.showBalance = true;
    } else {
      this.showBalance = false;
    }
    await this.loadCoin();
    await this.getFee();
    this.map = this.mapMain;
    this.coin = this.coins[0];
  },
  methods: {
    showBalanceFunc() {
      if (!this.showBalance == true) {
        localStorage.setItem("show-balance", "show");
      } else {
        localStorage.setItem("show-balance", "off");
      }
      this.showBalance = !this.showBalance;
    },
    updateBalance(symbol: string, amount: number, action: string) {
      const findCoin = this.coins.find((x: any) => x.symbol == symbol);
      if (findCoin) {
        if (action == "-") {
          findCoin.myAmount = findCoin.myAmount - Number(amount);
        } else {
          findCoin.myAmount = findCoin.myAmount + Number(amount);
        }
      }
    },
    async getFee() {
      const call = await walletApi.getFee();
      if (call.success) {
        this.fee = call.data;
      } else {
        this.notificationCallWallet(this.lang["TEXT_48"]);
      }
    },
    notificationCallWallet(text: string) {
      this.navNotification = true;
      this.titleNotification = text;
    },
    async changeLinkChooseToken(item: any, type: string) {
      if (type == "transfers") {
        const isCoin = this.coins.find(
          (x: any) => x.symbol == item.symbol && x.isTransfer
        );
        if (!isCoin) {
          this.notificationCallWallet(
            this.lang["TEXT_78"]?.replace("_symbol", item.symbol)
          );
        } else {
          this.map = "transfers";
          this.coin = isCoin;
        }
      } else if (type == "withdraw") {
        const isCoin = this.coins.find(
          (x: any) => x.symbol == item.symbol && x.isWithdraw
        );

        if (!isCoin) {
          this.notificationCallWallet(
            this.lang["TEXT_78"]?.replaceAll("_symbol", item.symbol)
          );
        } else {
          const callCoinChain = await walletApi.getCoinChain(item._id);
          if (callCoinChain.success) {
            if (callCoinChain.data.length <= 0) {
              this.notificationCallWallet(
                this.lang["TEXT_90"].replace("_symbol", item.symbol)
              );
            } else {
              this.map = "withdraw";
              this.coin = isCoin;
              this.coinChains = callCoinChain.data;
              this.isLoadingCoinChain = false;
            }
          } else {
            this.notificationCallWallet(
              this.lang["TEXT_48"].replace("_symbol", item.symbol)
            );
            return;
          }
        }
      } else if (type == "deposit") {
        const callCoinChain = await walletApi.getCoinChain(item._id);
        if (callCoinChain.success) {
          if (callCoinChain.data.length <= 0) {
            this.notificationCallWallet(
              this.lang["TEXT_46"].replace("_symbol", item.symbol)
            );
          } else {
            this.map = "deposit";
            this.coin = item;
            this.coinChains = callCoinChain.data;
            this.isLoadingCoinChain = false;
          }
        } else {
          this.notificationCallWallet(
            this.lang["TEXT_48"].replace("_symbol", item.symbol)
          );
          return;
        }
      } else if (type == "open_box") {
        this.map = "open_nft";
        this.coin = item;
      } else {
        this.mapDetails(item);
      }
    },
    close(type: string) {
      this.map = "home";
    },
    chooseToken(type: string) {
      this.navSend = false;
      this.map = "choose_token";
      this.typeChoose = type;
    },
    changeAll() {
      this.navSend = false;
    },
    changeButtonHome(value: string) {
      if (value == "send") {
        this.clickNavSend();
      } else if (value == "deposit") {
        this.chooseToken("deposit");
      } else if (value == "swap") {
        this.map = "swap";
      } else if (value == "history") {
        this.chooseToken("history");
      }
    },
    // Send
    clickNavSend() {
      this.navSend = !this.navSend;
    },
    changeMap(map: string) {
      this.map = map;
    },
    // Details
    async loadTransactionBySymbol(ref = "all", symbol: string) {
      this.isLoadingTransaction = true;

      let call: any;
      if (this.typeMapPage == "telegram") {
        call = await walletApi.getTransactionsTelegram(
          ref,
          this.getHashTelegram(),
          symbol
        );
      } else {
        const accessToken = await this.refreshTokenAction();
        if (!accessToken) {
          return;
        }
        call = await walletApi.getTransactions(ref, accessToken, symbol);
      }

      if (call.success) {
        this.transactions = this.groupByTime(call.data);
        this.isLoadingTransaction = false;
      }
    },
    async changeActionTransaction(method: string) {
      this.methodTransaction = method;
      await this.loadTransactionBySymbol(method, this.coin.symbol);
    },
    groupByTime(data: any) {
      const grouped = data.reduce((acc: any, item: any) => {
        const date = new Date(item.createdAt).toISOString().split("T")[0]; // YYYY-MM-DD

        if (!acc[date]) {
          acc[date] = [];
        }

        acc[date].push(item);

        return acc;
      }, {});

      return Object.keys(grouped).map((date) => ({
        date,
        items: grouped[date],
      }));
    },
    returnDesc(str: string) {
      const [type, ...rest] = str.split(":");

      const result: any = { type };

      rest.forEach((part) => {
        const [key, value] = part.split("_");
        result[key] = value;
      });

      return result;
    },
    mapDetails(item: any) {
      this.map = "details";
      this.coin = item;
      this.loadTransactionBySymbol("all", item.symbol);
    },
    async loadWallet() {
      let call: any;
      if (this.typeMapPage == "telegram") {
        call = await walletApi.getWalletTelegram(this.getHashTelegram());
      } else {
        const accessToken = await this.refreshTokenAction();
        if (!accessToken) {
          return;
        }
        call = await walletApi.getWallet(accessToken);
      }

      if (call.success) {
        let amount = 0;
        for (let item of call.data) {
          const findCoin = this.coins.find((x: any) => x.symbol == item[0]);
          if (findCoin) {
            findCoin.myAmount = item[1];
            amount = amount + item[1] * findCoin.price;
          }
        }
        this.totalUSDT = amount;
      }
    },
    async loadCoin() {
      this.isLoadingCoins = true;
      const call = await walletApi.getCoin();
      if (call.success) {
        this.coins = call.data;
        this.isLoadingCoins = false;
        setTimeout(async () => {
          if (this.user) {
            await this.loadWallet();
          }
        }, 200);
      } else {
      }
    },
  },
});
</script>

<style scoped>
.fs-small {
  font-size: 12px !important;
}
</style>
