import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import LayoutLayout from "../views/layout/_layout.vue"
import LayoutLayoutNft from "../views/layout/_layout_nft.vue"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        component: LayoutLayout,
        children: [
            {
                path: "/",
                name: "layout",
                component: () => import("../views/Home/Layout.vue")
            },
        ]
    },
    {
        path: '/',
        component: LayoutLayoutNft,
        children: [
            {
                path: "/nft",
                name: "nft",
                component: () => import("../views/Home/Nft.vue")
            }
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
