<template>
  <v-card
    class="square xyz-in"
    xyz="inherit down"
    color="var(--color-bg-card)"
    :style="{
      boxShadow: 'none !important',
      borderRadius:
        typeMap == 'wallet' || typeMap == 'game' ? '15px 15px 0px 0px' : '15px',
    }"
    :width="typeMap == 'wallet' || typeMap == 'game' ? '100%' : '70%'"
  >
    <v-toolbar
      :style="{
        borderRadius: '15px',
      }"
      color="var(--color-bg-card)"
      class="none-boxShadow"
    >
      <v-toolbar-title
        class="fs-x-large font-weight-bold d-flex justify-center text-center text-black"
        :style="{
          width: '100%',
        }"
      >
        {{ lang["TEXT_99"] }} {{ typeMap }}
      </v-toolbar-title>
    </v-toolbar>

    <v-sheet
      class="pa-2"
      :style="{
        borderRadius:
          typeMap == 'wallet' || typeMap == 'game'
            ? '15px 15px 0px 0px'
            : '15px',
      }"
      color="white"
    >
      <v-sheet
        color="rgb(0, 87, 255, 0.2)"
        :style="{
          borderRadius: '10px',
        }"
        class="fs-small text-black"
      >
        <div class="pa-4 text-start font-manrope">
          {{ lang["TEXT_293"] }}
        </div>
      </v-sheet>
      <div class="scroll-list--token font-manrope">
        <div>
          <v-row no-gutters v-if="isLoadingCoinChain">
            <v-col cols="12" class="mt-1" v-for="n in 3" :key="n">
              <v-skeleton-loader
                type="image"
                height="90"
                class="text--opacity_04"
              ></v-skeleton-loader>
            </v-col>
          </v-row>

          <div v-else>
            <v-sheet
              style="background-color: white !important"
              v-for="item in coinChains"
              :key="item._id"
              class="border-radius--s-default mt-3 cursor-pointer border text-start text-black"
              :style="
                item._id == chain._id ? 'border: 1px solid gray !important' : ''
              "
              @click="chooseChain(item)"
            >
              <div class="pa-3">
                <v-row>
                  <v-col cols="12" class="d-flex justify-start align-center">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <div class="font-size-large">
                          <v-avatar size="15">
                            <v-img :src="item.chain.image"></v-img>
                          </v-avatar>
                          <span class="ml-2 font-weight-semibold">{{
                            item.chain.symbol
                          }}</span>
                          <span class="font-weight-semibold text-gray1 ml-2">{{
                            item.chain.name
                          }}</span>
                        </div>
                      </v-col>

                      <v-col
                        cols="6"
                        v-if="method != 'deposit'"
                        class="fs-small text-gray1 mt-2"
                      >
                        {{ lang["TEXT_92"] }}:
                        {{
                          item.chain.feeUSD
                            ? formatPrice(item.chain.feeUSD * coin.price) +
                              " " +
                              item.coin.symbol
                            : lang["TEXT_85"]
                        }}
                      </v-col>

                      <v-col
                        cols="6"
                        v-if="method != 'deposit'"
                        class="fs-small text-gray1 mt-2 text-end"
                      >
                        {{ lang["TEXT_292"] }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-sheet>
          </div>
        </div>
      </div>

      <div class="d-flex justify-center mt-3">
        <v-avatar
          tile
          size="35"
          color="var(--color-black1)"
          class="cursor-pointer"
          @click="closeChain()"
          :style="{
            borderRadius: '10px !important',
          }"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-avatar>
      </div>
    </v-sheet>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: [
    "dialogChain",
    "lang",
    "isLoadingCoinChain",
    "coinChains",
    "chooseChain",
    "chain",
    "formatPrice",
    "coin",
    "typeMap",
    "closeChain",
    "method",
  ],
  data: () => ({
    //
  }),
});
</script>

