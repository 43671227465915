<template>
  <v-row no-gutters>
    <v-col cols="3">
      <div class="d-flex">
        <v-avatar size="var(--size-icon-xx-large)">
          <v-img
            v-if="symbol == 'GOF'"
            contain
            src="../../../assets/game_mining/token.png"
            class="rounded-circle"
          ></v-img>

          <v-img
            v-else
            contain
            :src="image"
            class="rounded-circle"
          ></v-img>
        </v-avatar>
        <div class="ml-2">
          <div class="font-weight-semibold fs">{{ name }}</div>
          <div class="fs-small mt-1 text-o-9">{{ symbol }}</div>
        </div>
      </div>
    </v-col>
    <v-col cols="9" class="text-end">
      <div>
        <div class="ml-3">
          <div class="font-weight-semibold fs">
            {{ showBalance ? formatPrice(myAmount) : '***' }} {{ symbol }}
          </div>
          <div class="mt-1 fs-small">
            <span class="font-weight-medium text-o-9">
              ${{ formatPrice(price) }}
            </span>
            <span class="font-weight-semibold">
              <span class="ml-1 error--text" v-if="CHANGEPCT24HOUR < 0">
                {{ Math.abs(Number(CHANGEPCT24HOUR.toFixed(2))) }}%
              </span>
              <span class="ml-1 success--text" v-else-if="CHANGEPCT24HOUR > 0">
                {{ CHANGEPCT24HOUR.toFixed(2) }}%
              </span>
              <span class="ml-1" v-else>
                {{ CHANGEPCT24HOUR.toFixed(2) }}%
              </span>
            </span>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: {
    image: {
      type: String,
      required: true,
    },
    lang: {
      type: Object,
    },
    name: {
      type: String,
      required: true,
    },
    symbol: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    CHANGEPCT24HOUR: {
      type: Number,
      required: true,
    },
    formatPrice: { required: false, type: Function },
    myAmount: { type: Number, required: true },
    type: {},
    showBalance: {}
  },
  data: () => ({
    //
  }),
});
</script>
