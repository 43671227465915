<template>
  <v-sheet
    :width="!typeMap ? '100%' : size.onSize <= 450 ? size.onSize : 450"
    class="text-start"
    style="background-color: var(--color-bg-card)"
  >
    <v-sheet>
      <v-toolbar
        height="40"
        color="var(--color-bg-card)"
        class="none-boxShadow"
      >
        <v-btn
          @click="changeMap('home')"
          style="border-radius: 50% !important"
          color="white"
          fab
          depressed
          width="var(--size-icon-x-large)"
          height="var(--size-icon-x-large)"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title
          class="fs-x-large font-weight-bold d-flex justify-center"
          style="width: 90%"
        >
          <v-avatar size="25">
            <v-img
              v-if="coin.symbol == 'GOF'"
              src="../../assets/game_mining/token.png"
            ></v-img>
            <v-img v-else :src="coin.image"></v-img>
          </v-avatar>
          <span class="ml-2">{{ coin.name }}</span>
        </v-toolbar-title>
      </v-toolbar>
    </v-sheet>

    <v-sheet color="white" style="border-radius: 30px 30px 0px 0px">
      <div class="pa-3">
        <div>{{ lang["TEXT_24"] }}</div>
        <div class="font-weight-bold fs-xx-large">
          {{ formatPrice(coin.myAmount) }}
          <span class="fs-small text-o-7"
            >~ ${{ formatPrice(coin.myAmount * coin.price) }}</span
          >
        </div>

        <div class="mt-3">
          <div class="font-weight-semibold">{{ lang["TEXT_4"] }}:</div>

          <div class="mt-3 d-flex">
            <div
              v-for="item of [
                {
                  value: 'all',
                  name: lang['TEXT_14'],
                },
                {
                  value: 'deposit_withdraw',
                  name: lang['TEXT_241'],
                },
                // {
                //   value: 'transfers',
                //   name: lang['TEXT_242'],
                // },
              ]"
              :key="item.value"
              class="pa-1"
              @click="changeActionTransaction(item.value)"
            >
              <v-btn
                small
                :color="method == item.value ? 'var(--color-blue-v2)' : ''"
                :dark="method == item.value"
                class="fs-small font-weight-medium text-none"
                depressed
              >
                {{ item.name }}
              </v-btn>
            </div>
          </div>

          <div class="mt-3" xyz="fade up out-down stagger">
            <div v-if="isLoading">
              <v-row no-gutters>
                <v-col cols="12" class="mt-3" v-for="n in 10" :key="n">
                  <v-skeleton-loader
                    type="image"
                    height="80"
                    class="text--opacity_04"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </div>
            <div v-if="transactions.length <= 0" class="mt-2">
              {{ lang["TEXT_243"] }}
            </div>

            <div
              class="scroll-list--token"
              :style="`height: ${size.height / 1.5}px !important`"
            >
              <div
                v-for="item in transactions"
                :key="item.date"
                class="square xyz-in"
              >
                <div class="mt-3 text-center text-o-7 fs-large">
                  {{ formatDate(item.date) }}
                </div>
                <v-sheet
                  v-for="jtem in item.items"
                  :key="jtem._id"
                  class="border-bottom mt-3"
                >
                  <div>
                    <v-row no-gutters>
                      <v-col cols="8">
                        <div class="d-flex">
                          <div class="font-weight-semibold">
                            <div v-if="jtem.ref == 'withdraw'">
                              {{ lang["TEXT_116"] }}
                            </div>
                            <div v-else-if="jtem.ref == 'swap'">
                              {{ lang["TEXT_120"] }}
                            </div>
                            <div v-else-if="jtem.ref == 'transfers'">
                              {{ lang["TEXT_89"] }}
                            </div>
                            <div v-else-if="jtem.ref == 'deposit'">
                              {{ lang["TEXT_121"] }}
                            </div>
                            <div v-else-if="jtem.ref == 'mission_game'">
                              {{ lang["TEXT_144"] }}
                            </div>
                            <div v-else-if="jtem.ref == 'transfers_received'">
                              {{ lang["TEXT_239"] }}
                            </div>
                            <div v-else-if="jtem.ref == 'buy_fruit'">
                              {{ lang["TEXT_276"] }}
                            </div>
                            <div v-else-if="jtem.ref == 'buy_box_nft'">
                              {{ lang["TEXT_277"] }}
                            </div>
                            <div v-else-if="jtem.ref == 'TESTNET'">
                              {{ lang["TEXT_285"] }}
                            </div>
                            <div
                              v-else-if="
                                jtem.ref == ' transfers_buy_marketplace '
                              "
                            >
                              {{ lang["TEXT_286"] }}
                            </div>
                            <div v-else>
                              {{ jtem.ref }}
                            </div>
                          </div>

                          <div class="ml-1">
                            <div
                              v-if="jtem.status == 'pending'"
                              :style="{
                                color: `orange`,
                              }"
                            >
                              {{ lang["TEXT_117"] }}
                            </div>
                            <div
                              v-else-if="jtem.status == 'approved'"
                              :style="{
                                color: `var(--color-success)`,
                              }"
                            >
                              {{ lang["TEXT_118"] }}
                            </div>
                            <div
                              v-else
                              :style="{
                                color: `var(--color-red)`,
                              }"
                            >
                              {{ lang["TEXT_119"] }}
                            </div>
                          </div>
                        </div>
                        <div class="text-o-7 font-weight-regular fs-small">
                          <div v-if="jtem.ref == 'withdraw'">
                            {{
                              lang["TEXT_238"]
                                ?.replaceAll("_symbol", jtem.symbol)
                                .replaceAll("_address", jtem.to)
                                .replaceAll(
                                  "_amount",
                                  returnDesc(jtem.desc).RECEIVE
                                )
                                .replaceAll("_fee", returnDesc(jtem.desc).FEE)
                            }}
                          </div>
                          <div v-else-if="jtem.ref == 'swap'">
                            {{
                              lang["TEXT_235"]
                                ?.replace(
                                  "_symbolFrom",
                                  jtem.action == "transfers"
                                    ? returnDesc(jtem.desc).FROM
                                    : returnDesc(jtem.desc).F
                                )
                                .replace(
                                  "_symbolTo",
                                  jtem.action == "transfers"
                                    ? returnDesc(jtem.desc).TO
                                    : returnDesc(jtem.desc).T
                                )
                            }}
                          </div>
                          <div v-else-if="jtem.ref == 'transfers'">
                            {{
                              lang["TEXT_237"]
                                ?.replaceAll("_symbol", jtem.symbol)
                                .replaceAll("_user", returnDesc(jtem.desc).ER)
                                .replaceAll("_fee", returnDesc(jtem.desc).FEE)
                            }}
                          </div>
                          <div v-else-if="jtem.ref == 'deposit'">
                            {{
                              lang["TEXT_236"]?.replace("_symbol", jtem.symbol)
                            }}
                          </div>
                          <div v-else-if="jtem.ref == 'mission_game'">
                            {{ lang["TEXT_144"] }}
                          </div>
                          <div v-else-if="jtem.ref == 'transfers_received'">
                            {{
                              lang["TEXT_240"]
                                ?.replaceAll("_symbol", jtem.symbol)
                                .replaceAll("_user", returnDesc(jtem.desc).E)
                            }}
                          </div>
                        </div>
                        <div class="text-o-7 font-weight-regular fs-small">
                          {{ formatDate(jtem.createdAt) }}
                        </div>
                      </v-col>
                      <v-col
                        cols="4"
                        class="font-weight-semibold d-flex justify-end align-center"
                      >
                        <div
                          :style="{
                            color: `var(--color-success)`,
                          }"
                          v-if="
                            jtem.action == 'deposit' || jtem.action == 'receive'
                          "
                        >
                          + {{ formatPrice(jtem.amount) }} {{ jtem.symbol }}
                        </div>
                        <div
                          v-else
                          :style="{
                            color: `var(--color-red)`,
                          }"
                        >
                          - {{ formatPrice(jtem.amount) }} {{ jtem.symbol }}
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="mt-2"></div>
                </v-sheet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-sheet>
  </v-sheet>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: [
    "coin",
    "lang",
    "formatPrice",
    "formatDate",
    "returnDesc",
    "isLoading",
    "transactions",
    "method",
    "size",
    "changeMap",
    "changeActionTransaction",
    "typeMap"
  ],
  data: () => ({
    //
  }),
});
</script>

