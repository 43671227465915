<template>
  <v-sheet
    :width="!typeMap ? '100%' : size.onSize <= 450 ? size.onSize : 450"
    class="text-start"
    style="background-color: var(--color-bg-card)"
  >
    <v-sheet>
      <v-toolbar
        height="40"
        color="var(--color-bg-card)"
        class="none-boxShadow"
      >
        <v-btn
          style="border-radius: 50% !important"
          color="white"
          fab
          depressed
          small
          width="var(--size-icon-x-large)"
          height="var(--size-icon-x-large)"
          @click="chooseToken('withdraw')"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title
          class="fs-x-large font-weight-bold d-flex justify-center"
          style="width: 90%"
        >
          {{ lang["TEXT_97"] }} {{ coin.symbol }}</v-toolbar-title
        >
      </v-toolbar>
    </v-sheet>

    <v-sheet color="white" style="border-radius: 30px 30px 0px 0px">
      <div class="pa-3">
        <div class="d-flex justify-center">
          <div>
            <v-btn
              depressed
              color="var(--color-blue-v2)"
              dark
              width="150"
              :style="{
                zIndex: '2',
              }"
              class="text-none border-radius--default fs font-weight-semibold"
            >
              Crypto
            </v-btn>
            <v-btn
              depressed
              color="#F1F3F8"
              width="150"
              disabled
              :style="{
                marginLeft: '-15px',
                borderRadius:
                  '0px var(--border-radius-default) var(--border-radius-default) 0px !important',
              }"
              class="text-none fs font-weight-semibold"
            >
              Cash
            </v-btn>

            <div class="mt-3 fs-small font-weight-medium text-center">
              <v-icon size="var(--size-icon-small)">mdi-history</v-icon>
              Transaction
            </div>
          </div>
        </div>
        <div class="mt-3">
          <div
            class="scroll-list--token"
            :style="{
              height: size.height / 1.5 + 'px',
            }"
          >
            <div class="mt-3">
              <v-sheet
                v-if="!chain._id"
                color="var(--color-bg-card)"
                class="border-radius--default"
                :style="{
                  cursor: 'pointer',
                }"
                @click="dialogChain = true"
              >
                <div class="pa-3">
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      class="font-weight-regular fs-small text-o-8"
                    >
                      <div>{{ lang["TEXT_54"] }}</div>
                    </v-col>
                    <v-col cols="10" class="font-weight-bold">
                      <div class="mt-3">{{ lang["TEXT_55"] }}</div>
                    </v-col>
                    <v-col cols="2" class="text-o-8 text-end">
                      <v-icon>mdi-swap-horizontal</v-icon>
                    </v-col>
                  </v-row>
                </div>
              </v-sheet>

              <div v-else class="mt-3">
                <v-sheet
                  color="var(--color-bg-card)"
                  class="border-radius--default"
                >
                  <div class="pa-3">
                    <v-row
                      no-gutters
                      class="cursor-pointer"
                      @click="dialogChain = true"
                    >
                      <v-col cols="12" class="font-weight-bold text-o-8">
                        <div>{{ lang["TEXT_54"] }}</div>
                      </v-col>
                      <v-col cols="10">
                        <div class="mt-2 font-weight-bold d-flex align-center">
                          <v-img
                            contain
                            max-width="var(--size-icon-x-large)"
                            max-height="var(--size-icon-x-large)"
                            :src="chain.chain?.image"
                          ></v-img>
                          <span class="ml-3">{{ chain.chain.name }}</span>
                        </div>
                      </v-col>
                      <v-col cols="2" class="text-o-8 text-end mt-3">
                        <v-icon>mdi-swap-horizontal</v-icon>
                      </v-col>
                    </v-row>
                  </div>
                </v-sheet>

                <v-sheet
                  :style="{
                    borderRadius: '20px',
                  }"
                  color="var(--color-bg-card)"
                  class="mt-3"
                >
                  <div class="pa-3">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <div class="font-weight-bold mt-3">
                          {{ lang["TEXT_80"] }}
                        </div>
                      </v-col>
                      <v-col cols="8" class="mt-2">
                        <v-text-field
                          type="number"
                          hide-details=""
                          outlined
                          placeholder="0.00"
                          hide-spin-buttons
                          background-color="white"
                          v-model="amountFrom"
                          dense
                          :style="{
                            borderRadius: '20px !important',
                            width: '95%',
                            backgroundColor: '#FFFFFF !important',
                          }"
                        >
                          <template v-slot:append>
                            <v-btn
                              @click="max()"
                              depressed
                              height="32"
                              :style="{
                                marginTop: '-4px !important',
                                borderRadius: '20px !important',
                              }"
                              class="text-none font-weight-medium font-family--poppins fs"
                            >
                              {{ lang["TEXT_64"] }}
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col
                        cols="4"
                        class="mt-2 d-flex justify-start align-center"
                      >
                        <v-btn
                          depressed
                          x-large
                          class="text-none"
                          color="white"
                          width="100%"
                          :style="{
                            borderRadius: '30px !important',
                            border: '1px solid #eaecf0 !important',
                            height: '40px !important',
                          }"
                          @click="chooseToken('withdraw')"
                        >
                          <v-avatar size="var(--size-icon-x-large)">
                            <v-img contain :src="coin.image"></v-img>
                          </v-avatar>
                          <span class="ml-3 font-weight-bold fs-small">
                            {{ coin.symbol }}
                          </span>
                          <v-icon size="var(--size-icon-large)" class="ml-3"
                            >mdi-chevron-down</v-icon
                          >
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="fs-small">
                        <div class="mt-2">
                          <span class="font-weight-regular">
                            {{ lang["TEXT_24"] }}:
                          </span>
                          <span
                            :style="`color: var(--color-success)`"
                            class="font-weight-bold"
                            >{{ formatPrice(coin?.myAmount) }}
                            {{ coin.symbol }}</span
                          >
                        </div>
                      </v-col>
                      <v-col cols="6" class="text-start mt-3 fs-small">
                        <div v-if="Number(coin.minimumWithdraw) > 0">
                          {{
                            Number(coin.minimumWithdraw) > 0
                              ? `${lang["TEXT_87"]}: ${formatPrice(
                                  Number(coin.minimumWithdraw) * coin.price
                                )} ${coin.symbol}`
                              : ""
                          }}
                        </div>
                      </v-col>
                      <v-col cols="6" class="text-end mt-3 fs-small">
                        <div v-if="Number(coin.maximumWithdraw) > 0">
                          {{
                            Number(coin.maximumWithdraw) > 0
                              ? `${lang["TEXT_105"]}: ${formatPrice(
                                  Number(coin.maximumWithdraw) * coin.price
                                )} ${coin.symbol}`
                              : ""
                          }}
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-sheet>

                <v-sheet
                  color="var(--color-bg-card)"
                  class="mt-3 border-radius--default"
                >
                  <div class="pa-3">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <div class="font-weight-bold">
                          {{ lang["TEXT_98"] }}
                        </div>
                      </v-col>
                      <v-col cols="12" class="mt-2 text-end">
                        <v-text-field
                          hide-details=""
                          outlined
                          :placeholder="lang['TEXT_101']"
                          v-model="address"
                          dense
                          :style="{
                            borderRadius: '20px !important',
                            backgroundColor: '#FFFFFF !important',
                          }"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-sheet>

                <div class="mt-4 text--opacity_08 fs">
                  <v-row no-gutters>
                    <v-col cols="4" class="font-weight-regular">
                      {{ lang["TEXT_102"] }}:
                    </v-col>
                    <v-col cols="8" class="text-end">
                      {{
                        chain.chain.feeUSD * coin.price > 0
                          ? formatPrice(chain.chain.feeUSD * coin.price) +
                            " " +
                            coin.symbol
                          : lang["TEXT_85"]
                      }}
                    </v-col>

                    <v-col cols="4" class="font-weight-regular">
                      {{ lang["TEXT_106"] }}:
                    </v-col>
                    <v-col cols="8" class="text-end mt-1">
                      {{
                        amountFrom
                          ? (Number(amountFrom) - chain.chain.feeUSD <= 0
                              ? 0
                              : formatPrice(
                                  Number(amountFrom) - chain.chain.feeUSD
                                )) * coin.price
                          : 0
                      }}
                      {{ coin.symbol }}
                    </v-col>
                  </v-row>
                </div>

                <v-btn
                  block
                  depressed
                  class="mt-3 text-none font-size-large"
                  color="var(--color-wl-sub)"
                  :style="{ color: 'white', borderRadius: '15px' }"
                  x-large
                  :disabled="disabled().active || isLoading"
                  @click="withdraw()"
                  >{{ isLoading ? lang["TEXT_73"] : lang["TEXT_66"] }}</v-btn
                >

                <div class="mt-3 error--text text-center">
                  {{ disabled().message }}
                </div>
              </div>

              <div
                class="d-flex justify-center text-center"
                v-if="isLoadingDeposit"
              >
                <div>
                  <v-progress-circular
                    indeterminate
                    color="var(--color-main)"
                  ></v-progress-circular>
                  <div>{{ lang["TEXT_59"] }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-sheet>

    <v-bottom-sheet
      v-model="dialogChain"
      persistent
      v-if="typeMap == 'wallet' || typeMap == 'game'"
    >
      <v-sheet height="120%" color="transparent">
        <center>
          <v-sheet max-width="450" color="transparent">
            <ChooseChainViewComponent
              :coin="coin"
              :formatPrice="formatPrice"
              :chain="chain"
              :chooseChain="chooseChain"
              :coinChains="coinChains"
              :dialogChain="dialogChain"
              :lang="lang"
              :isLoadingCoinChain="isLoadingCoinChain"
              :typeMap="typeMap"
              :closeChain="closeChain"
            />
          </v-sheet>
        </center>
      </v-sheet>
    </v-bottom-sheet>

    <v-overlay
      v-else
      :value="dialogChain"
      class="d-flex justify-center align-center text-center"
      :style="{
        zIndex: '1000',
      }"
    >
      <center>
        <ChooseChainViewComponent
          :coin="coin"
          :formatPrice="formatPrice"
          :chain="chain"
          :chooseChain="chooseChain"
          :coinChains="coinChains"
          :dialogChain="dialogChain"
          :lang="lang"
          :isLoadingCoinChain="isLoadingCoinChain"
          :typeMap="typeMap"
          :closeChain="closeChain"
        />
      </center>
    </v-overlay>
  </v-sheet>
</template>

<script lang="ts">
import Vue from "vue";
import ChooseChainViewComponent from "@/components/view/ChooseChain.vue";

import { WalletApi } from "@/api/WALLET";
const walletApi = new WalletApi();

export default Vue.extend({
  components: {
    ChooseChainViewComponent,
  },
  props: [
    "chooseToken",
    "lang",
    "coin",
    "coinChains",
    "formatPrice",
    "size",
    "isLoadingCoinChain",
    "close",
    "refreshTokenAction",
    "notificationCall",
    "updateBalance",
    "typeMap",
    "typeMapPage",
    "statusCode",
    "getHashTelegram",
  ],
  data: () => ({
    chain: {} as any,
    dialogChain: true,
    amountFrom: "" as any,
    address: "",
    isLoadingDeposit: false,
    isLoading: false,
  }),
  methods: {
    closeChain() {
      this.dialogChain = !this.dialogChain;
    },
    async withdraw() {
      this.isLoading = true;
      let call: any;

      if (this.typeMapPage == "telegram") {
        call = await walletApi.withdrawTelegram(
          this.coin._id,
          this.amountFrom,
          this.address,
          this.chain._id,
          this.getHashTelegram()
        );
      } else {
        const accessToken = await this.refreshTokenAction();
        call = await walletApi.withdraw(
          this.coin._id,
          this.amountFrom,
          this.address,
          this.chain._id,
          accessToken
        );
      }

      if (call.success) {
        this.notificationCall(
          this.lang[call.message]
            ?.replaceAll("_symbol", this.coin.symbol)
            .replaceAll("_amount", this.formatPrice(call.data.amountMain))
            .replaceAll(
              "_fee",
              call.data.feeWithdraw > 0
                ? this.formatPrice(call.data.feeWithdraw)
                : this.lang["TEXT_85"]
            )
        );

        this.updateBalance(this.coin.symbol, this.amountFrom, "-");
        this.address = "";
        this.amountFrom = "";
        this.isLoading = false;
      } else {
        this.notificationCall(this.statusCode[call.message] ?? call.message);
        this.isLoading = false;
      }
    },
    async chooseChain(chainCoin: any) {
      this.chain = chainCoin;
      this.dialogChain = false;
    },
    max() {
      this.amountFrom = this.coin.myAmount;
    },
    disabled() {
      if (this.amountFrom <= 0)
        return {
          active: true,
          message: this.lang["TEXT_108"],
        };
      if (this.amountFrom > this.coin.myAmount)
        return {
          active: true,
          message: this.lang["TEXT_107"],
        };
      if (Number(this.coin.minimumWithdraw) * this.coin.price > this.amountFrom)
        return {
          active: true,
          message: this.lang["TEXT_109"]
            .replace(
              "_amount",
              this.formatPrice(
                Number(this.coin.minimumWithdraw) * this.coin.price
              )
            )
            .replace("_symbol", this.coin.symbol),
        };
      if (Number(this.coin.maximumWithdraw) * this.coin.price < this.amountFrom)
        return {
          active: true,
          message: this.lang["TEXT_110"]
            .replace(
              "_amount",
              this.formatPrice(
                Number(this.coin.maximumWithdraw) * this.coin.price
              )
            )
            .replace("_symbol", this.coin.symbol),
        };
      if (!this.address)
        return {
          active: true,
          message: this.lang["TEXT_111"],
        };
      if (this.amountFrom - this.chain.chain.feeUSD * this.coin.price <= 0)
        return {
          active: true,
          message: this.lang["TEXT_112"],
        };
      return {
        active: false,
      };
    },
  },
});
</script>

