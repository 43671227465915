<template>
  <v-sheet
    :width="!typeMap ? '100%' : size.onSize <= 450 ? size.onSize : 450"
    class="text-start"
    style="background-color: var(--color-bg-card)"
  >
    <v-sheet>
      <v-toolbar
        height="40"
        color="var(--color-bg-card)"
        class="none-boxShadow"
      >
        <v-btn
          color="white"
          fab
          @click="close"
          depressed
          width="var(--size-icon-x-large)"
          height="var(--size-icon-x-large)"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title
          class="fs-x-large font-weight-bold d-flex justify-center"
          :style="{
            width: '90%',
          }"
        >
          {{ lang["TEXT_15"] }}
        </v-toolbar-title>
      </v-toolbar>
    </v-sheet>

    <v-sheet color="white" style="border-radius: 30px 30px 0px 0px">
      <div class="pa-3">
        <div class="d-flex justify-center">
          <div>
            <v-btn
              depressed
              color="var(--color-blue-v2)"
              dark
              width="150"
              :style="{
                zIndex: '2',
              }"
              class="text-none border-radius--default fs font-weight-semibold"
            >
              Crypto
            </v-btn>
            <v-btn
              depressed
              color="#F1F3F8"
              width="150"
              disabled
              :style="{
                marginLeft: '-15px',
                borderRadius:
                  '0px var(--border-radius-default) var(--border-radius-default) 0px !important',
              }"
              class="text-none fs font-weight-semibold"
            >
              Cash
            </v-btn>

            <div class="mt-3 fs-small font-weight-medium text-center">
              <v-icon size="var(--size-icon-small)">mdi-history</v-icon>
              Transaction
            </div>
          </div>
        </div>
        <v-text-field
          :placeholder="lang['TEXT_296']"
          outlined
          dense
          v-model="search"
          hide-details=""
          class="mt-3"
        >
          <template v-slot:append>
            <v-fade-transition leave-absolute>
              <div class="pa-1">
                <img
                  width="15"
                  height="15"
                  loading="lazy"
                  src="../../assets/wallet/search.png"
                />
              </div>
            </v-fade-transition>
          </template>
        </v-text-field>

        <div class="mt-3">
          <div
            class="scroll-list--token"
            :style="{
              height: size.height / 1.5 + 'px',
            }"
          >
            <div v-if="filterBy(coins_, search).length === 0">
              <div>{{ lang["TEXT_291"] }}</div>
            </div>
            <v-row no-gutters>
              <v-col
                cols="12"
                v-for="item in filterBy(coins_, search)"
                :key="item._id"
                @click="changeLinkChooseToken(item, typeChoose)"
                class="mt-1"
              >
                <v-card
                  link
                  class="no-box-shadow hover-choose"
                  :style="{
                    borderRadius: '15px',
                  }"
                >
                  <div class="pa-2">
                    <ItemCoinComponent
                      :image="item.image"
                      :name="item.name"
                      :symbol="item.symbol"
                      :price="item.price"
                      :CHANGEPCT24HOUR="item.CHANGEPCT24HOUR"
                      :formatPrice="formatPrice"
                      :myAmount="item.myAmount"
                      :lang="lang"
                      type="choose"
                    />
                  </div>
                </v-card>
              </v-col>
            </v-row>

            <v-sheet height="100"></v-sheet>
          </div>
        </div>
      </div>
    </v-sheet>
  </v-sheet>
</template>

<script lang="ts">
import Vue from "vue";
import ItemCoinComponent from "@/components/wallet/item/ItemCoin.vue";
import Vue2Filters from "vue2-filters";

export default Vue.extend({
  props: [
    "lang",
    "size",
    "coins",
    "formatPrice",
    "isLoading",
    "close",
    "changeLinkChooseToken",
    "typeChoose",
    "typeMap"
  ],
  mixins: [Vue2Filters.mixin],
  data: () => ({
    search: "",
    coins_: [] as any,
  }),
  created() {
    this.coins_ = this.coins;
  },
  components: {
    ItemCoinComponent,
  },
});
</script>

