<template>
  <v-app v-resize="onResize">
    <!-- <v-bottom-sheet persistent v-model="isLoading">
        <LoadingComponent :lang="lang" />
      </v-bottom-sheet> -->
    <router-view
      :notificationCall="notificationCall"
      :accessTokenSave="accessTokenSave"
      :getProfile="getProfile"
      :lang="lang"
      :user="user"
      :pushLink="pushLink"
      :formatPrice="formatPrice"
      :clickLogin="clickLogin"
      :pushLinkPath="pushLinkPath"
      :refreshTokenAction="refreshTokenAction"
      :socket="socket"
      :size="size"
      :TOKEN="TOKEN"
      :loginDemo="loginDemo"
      :formatDate="formatDate"
      :copy="copy"
      :statusCode="statusCode"
      :updateUser="updateUser"
      :href="href"
      :changeLang="changeLang"
      :logOut="logOut"
    />

    <v-dialog v-model="snackbar" max-width="350px">
      <v-card style="border-radius: 10px">
        <v-card-title>
          <span class="text-h5">{{ lang["TEXT_45"] }}</span>
        </v-card-title>
        <v-card-text>
          {{ text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="var(--color-main)"
            @click="snackbar = false"
            class="text-none"
          >
            {{ lang["TEXT_47"] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
  
  <script>
import Vue from "vue";

import vi from "@/lang/vi";
import en from "@/lang/en";
import { AuthApi } from "@/api/AUTH";
import moment from "moment";
const authApi = new AuthApi();

import LoadingComponent from "@/components/Loading.vue";
// import { io } from "socket.io-client";

export default Vue.extend({
  data: () => ({
    TOKEN: "GOF",
    accessToken: "",
    lang: vi,
    snackbar: false,
    text: "",
    isLoading: true,
    user: null,
    dialogLogin: false,
    socket: null,
    windowSize: null,
    size: {
      onSize: 0,
      height: 0,
      xs: false,
      sm: false,
      md: false,
      lg: false,
      xl: false,
    },
    statusCode: {},
  }),
  async created() {
    await this.loadStatusCode();
    const lang = localStorage.getItem("lang");
    if (lang == "vn") {
      this.lang = vi;
    } else {
      this.lang = en;
    }
    setTimeout(async () => {
      this.isLoading = false;

      this.isLoading = true;
      const isAccessTokenExpired = await authApi.isAccessTokenExpired(
        this.accessTokenz
      );
      if (isAccessTokenExpired) {
        const refreshToken = await authApi.refreshToken();
        if (refreshToken.success) {
          const accessToken = refreshToken.data.accessToken;
          this.accessToken = accessToken;
          this.getProfile(accessToken);
        } else {
          if (this.$route.name != "layout" || this.$route.name != "nft") {
            // setTimeout(() => {
            //   if (this.$route.name !== "login") {
            //     window.location.href = "/login";
            //     return;
            //   }
            // }, 500);
          }
        }
      }
      this.isLoading = false;
    }, 100);
  },
  methods: {
    async logOut(type = 'game') {
      const accessToken = await this.refreshTokenAction();
      if (!accessToken) {
        this.pushLink("home");
        return;
      }
      const call = await authApi.logOut(accessToken);
      if (call.success) {
        localStorage.removeItem("tktk");

        if(type == 'home') {
          window.location.href = "/";
        }
        else {
          window.location.href = "/login";
        }
      }
    },
    changeLang(lang) {
      if (lang == "vi") {
        localStorage.setItem('lang', 'vi')
        this.lang = vi;
      } else {
        localStorage.setItem('lang', 'en')
        this.lang = en;
      }
    },
    async loadStatusCode() {
      const call = await authApi.getStatusCode();
      if (call.success) {
        this.statusCode = call.data;
      }
    },
    async copy(content) {
      try {
        await navigator.clipboard.writeText(content);
      } catch (error) {
        const textArea = document.createElement("textarea");
        textArea.value = content;
        document.body.appendChild(textArea);
        textArea.select();
        textArea.setSelectionRange(0, 99999); // For mobile devices
        document.execCommand("copy");
        document.body.removeChild(textArea);
      }
    },
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY HH:MM:SS");
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      this.resetOnResize();
      this.size.onSize = this.windowSize.x;
      this.size.height = this.windowSize.y;
      if (this.windowSize.x < 600) {
        this.size.xs = true;
      } else if (this.windowSize.x < 960) {
        this.size.sm = true;
      } else if (this.windowSize.x < 1264) {
        this.size.md = true;
      } else if (this.windowSize.x < 2104) {
        this.size.lg = true;
      } else {
        this.size.xl = true;
      }
    },
    resetOnResize() {
      this.size = {
        onSize: 0,
        xs: false,
        sm: false,
        md: false,
        lg: false,
        xl: false,
      };
    },
    async refreshTokenAction() {
      const isAccessTokenExpired = await authApi.isAccessTokenExpired(
        this.accessToken
      );
      if (isAccessTokenExpired) {
        const refreshToken = await authApi.refreshToken();
        if (refreshToken.success) {
          const accessToken = refreshToken.data.accessToken;
          this.accessToken = accessToken;
          return accessToken;
        } else {
          return this.accessToken;
        }
      } else {
        return this.accessToken;
      }
    },
    loginDemo() {
      const token =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NmY0MTRiYzZkMGFiZDBjYTg2YjVlOWMiLCJpYXQiOjE3MjcyNzIxMjQsImV4cCI6MTcyNzg3NjkyNH0.jDiHUYxPz0Hg1FcVdFPuVWbaWwA5p_a78yEPWs-o4Lw";
      localStorage.setItem("tktk", token);
      window.location.href = "/";
    },
    clickLogin(value) {
      this.dialogLogin = value;
    },
    formatPrice(price) {
      const formattedPrice = new Intl.NumberFormat("en-US", {
        currency: "USD",
        minimumFractionDigits: 8,
        maximumFractionDigits: 8,
      }).format(price);

      return formattedPrice.toString().replace(/\.?0+$/, "");
    },
    async getProfile(accessToken) {
      const call = await authApi.getProfile(accessToken);
      if (call.success) {
        this.user = call.data;
      } else {
      }
    },
    updateUser(key, value) {
      this.user[key] = value;
    },
    notificationCall(text) {
      this.snackbar = true;
      this.text = text;
    },
    accessTokenSave(accessToken) {
      authApi.isAccessTokenExpired(accessToken);
      this.accessToken = accessToken;
    },
    pushLink(name) {
      window.scrollTo(0, 0);
      if (this.$route.name != name) {
        this.$router.push({
          name,
        });
      }
    },
    href(link) {
      window.location.href = link;
    },
    pushLinkPath(path) {
      this.$router.push({
        path,
      });
    },
  },
  components: { LoadingComponent },
});
</script>
  

<style lang="scss">
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

.cursor-pointer {
  cursor: pointer;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  width: 1px;
}

.card-main-v1 {
  border-radius: 30px !important;
}

.v-dialog:not(.v-dialog--fullscreen) {
    max-height: 90%;
    border-radius: 20px;
}

.card-main-v2 {
  border-radius: 15px 15px 5px 5px;
}

.border-radius--s-default {
  border-radius: 15px !important;
}

.button-29:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d64529 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-29:active {
  box-shadow: #d64529 0 3px 7px inset;
  transform: translateY(2px);
}
.scroll-list--token {
  overflow: scroll;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

:root {
  --font-size-page: 12px;
  --border-radius-default: 20px;
  --border-radius-large: 25px;
  --border-radius-mixed: 10px;
  --border-radius-small: 5px;
  --color-black: #232531;
  --size-avatar: 31.7px;
  --size-icon-small: 12px;
  --size-icon: 16px;
  --size-icon-large: 20px;
  --size-icon-x-large: 25px;
  --size-icon-xx-large: 35px;
  --color-blue: #071b60;
  --color-blue-v2: #0057ff;
  --font-size-x-large: 16px;
  --font-size-large: 14px;
  --font-size-small: 10px;
  --color-bg-card: #f7f9fb;
  --color-gray-v1: #979797;
  --color-gray-v2: #848484;
  --color-gray-v3: #999999;
  --color-black-1: #3c404e;
  --color-black1: #3c404e;
  --color-main: rgba(172, 255, 0, 1);
  --color-button: #2b3139 !important;
  --color-line: #dbe5f1 !important;
  --color-bg2: #1e2329 !important;
  --radius-xl: 16px;
  --color-wl-sub: #0057ff;
  --color-gray-x: #efeef2;
  --color-wl-main: #071b60;
  --color-success: #11cabe;
  --color-success2: #1c732c;
  --color-orange: #fd8f01;
  --color-red: #ff0000;
  --color-black-o9: #444d57;
  --color-black-o8: #232531;
  --color-yellow-01: #ffcc00;
  --font-size-content: 10px
  --font-size-page: 12px 
}

.text-color-black-1 {
  color: var(--color-black-1) !important;
}

.text-o-9 {
  color: var(--color-gray-v1) !important;
}

.text-o-8 {
  color: var(--color-gray-v2) !important;
}

.text-o-7 {
  color: var(--color-gray-v3) !important;
}

.border {
  border: 1px solid #eaecf0 !important;
}

.v-main {
  font-family: "Manrope", sans-serif !important;
  color: var(--color-black) !important;
}



.fs-large {
  font-size: var(--font-size-large) !important;
}

.fs {
  font-size: 12px !important;
}

.fs-x-large {
  font-size: var(--font-size-x-large) !important;
}

.border-radius--default {
  border-radius: var(--border-radius-default) !important;
}

.gift-box {
  display: inline-block;
  display: inline-block;
  animation: shake 0.5s ease-in-out infinite, zoomFade 2s ease-in-out forwards 3s;
  /* Bắt đầu với hiệu ứng ánh sáng nhẹ */
}

/* Định nghĩa hiệu ứng rung */
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

/* Hiệu ứng phóng to và mờ dần */
@keyframes zoomFade {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
    box-shadow: 0 0 50px rgba(255, 255, 0, 0);
  }
}

.border-radius-large {
  border-radius: var(--border-radius-large) !important;
}

.full-img .v-image__image--cover {
  background-size: 100% 100% !important;
}

.border-radius--small {
  border-radius: var(--border-radius-small) !important;
}

.border-radius--mixed {
  border-radius: var(--border-radius-mixed) !important;
}

.border-weight-extrabold {
  font-weight: 800 !important;
}

.font-family--plus-jakarta-sans {
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

.font-family--poppins {
  font-family: "Poppins", sans-serif !important;
}

h1 {
  font-size: 32px !important;
}

.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0px 5px 0px 15px !important;
}

.v-text-field--outlined fieldset {
  border: "1px solid #eaecf0 !important";
  box-shadow: none !important;
}

.v-btn {
  border: "1px solid #eaecf0 !important";
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(
    .v-input--has-state
  )
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #eaecf0 !important;
}

.v-text-field {
  font-size: 10px !important;
}

.theme--light.v-tabs-items {
  background-color: transparent !important;
}

.v-tab {
  padding: 0px 5px !important;
}

.text-black {
  color: #232531 !important;
}

.card-main {
  background: transparent !important;
  border: 1px solid var(--color-line) !important;
  border-radius: 10px !important;
}

.card-white--top {
  position: relative;

  $border: 1px;
  color: black;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.8) 100%
  );
  background-clip: padding-box;
  border: solid $border transparent;
  border-radius: 10px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    margin: 0;
    border-radius: inherit;
    border: 1px solid white;
  }
}

.card-green {
  background: linear-gradient(
    to bottom,
    rgba(166, 230, 72, 1) 0%,
    rgba(71, 145, 40, 1) 100%
  );
}

.floating-box {
  display: inline-block;
  animation: float 3s ease-in-out infinite;
}

/* Hiệu ứng đưa lên đưa xuống */
@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Di chuyển lên 20px */
  }
  100% {
    transform: translateY(0); /* Trở lại vị trí ban đầu */
  }
}

.v-text-field {
  border-radius: 30px !important;
}

.none-boxShadow {
  box-shadow: none !important;
}

.v-menu__content {
  box-shadow: none !important;
}

.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.v-btn {
  border-radius: 15px !important;
}

.footer-menu-fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.no-wrap {
  white-space: nowrap;
}

.v-dialog:not(.v-dialog--fullscreen) {
  box-shadow: none;
}

.navbar {
  overflow: hidden;
  background-color: #333;
  position: fixed;
  top: 0;
  width: 100%;
}

.border {
  border: 1px solid #eaecf0 !important;
}

.bg-game {
  border-radius: 15px;
  background: linear-gradient(
    to bottom,
    rgba(250, 145, 2, 1) 0%,
    rgba(253, 239, 42, 1) 100%
  ) !important;
}

.card-gray {
  background-color: #eaecf0;
}
</style>
  