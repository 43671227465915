import Vue from 'vue'
import { register } from 'swiper/element/bundle';

// import App from './App.vue'
// import App from './AppTelegram.vue'
// import router from './router'

import App from './AppHome.vue'
import router from './router/indexHome'

// import App from './AppWallet.vue'
// import router from './router/indexWallet'
















import vuetify from './plugins/vuetify'

import VueAnimXYZ from '@animxyz/vue'
import '@animxyz/core' // Import css here if you haven't elsewhere
import Vue2Filters from 'vue2-filters'
register();
Vue.config.productionTip = false
Vue.use(VueAnimXYZ)
Vue.use(Vue2Filters)
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
