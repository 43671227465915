export default {
    lang: "vi",
    LOGIN_200: "Đăng nhập thành công",
    SWAP_OK_200: "Chuyển đổi _amountFrom _symbolFrom Qua _amountTo _symbolTo thành công",
    TRANSFERS_OK_200: "Chuyển thành công _amount _symbol đến tài khoản _email thành công. (Phí: _fee _symbol)",
    WITHDRAW_OK_200: "Rút _amount _symbol thành công, vui lòng chờ chúng tôi xác nhận. (Phí: _fee _symbol)",

    UNAUTHORIZED_400: "Không được phép",
    ERROR_MINIMUM_SWAP_400: "Vui lòng chuyển đổi tối thiểu _maximumswap _symbol",
    ERROR_BALANCE_NO_400: "Không đủ _symbol để giao dịch.",
    ERROR_AMOUNT_400: "Vui lòng nhập số lượng",
    ERROR_DES_400: "Ghi chú không hợp lệ",
    ERROR_MINIMUM_400: "Vui lòng nhập số tiền tối thiểu.",
    ERROR_MAXIMUM_400: "Vui lòng nhập số tiền tối đa.",
    ERROR_SWAP_SAME_400: "Không hoán đổi 2 đồng coin giống nhau",
    ERROR_NO_SWAP_400: "Hiện tại không thể hoán đổi, vui lòng thử lại sau.",
    SESSION_EXPIRED_FE: "Phiên đang nhập đã hết hạn.",
    SERVER_400: "Lỗi kết nối đến máy chủ, vui lòng thử lại",
    ERROR_NETWORK_400: "Có vấn đề liên quan đến mạng Blockchain, vui lòng thử lại sau",
    ERROR_WALLET_400: "Chúng tôi không tìm được ví nạp tiền cho bạn, vui lòng thử lại hoặc liên hệ với hệ thống để được hỗ trợ",
    ERROR_TRANSFERS_400: "Hiện tại không thể chuyển _symbol, vui lòng thử lại sau",
    ERROR_TRANSFERS_EMAIL_SAME_400: "Không gửi tiền cho chính mình",
    ERROR_TRANSFERS_EMAIL_EXIST_400: "Không tìm thấy tài khoản chuyển tiền, vui lòng kiểm tra lại",
    ERROR_WITHDRAW_400: "Hiện tại không thể rút _symbol, vui lòng thử lại sau",
    INVALID_CREDENTIALS_401: "Thông tin xác thực không hợp lệ",
    ERROR_SERVER2_400: "Có vẻ hệ thống gặp vấn đề, vui lòng tải lại trang,",

    NOT_VALID_403: "Không hợp lệ",

    TEXT_1: "Gửi",
    TEXT_2: "Nạp",
    TEXT_3: "Hoán đổi",
    TEXT_4: "Lịch sử",
    TEXT_5: "Số dư ước tính",
    TEXT_6: "Tài sản ví",
    TEXT_7: "Tìm token bạn muốn",

    TEXT_8: "Phân bổ tài sản",
    TEXT_9: "Khả dụng",
    TEXT_10: "Đang rút",
    TEXT_11: "Không khả dụng",
    TEXT_12: "Chờ xác nhận",

    TEXT_13: "Lịch sử",
    TEXT_14: "Tất cả",

    TEXT_15: "Lựa chọn coin",
    TEXT_16: "Tìm kiếm coin",
    TEXT_17: "Chọn mang lưới",
    TEXT_18: "Chọn mạng",
    TEXT_19: "Nạp tiền mã hóa",
    TEXT_20: "Địa chỉ nạp tiền",
    TEXT_21: "Khoản tiền đã nạp gần đây",
    TEXT_22: "Xem thêm",
    TEXT_23: "Danh sách coin",

    TEXT_24: "Số dư",
    TEXT_25: "Bán",
    TEXT_27: "Mua",
    TEXT_26: "Mọi lúc, mọi nơi.",
    TEXT_28: "Tỷ lệ",
    TEXT_29: "Danh sách Token",
    TEXT_30: "Hãy nhập từ khóa",
    TEXT_31: "Email",
    TEXT_32: "Tiếp theo",
    TEXT_33: "Đăng nhập",
    TEXT_34: "hoặc",

    TEXT_35: "Vui lòng nhập email",
    TEXT_36: "Đăng nhập bằng Apple",
    TEXT_37: "Đang bảo trì",
    TEXT_38: "Đăng nhập Demo",

    TEXT_39: "Trang chủ",
    TEXT_40: "Báo cáo",
    TEXT_41: "Ví",
    TEXT_43: "Khám phá",
    TEXT_42: "Cài đặt",
    TEXT_44: "Trở về",

    TEXT_45: "Thông báo",
    TEXT_46: "Rất tiếc hiện tại bạn không thể nạp _symbol, vui lòng quay lại sau.",
    TEXT_47: "Xác nhận",
    TEXT_48: "Có vẻ hệ thống chúng tôi đang gặp sự cố, vui lòng thử lại hoặc báo với admin được được khắc phục, xin lỗi vì bất tiện này",
    TEXT_49: "Chọn mạng lưới",
    TEXT_50: "Khoản tiền nạp tối thiểu",
    TEXT_51: "Xin lưu ý, chỉ các mạng được hỗ trợ trên nền tảng mới hiển thị. Nếu bạn gửi qua mạng khác, tài sản của bạn có thể bị mất.",
    TEXT_52: "Thời gian hoàn thành",
    TEXT_53: "phút",
    TEXT_54: "Mạng lưới",
    TEXT_55: "Trước tiên, vui lòng chọn mạng",
    TEXT_56: "Địa chỉ nạp",
    TEXT_57: "Số tiền nạp tối thiểu",
    TEXT_58: "Chỉ gửi _symbol đến địa chỉ này, việc gửi các loại tiền hoặc Token khác đến địa chỉ này có thể khiến bạn mất tiền và Token mà không thể khôi phục.",
    TEXT_59: "Đang tải...",
    TEXT_60: "Chia sẻ",
    TEXT_61: "Từ",
    TEXT_62: "Bạn bán",
    TEXT_63: "Chọn Token",
    TEXT_64: "Max",
    TEXT_65: "Bạn mua",
    TEXT_66: "Giao dịch",
    TEXT_67: "Giao dịch",
    TEXT_68: "Tỷ giá",
    TEXT_69: "Tỷ giá có thể bị thay đổi khi bạn thực hiện lệnh hoán đổi do sự biến động tỷ giá thị trường",
    TEXT_70: "Token muốn bán",
    TEXT_71: "Token muốn mua",
    TEXT_72: "Chuyển đổi tối thiểu",
    TEXT_73: "Đang hoán đổi...",
    TEXT_74: "Gửi qua mạng lưới tiền mã hóa",
    TEXT_75: "Gửi crypto đến địa chỉ crypto khác qua mạng lưới tiền mã hóa",
    TEXT_76: "Gửi qua email/ID",
    TEXT_77: "Gửi đến người dùng hệ thống. Dễ dàng và nhanh chóng.",
    TEXT_78: "Hiện tại không thể rút _symbol, vui lòng thử lại sau.",
    TEXT_79: "Nhập Email đã sử dụng trong hệ thống.",
    TEXT_80: "Bạn sẽ gửi",
    TEXT_81: "Thêm ghi chú cho người nhận",
    TEXT_82: "Không bắt buộc",
    TEXT_83: "Gửi đến tài khoản hệ thống qua email. Tiền về tài khoản ngay lập tức. Không hỗ trợ hoàn tiền.",
    TEXT_84: "Phí giao dịch",
    TEXT_85: "Miễn phí",
    TEXT_86: "Tài khoản sẽ nhận",
    TEXT_87: "Gửi tối thiểu",
    TEXT_89: "Chuyển tiền",
    TEXT_90: "Hiện tại không thể rút _symbol, vui lòng thử lại sau.",
    TEXT_91: "Xin lưu ý chỉ hỗ trợ gửi Crypto qua các mạng trên, Nếu bạn gửi qua mang không hỗ trợ sẽ khiến tài sản bị mất.",
    TEXT_92: "Phí rút tiền",
    TEXT_93: "Rút tối thiểu",
    TEXT_94: "Vô hạng",
    TEXT_95: "Rút tối đa",
    TEXT_96: "Chọn mạng lưới rút tiền",
    TEXT_97: "Rút",
    TEXT_98: "Địa chỉ ví cần chuyển",
    TEXT_99: "Mạng lưới cần gửi",
    TEXT_100: "Token muốn rút",
    TEXT_101: "Nhập địa chỉ ví cần chuyển",
    TEXT_102: "Phí rút tiền",
    TEXT_103: "Mỗi mạng sẽ có mức phí khác nhau tùy thuộc vào mạng blockchain",
    TEXT_104: "Lưu ý: địa chỉ ví phải thuộc định dạng chung với mạng ví dụ đối với EVM thì đầu là 0x,.. Xin hãy cẩn trọng trước khi gửi giao dịch",
    TEXT_105: "Gửi tối đa",
    TEXT_106: "Địa chỉ ví sẽ nhận",
    TEXT_107: "Không đủ số dư",
    TEXT_108: "Vui lòng nhập số dư",
    TEXT_109: "Nhập tối thiểu _amount _symbol",
    TEXT_110: "Nhập tối đa _amount _symbol",
    TEXT_111: "Vui lòng nhập địa chỉ ví nhận tiền",
    TEXT_112: "Phí rút sẽ trừ trực tiếp vào trong số lượng gửi. Do đó tổng số lượng bạn nhập (TRỪ) phí rút phải > 0",
    TEXT_113: "Vui lòng nhập email",
    TEXT_114: "Mô tả phải bé hơn 50 ký tự",
    TEXT_115: "Lịch sử hoạt động",
    TEXT_116: "Rút tiền",
    TEXT_117: "Đang chờ",
    TEXT_118: "Đã thành công",
    TEXT_119: "Từ chối xử lý",
    TEXT_120: "Chuyển đổi",
    TEXT_121: "Nạp tiền",
    TEXT_122: "Không có dữ liệu được ghi nhận",
    "ERROR_GAME_NOT_HARVESTS_400": "Chưa tới giờ thu hoạch",
    TEXT_123: "Cây sầu riêng",
    TEXT_124: "Cây cam",
    TEXT_125: "Cây bơ",
    TEXT_126: "Loại cây miễn phí thu hoạch các trái cam ngọt liệm.",
    TEXT_127: "Giống cây sầu riêng quý hiếm mang lợi nhuận cao.",
    TEXT_128: "Cây bơ tầm trung, thu được các trái bơ xanh chính.",
    TEXT_129: "Đi thu hoạch",
    TEXT_130: "Shop cây trồng",
    TEXT_131: "Nông trại",
    TEXT_132: "Cửa hàng",
    TEXT_133: "Earn",
    TEXT_134: "Bạn bè",
    TEXT_135: "Ví",
    TEXT_136: "Mua ngay",
    TEXT_137: "Giá",
    TEXT_138: "Xoài",
    TEXT_139: "Sầu riêng",
    TEXT_140: "Bơ",
    TEXT_141: "Cam",
    TEXT_142: "Gửi",
    "ERR_SEND_MISSION_VALID_400": "Nhiệm vụ không hợp lệ, vui lòng tải lại trang.",
    "ERR_SEND_MISSION_NOT_AMOUNT_400": "Số lượng trái cây không đủ để nộp.",
    TEXT_143: "Cây xoài",
    TEXT_144: "Mining game",
    TEXT_145: "Xem thêm",
    TEXT_146: "Tài sản",
    TEXT_147: "Xin chào",
    TEXT_148: "Số lượng _symbol",
    TEXT_149: "Tối thiểu",
    TEXT_150: "Thời gian hoàn thành",
    TEXT_151: "1 - 5 giây",
    TEXT_152: "Phí giao dịch",
    TEXT_153: "Token muốn chuyển",
    TEXT_154: "Kết nối ví",
    TEXT_155: "Bạn sẽ được kết nối ví AZG Pay với tài khoản <b>_email</b>, phương thức sẽ thực hiện duy nhất 1 lần",
    TEXT_156: "Từ chối",
    TEXT_157: "Kết nối",
    TEXT_158: "Đang kết nối",
    TEXT_159: "Mời bạn bè nhận nhiều quà",
    TEXT_160: "Xây dựng một đội ngũ mạnh mẽ và kiếm tiền!",
    TEXT_161: "Đang tải dữ liệu game",
    TEXT_162: "Cần _amount trái _fruit làm từ thiện",
    TEXT_163: "Gửi ngay",
    TEXT_164: "Đang xử lý...",
    TEXT_165: "Nhiệm vụ",
    TEXT_166: "Kho",
    TEXT_167: "Tổng tài sản game",
    TEXT_168: "1 TOKEN giao dịch chính trong game",
    TEXT_169: "Lợi nhuận cây này mang lại rất cao",
    TEXT_170: "Một trái cây miễn phí",
    TEXT_171: "Một loại trái tầm trung cũng mang lợi nhuận rất cao",
    TEXT_172: "Kho trái cây",
    TEXT_173: "Vườn trái cây",
    TEXT_174: "Hiện có",
    "ERR_BUY_EXIST_FRUIT_400": "Hiện tại không thể mua loại trái này.",
    "ERR_CODE1_400": "Bạn đã mua trái này rồi.",
    "ERR_CODE2_400": "Không đủ số dư để mua.",
    TEXT_175: "Top win",
    TEXT_176: "Lucky Weel",
    "ERR_CODE3_400": "Người giới thiệu không tồn tại.",
    TEXT_177: "Số lượng người giới thiệu",
    TEXT_178: "STT",
    TEXT_179: "Tên tài khoản",
    TEXT_180: "Ngày đăng ký",
    TEXT_181: "Tổng hoa hồng giới thiệu",
    TEXT_182: "Link giới thiệu",
    TEXT_183: "Mã",
    TEXT_184: "Mời bạn bè",
    TEXT_185: "Mời bạn bè ngay, nhận quà hấp dẫn",
    TEXT_186: "Thống kê giới thiệu",
    TEXT_187: "Tổng số giới thiệu và phần thưởng",
    TEXT_188: "Lịch sử doanh thu",
    TEXT_189: "Tổng quan về giới thiệu và lợi nhuận từ việc giới thiệu",
    TEXT_190: "Không có lịch sử giới thiệu được tìm thấy",
    TEXT_191: 'Tìm kiếm',
    TEXT_192: 'Kiếm Token ngay',
    TEXT_193: 'Mời bạn bè tham gia sứ mệnh',
    TEXT_194: "Xây dựng một đội mạnh và kiếm tiền!",
    TEXT_195: "Nhiệm vụ cần làm",
    TEXT_196: "Hoàn thành các nhiệm vụ này để nhận thêm Token",
    "FOLLOW_US_ON_YOUTUBE": "Đăng ký kênh Youtube",
    "FOLLOW_US_ON_FACEBOOK": "Đăng ký kênh Facebook",
    "FOLLOW_US_ON_TELEGRAM": "Đăng ký kênh Telegram",
    TEXT_197: "Mời bạn bè",
    TEXT_198: "Hiện tại chưa có nhiệm vụ",
    TEXT_199: "Hoàn thành",
    TEXT_200: "Cộng đồng",
    TEXT_201: "Nhận",
    "ERR_CODE4_400": "Nhiệm vụ không tồn tại.",
    "ERR_CODE5_400": "Vui lòng làm nhiệm vụ để nhận thưởng.",
    "ERR_CODE6_400": "Bạn đã làm nhiệm vụ này.",
    TEXT_202: "Tên",
    TEXT_203: "Tổng số",
    TEXT_204: "Bạn",
    TEXT_205: "Chưa sở hữu",
    TEXT_206: "Bán",
    TEXT_207: "Mua",
    TEXT_208: "Không có Token nào được tìm thấy",
    "ERR_CODE7_400": "Số lượng trái cây không đủ.",
    TEXT_209: "Trái cây",
    TEXT_210: "Số lượng",
    TEXT_211: 'Thêm',
    TEXT_212: 'Giá bán',
    TEXT_213: 'Phí',
    TEXT_214: 'Danh sách bán',
    TEXT_215: 'Hủy',
    TEXT_216: 'Danh sách mua',

    t_orange: 'Vườn cam',
    t_durian: 'Vườn sầu riêng',
    t_butter: 'Vườn bơ',
    t_mango: 'Vườn xoài',
    t_kiwi: 'Vườn kiwi',
    t_lemon: 'Vườn chanh',
    t_grapefruit: 'Vườn bưởi',
    t_guava: 'Vườn ổi',
    t_peach: 'Vườn đào',
    t_tomato: 'Vườn cà chua',


    n_orange: 'Cam',
    n_durian: 'Sầu riêng',
    n_butter: 'Bơ',
    n_mango: 'Xoài',
    n_kiwi: 'Kiwi',
    n_lemon: 'Chanh',
    n_grapefruit: 'Bưởi',
    n_guava: 'Ổi',
    n_peach: 'Đào',
    n_tomato: 'Cà chua',

    TEXT_217: 'Đang tải dữ liệu tài khoản',
    TEXT_218: 'Đang giải nén hình ảnh, âm thanh',
    TEXT_219: 'Đang tải...',
    TEXT_220: 'Marketplace',
    TEXT_221: 'Không tìm thấy vật phẩm nào',
    TEXT_222: 'Bán lên chợ',
    TEXT_223: 'Số lượng muốn bán',
    TEXT_224: 'Giá bán',
    TEXT_225: 'Đăng bán',
    TEXT_226: 'Mua ngay',
    TEXT_227: 'Số lượng muốn mua',
    TEXT_228: 'Mua _amount với giá _price',
    TEXT_229: 'Mua trái cây',
    TEXT_230: 'Tài sản giao dịch',
    TEXT_231: 'Đang tìm kiếm',
    "ERR_CODE8_400": 'Giao dịch này đã kết thúc do đã có người mua hoặc chủ bán đã hủy đơn bán.',
    "ERR_CODE9_400": "Không thể mua giao dịch của chính mình",
    TEXT_232: 'Mua thành công',
    TEXT_233: 'Tổng giá trị',
    TEXT_234: 'Đóng',
    TEXT_235: 'Hoán đổi từ _symbolFrom thành _symbolTo',
    TEXT_236: 'Nạp _symbol thành công',
    TEXT_237: 'Chuyển _symbol đến tài khoản _user (Phí: _fee _symbol)',
    TEXT_238: 'Rút _amount _symbol đến ví _address (Phí: _fee _symbol)',
    TEXT_239: 'Nhận tiền',
    TEXT_240: 'Nhận _symbol từ tài khoản _user',
    TEXT_241: 'Nạp và rút',
    TEXT_242: 'Chuyển tiền và nhận tiền',
    TEXT_243: 'Hiện tại chưa có giao dịch nào',
    TEXT_244: 'Chợ giao dịch',
    TEXT_245: 'Thông báo',
    TEXT_246: 'Số lượng hộp NFT',
    TEXT_247: 'Thanh toán',

    TEXT_248: 'CHƠI',
    TEXT_249: 'NFT',
    TEXT_250: 'MỞ BÁN TRƯỚC',
    TEXT_251: "Bước vào không gian NFT để gây quỹ cho cây trồng",
    TEXT_252: "Hộp quà NFT ngẫu nhiên",
    TEXT_253: "Hộp quà tặng cung cấp cho bạn NFT cây ngẫu nhiên. Cùng nhau trồng cây để tạo ra thu nhập thực sự.",
    TEXT_254: "Ngày",
    TEXT_255: "Giờ",
    TEXT_256: "Phút",
    TEXT_257: "Giây",
    TEXT_258: "Giá mỗi hộp",
    TEXT_259: "Giảm giá",
    TEXT_260: "Mở hộp quà",
    TEXT_261: "KẾT NỐI VÍ",
    TEXT_262: "Các thẻ NFT",
    TEXT_263: "Vui lòng nhập số lượng",
    TEXT_264: "Số lượng không hợp lệ",
    TEXT_265: "Giá 1 box NFT",
    TEXT_266: "Số lượng mua",
    TEXT_267: "Chưa rõ",
    TEXT_268: "Tổng thanh toán",
    TEXT_269: "Bạn đã sẵn sàng chơi chưa?",
    TEXT_270: "SẲN SÀNG",
    TEXT_271: "MUA NGAY",
    TEXT_272: "Mua, Trồng và Thu hoạch",
    TEXT_273: "Thỏa mãn niềm đam mê làm nông của bạn, kiếm tiền không giới hạn, đơn giản",
    TEXT_274: "Các NFT cây trồng",
    TEXT_275: "Không thể tin được chuyện gì sắp xảy ra tiếp theo!",
    TEXT_276: "Mua trái cây",
    TEXT_277: "Mua Box NFT",
    TEXT_278: "Số lượng không hợp lệ",
    TEXT_279: "Số lượng không đủ",
    TEXT_280: "Giá nhập không hợp lệ",
    TEXT_281: "Vui lòng nhập giá bán",
    TEXT_282: "Bạn chưa bán loại trái cây nào",
    TEXT_283: "Số dư không đủ",
    TEXT_284: "Số lượng nhiệm vụ trong 1 ngày",
    TEXT_285: "Tiền TEST",
    TEXT_286: "Mua - Marketplace",
    TEXT_287: "Đăng xuất",
    TEXT_289: "Tham gia",


    'tree_peach': "Cây đào",
    'tree_grapefruit': "Cây bưởi",
    'tree_mango': "Cây xoài",
    'tree_butter': "Cây bơ",
    'tree_kiwi': "Cây Kiwi",
    'tree_durian': "Cây sầu riêng",
    'tree_tomato': "Cây cà chua",
    'tree_guava': "Cây ổi",
    'tree_lemon': "Cây chanh",
    'tree_orange': "Cây cam",

    TEXT_290: "Quản lý Coin / Token",
    TEXT_291: "Không tìm thấy kết quả bạn tìm kiếm",
    TEXT_292: "Xử lý từ 1 phút - 6 giờ",
    TEXT_293: "Đảm bảo mạng khớp với địa chỉ rút tiền và nền tảng gửi tiền hỗ trợ nó, nếu không tài sản của bạn có thể bị mất",
    
    TEXT_294: 'Sàn giao dịch',
    TEXT_295: 'Ví web3',
    TEXT_296: 'Tìm Coin / TOKEN',
    TEXT_297: 'Ngôn ngữ',
}