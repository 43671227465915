<template>
  <v-card
    class="square xyz-in text-start"
    xyz="inherit down"
    color="var(--color-bg-card)"
    :style="{
      boxShadow: 'none !important',
      borderRadius: typeMap == 'wallet' || typeMap == 'game' ? '15px 15px 0px 0px' : '15px',
    }"
    :width="typeMap == 'wallet' || typeMap == 'game' ? '100%' : size.onSize > 450 ? 400 : size.onSize"
  >
    <v-toolbar
      :style="{
        borderRadius: '15px',
      }"
      color="var(--color-bg-card)"
      class="none-boxShadow"
    >
      <v-toolbar-title
        class="fs-x-large font-weight-bold d-flex justify-center text-center text-black"
        :style="{
          width: '100%',
        }"
      >
        {{ typeChooseToken == "from" ? lang["TEXT_70"] : lang["TEXT_71"] }}
      </v-toolbar-title>
    </v-toolbar>

    <v-sheet
      class="pa-2"
      :style="{
        borderRadius:
          typeMap == 'wallet' || typeMap == 'game'
            ? '15px 15px 0px 0px'
            : '15px',
      }"
      color="white"
    >
      <v-text-field
        class="mt-3"
        :placeholder="lang['TEXT_16']"
        outlined
        :style="{
          backgroundColor: '#FFFFFF !important',
        }"
        hide-details=""
        dense
        append-icon="mdi-magnify"
      ></v-text-field>
      <div class="scroll-list--token font-manrope">
        <div
          @click="clickChooseToken(item)"
          v-for="item in coins"
          :key="item._id"
          class="mt-3 cursor-pointer"
        >
          <ItemCoinComponent
            :image="item.image"
            :name="item.name"
            :symbol="item.symbol"
            :price="item.price"
            :CHANGEPCT24HOUR="item.CHANGEPCT24HOUR"
            :formatPrice="formatPrice"
            :myAmount="item.myAmount"
            :lang="lang"
            type="choose"
          />
        </div>
      </div>
    </v-sheet>

    <div class="d-flex justify-center pt-2 pb-2">
      <v-avatar
        tile
        size="35"
        color="var(--color-black1)"
        class="cursor-pointer"
        @click="closeToken()"
        :style="{
          borderRadius: '10px !important',
        }"
      >
        <v-icon color="white">mdi-close</v-icon>
      </v-avatar>
      .
    </div>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import ItemCoinComponent from "@/components/wallet/item/ItemCoin.vue";
export default Vue.extend({
  props: [
    "dialogChooseToken",
    "lang",
    "formatPrice",
    "clickChooseToken",
    "typeChooseToken",
    "coins",
    "size",
    "typeMap",
    "closeToken"
  ],
  components: {
    ItemCoinComponent
  },
  data: () => ({
    //
  }),
});
</script>

