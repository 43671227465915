<template>
  <v-sheet
    color="transparent"
    class="footer-menu-fixed fs-small"
    style="height: 90px; font-size: 12px"
  >
    <center>
      <v-sheet max-width="450" color="transparent">
        <div style="padding: 0px 10px">
          <v-img src="../../assets/game_mining/ifame_button.png">
            <div class="d-flex mt-3" style="padding: 0px 30px">
              <div
                style="width: 25%"
                class="cursor-pointer"
                @click="changeMenu('home')"
              >
                <v-sheet width="100%" color="transparent">
                  <v-avatar tile size="40">
                    <v-img
                      src="../../assets/game_mining/button_home.png"
                    ></v-img>
                  </v-avatar>
                  <div style="margin-top: 3px" class="no-wrap">
                    {{ lang["TEXT_131"] }}
                  </div>
                </v-sheet>
              </div>

              <div
                style="width: 25%"
                class="cursor-pointer"
                @click="changeMenu('earn')"
              >
                <v-sheet width="100%" color="transparent">
                  <v-avatar tile size="40">
                    <v-img
                      src="../../assets/game_mining/button_earn.png"
                    ></v-img>
                  </v-avatar>
                  <div style="margin-top: 3px" class="no-wrap">
                    {{ lang["TEXT_133"] }}
                  </div>
                </v-sheet>
              </div>

              <div
                style="width: 25%"
                class="cursor-pointer"
                @click="changeMenu('shop_nft')"
              >
                <v-sheet width="100%" color="transparent">
                  <v-avatar tile size="40">
                    <v-img
                      src="../../assets/game_mining/button_nft.png"
                    ></v-img>
                  </v-avatar>
                  <div style="margin-top: 3px" class="no-wrap">
                    {{ lang["TEXT_132"] }}
                  </div>
                </v-sheet>
              </div>

              <div
                style="width: 25%"
                class="cursor-pointer"
                @click="changeMenu('friend')"
              >
                <v-sheet width="100%" color="transparent">
                  <v-avatar tile size="40">
                    <v-img
                      src="../../assets/game_mining/button_friend.png"
                    ></v-img>
                  </v-avatar>
                  <div style="margin-top: 3px" class="no-wrap">
                    {{ lang["TEXT_134"] }}
                  </div>
                </v-sheet>
              </div>

              <div
                style="width: 25%"
                class="cursor-pointer"
                @click="changeMenu('wallet')"
              >
                <v-sheet width="100%" color="transparent">
                  <v-avatar tile size="40">
                    <v-img
                      src="../../assets/game_mining/button_wallet.png"
                    ></v-img>
                  </v-avatar>
                  <div style="margin-top: 3px" class="no-wrap">
                    {{ lang["TEXT_135"] }}
                  </div>
                </v-sheet>
              </div>
            </div>
          </v-img>
        </div>

        <!-- <div
          style="width: 25%"
          @click="changeMenu('home')"
          class="cursor-pointer"
        >
          <v-sheet width="100%">
            <v-icon size="25">mdi-home</v-icon>
            <div style="margin-top: 3px" class="no-wrap">
              {{ lang["TEXT_39"] }}
            </div>
          </v-sheet>
        </div>

        <div style="width: 25%" class="cursor-pointer">
          <v-sheet width="100%">
            <v-icon size="25">mdi-clipboard-text-clock</v-icon>
            <div style="margin-top: 3px" class="no-wrap">
              {{ lang["TEXT_40"] }}
            </div>
          </v-sheet>
        </div>

        <div
          style="width: 25%"
          @click="changeMenu('wallet')"
          class="cursor-pointer"
        >
          <v-sheet width="100%">
            <v-icon size="25">mdi-wallet</v-icon>
            <div style="margin-top: 3px" class="no-wrap">
              {{ lang["TEXT_41"] }}
            </div>
          </v-sheet>
        </div>

        <div style="width: 25%" class="cursor-pointer">
          <v-sheet width="100%">
            <v-icon size="25">mdi-cog</v-icon>
            <div style="margin-top: 3px" class="no-wrap">
              {{ lang["TEXT_42"] }}
            </div>
          </v-sheet>
        </div> -->
      </v-sheet>
    </center>
  </v-sheet>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: ["lang", "changeMenu", "typeMap", "pushLink"],
  data: () => ({
    //
  }),
});
</script>

