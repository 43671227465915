<template>
  <v-sheet
    v-if="user"
    :style="`
              ${
                $route.fullPath != '/'
                  ? ''
                  : 'border-radius: 0px 0px 30px 30px;'
              }
              ${$route.name != 'home' ? '' : 'position: absolute;'}
              width: ${size.onSize > 450 ? 450 : size.onSize}px`"
    color="transparent"
    class="fs-small"
  >
    <div style="padding: 10px 5px">
      <v-row no-gutters v-if="type != 'lite'">
        <v-col cols="3">
          <v-menu
            bottom
            origin="center center"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="text-none"
                depressed
                v-bind="attrs"
                v-on="on"
                small
                block
                style="
                  border-radius: 20px 7px 7px 20px !important;
                  padding: 0px;
                "
              >
                <v-avatar size="25" class="ml-2">
                  <v-img
                    v-if="typeMap == 'telegram'"
                    src="@/assets/game_mining/telegram.png"
                  ></v-img>
                  <v-img v-else :src="user.picture"></v-img>
                </v-avatar>
                <span
                  v-if="typeMap == 'telegram'"
                  style="padding: 0px 4px"
                  class="font-weight-semibold fs-small"
                >
                  {{ user.last_name + " " + user.first_name }}
                </span>
                <span
                  v-else
                  style="padding: 0px 4px"
                  class="font-weight-semibold fs-small"
                >
                  {{
                    user.name.length < 5
                      ? user.name.length
                      : user.name.substring(0, 5) + "..."
                  }}
                </span>
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-sheet
              :style="{
                borderRadius: '8px',
                fontSize: '12px',
              }"
            >
              <div class="pa-1 mt-1">
                <v-btn
                  @click="logOut()"
                  block
                  small
                  depressed
                  class="fs-small text-none"
                >
                  {{ lang["TEXT_287"] }}
                </v-btn>

                <v-menu left offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      block
                      small
                      depressed
                      class="fs-small text-none mt-2"
                    >
                      {{ lang["TEXT_297"] }}
                    </v-btn>
                  </template>

                  <v-sheet
                    :style="{
                      borderRadius: '8px',
                      fontSize: '12px',
                    }"
                  >
                    <div class="pa-1">
                      <v-btn
                        :dark="lang.lang == 'vi'"
                        :color="lang.lang == 'vi' ? '#1C732C' : ''"
                        block
                        small
                        depressed
                        @click="changeLang('vi')"
                        class="fs-small text-none"
                      >
                        Vietnamese
                      </v-btn>

                      <v-btn
                        :dark="lang.lang == 'en'"
                        :color="lang.lang == 'en' ? '#1C732C' : ''"
                        block
                        small
                        depressed
                        @click="changeLang('en')"
                        class="fs-small text-none mt-2"
                      >
                        English
                      </v-btn>
                    </div>
                  </v-sheet>
                </v-menu>
              </div>
            </v-sheet>
          </v-menu>
        </v-col>
        <v-col cols="9" class="text-center font-weight-medium">
          <div class="float-end">
            <v-menu
              bottom
              origin="center center"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :ripple="false"
                  retain-focus-on-click
                  v-bind="attrs"
                  v-on="on"
                  depressed
                  small
                  :style="{
                    borderRadius: '7px !important',
                  }"
                  class="fs-small"
                >
                  <v-avatar size="20">
                    <v-img src="@/assets/game_mining/token.png"></v-img>
                  </v-avatar>
                  <span class="ml-1 font-weight-bold">{{
                    formatPrice(tokenMain.myAmount)
                  }}</span>
                  <span>{{ tokenMain.symbol }}</span>
                </v-btn>
              </template>

              <v-sheet
                :style="{
                  borderRadius: '8px',
                  fontSize: '12px',
                }"
              >
                <div class="pa-4 mt-1">
                  <h3 class="font-weight-bold">
                    {{ lang["TEXT_167"] }}
                  </h3>
                  <v-sheet
                    class="mt-1"
                    :style="{
                      border: '1px solid var(--color-gray-x)',
                      borderRadius: '10px',
                    }"
                  >
                    <div class="pa-2">
                      <div class="d-flex justify-start align-center">
                        <div class="d-flex">
                          <v-avatar size="20">
                            <v-img
                              src="../../assets/game_mining/token.png"
                            ></v-img>
                          </v-avatar>
                          <div class="ml-2">
                            <div>
                              <span class="font-weight-bold">
                                {{ formatPrice(tokenMain.myAmount) }}
                              </span>
                              <span class="ml-2">
                                {{ tokenMain.symbol }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>{{ lang["TEXT_168"] }}</div>
                    </div>
                  </v-sheet>

                  <v-sheet
                    v-for="(value, key) in balanceFruit"
                    :key="key"
                    class="mt-1"
                    :style="{
                      border: '1px solid var(--color-gray-x)',
                      borderRadius: '10px',
                    }"
                  >
                    <div class="pa-2">
                      <div class="d-flex justify-start align-center">
                        <div class="d-flex">
                          <v-avatar size="20" tile>
                            <v-img
                              contain
                              :src="returnImageFruit(value[0])"
                            ></v-img>
                          </v-avatar>
                          <div class="ml-2">
                            <div>
                              <span class="font-weight-bold">
                                {{ value[1] }}
                              </span>
                              <span class="ml-2">
                                {{ lang[`n_${String(value[0])}`] }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-sheet>
                </div>
              </v-sheet>
            </v-menu>
            <v-avatar size="30" tile class="ml-2">
              <v-img src="../../assets/game_mining/button_music.png"></v-img>
            </v-avatar>
            <v-avatar size="30" tile class="ml-2">
              <v-img src="../../assets/game_mining/button_question.png"></v-img>
            </v-avatar>
            <v-avatar size="30" tile class="ml-2">
              <v-img
                src="../../assets/game_mining/button_notification.png"
              ></v-img>
            </v-avatar>
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters v-else>
        <v-col cols="3" class="d-flex justify-start">
          <v-avatar
            color="#EAECF0"
            size="25"
            class="cursor-pointer"
            @click="pushLink(typeMap == 'telegram' ? '' : 'home')"
          >
            <v-icon size="15">mdi-chevron-left</v-icon>
          </v-avatar>
        </v-col>
        <v-col
          cols="6"
          class="text-center font-weight-extrabold fs d-flex justify-center align-center"
        >
          <div v-if="$route.name == 'warehouse'">
            {{ lang["TEXT_166"] }}
          </div>

          <div v-else-if="$route.name == 'shop'">
            {{ lang["TEXT_132"] }}
          </div>

          <div v-else-if="$route.name == 'game_mission'">
            {{ lang["TEXT_165"] }}
          </div>

          <div v-else-if="$route.name == 'task'">
            {{ lang["TEXT_133"] }}
          </div>

          <div v-else-if="$route.name == 'friend'">
            {{ lang["TEXT_134"] }}
          </div>

          <div v-else-if="$route.name == 'marketplace'">
            {{ lang["TEXT_244"] }}
          </div>
        </v-col>

        <v-col
          cols="3"
          class="text-center font-weight-medium d-flex justify-end"
        >
          <v-btn
            class="text-none"
            depressed
            small
            style="border-radius: 20px 7px 7px 20px !important; padding: 0px"
          >
            <v-img
              width="20"
              height="20"
              contain
              v-if="typeMap == 'telegram'"
              src="@/assets/game_mining/telegram.png"
            ></v-img>

            <v-avatar v-else size="25" class="ml-2">
              <v-img :src="user.picture"></v-img>
            </v-avatar>
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-sheet>
</template>

<script lang="ts">
import Vue from "vue";
import { AuthApi } from "@/api/AUTH";
const authApi = new AuthApi();

export default Vue.extend({
  props: [
    "size",
    "lang",
    "balanceFruit",
    "tokenMain",
    "formatPrice",
    "user",
    "returnImageFruit",
    "type",
    "pushLink",
    "logOut",
    "changeLang",
    "typeMap",
  ],
  data: () => ({
    //
  }),
  methods: {},
  created() {},
});
</script>

