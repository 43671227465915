<template>
  <v-app class="layout">
    <div class="home-header">
      <div class="section-wrapper">
        <div class="home-header__wrapper">
          <div class="home-header__left">
            <div
              class="home-header__left-item"
              @click="href('https://testnet.okxup.tech/')"
            >
              {{ lang["TEXT_248"] }}
            </div>
            <div @click="pushLink('nft')" class="home-header__left-item">
              {{ lang["TEXT_249"] }}
            </div>
          </div>

          <div class="home-header__right">
            <div
              class="home-button home-button-sm cursor-pointer"
              @click="pushLink('nft')"
              :style="{
                fontSize: size.onSize < 400 ? '12px !important' : '',
              }"
            >
              <span>
                {{ lang["TEXT_289"] }}
              </span>
              <img src="@/assets/layout/images/circle-arrow.png" />
            </div>

            <v-menu transition="slide-y-transition" bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="home-button home-button-sm cursor-pointer"
                  :style="{
                    backgroundColor: 'transparent',
                    border: '1px solid white',
                  }"
                >
                  <img
                    v-if="size.onSize > 400"
                    src="@/assets/layout/images/lang.png"
                  />
                  <span class="white--text"> EN </span>
                  <v-icon color="white">mdi-menu-down</v-icon>
                </div>
              </template>
              <v-list
                dense
                :style="{
                  borderRadius: '10px',
                }"
              >
                <v-list-item
                  @click="changeLang(item.value)"
                  link
                  v-for="item in [
                    {
                      name: 'Vietnamese',
                      value: 'vi',
                    },
                    {
                      name: 'English',
                      value: 'en',
                    },
                  ]"
                  :key="item.name"
                >
                  <v-list-item-title
                    :class="!(lang.lang == item.value) || 'red--text'"
                  >
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </div>

    <div class="theme-section">
      <img
        src="@/assets/layout/images/theme-2.png"
        class="theme-section__image"
        style="width: 75%"
      />
      <img
        src="@/assets/layout/images/go-farm.png"
        class="theme-section__image"
        style="top: 5%; max-width: 242px; width: 20%"
      />
      <img
        src="@/assets/layout/images/theme.png"
        class="theme-section__image"
        style="bottom: -20%; width: 80%"
      />
    </div>

    <div class="ready-section">
      <p>
        {{ lang["TEXT_269"] }}
      </p>

      <div class="home-button cursor-point" @click="pushLink('nft')">
        <span>{{ lang["TEXT_270"] }}</span>
        <img src="@/assets/layout/images/circle-arrow.png" />
      </div>
    </div>

    <div class="section-wrapper" style="position: relative">
      <div id="slider-1" class="keen-slider">
        <div
          class="keen-slider__slide slider-1-item"
          v-for="(item, i) in nfts"
          :key="i"
        >
          <img
            v-if="item.name == 'lemon'"
            src="@/assets/layout/images/fruit-lemon.png"
          />
          <img
            v-else-if="item.name == 'orange'"
            src="@/assets/layout/images/fruit-orange.png"
          />
          <img
            v-else-if="item.name == 'peach'"
            src="@/assets/layout/images/fruit-peach.png"
          />
          <img
            v-else-if="item.name == 'grapefruit'"
            src="@/assets/layout/images/fruit-grapefruit.png"
          />
          <img
            v-else-if="item.name == 'mango'"
            src="@/assets/layout/images/fruit-mango.png"
          />
          <img
            v-else-if="item.name == 'durian'"
            src="@/assets/layout/images/fruit-durian.png"
          />
          <img
            v-else-if="item.name == 'tomato'"
            src="@/assets/layout/images/fruit-tomato.png"
          />
          <img
            v-else-if="item.name == 'kiwi'"
            src="@/assets/layout/images/fruit-kiwi.png"
          />
          <img
            v-else-if="item.name == 'guava'"
            src="@/assets/layout/images/fruit-guava.png"
          />
          <img
            v-else-if="item.name == 'butter'"
            src="@/assets/layout/images/fruit-butter.png"
          />
          <img v-else src="@/assets/layout/images/fruit-mango.png" />
        </div>
      </div>

      <div class="slider-1-arrow-left">
        <img src="@/assets/layout/images/circle-arrow-dark.png" />
      </div>
      <div class="slider-1-arrow-right">
        <img src="@/assets/layout/images/circle-arrow-dark.png" />
      </div>
    </div>

    <div class="presale-section">
      <div class="section-wrapper">
        <div class="presale-wrapper">
          <div class="presale-left">
            <img src="@/assets/layout/images/group-fruit.png" />
          </div>

          <div class="presale-right">
            <img
              src="@/assets/layout/images/theme-2.png"
              class="presale-right__theme"
            />

            <div class="presale-right">
              <div class="presale-right__title">
                <h1>{{ lang["TEXT_250"] }}</h1>
                <p>{{ lang["TEXT_251"] }}</p>
              </div>
              <img src="@/assets/layout/images/go-farm.png" />
              <div class="presale-countdown">
                <div class="presale-countdown__time">
                  <span id="day">00</span>
                  <div class="presale-countdown__text">
                    {{ lang["TEXT_254"] }}
                  </div>
                </div>
                :
                <div class="presale-countdown__time">
                  <span id="hour">00</span>
                  <div class="presale-countdown__text">
                    {{ lang["TEXT_255"] }}
                  </div>
                </div>
                :
                <div class="presale-countdown__time">
                  <span id="minute">00</span>
                  <div class="presale-countdown__text">
                    {{ lang["TEXT_256"] }}
                  </div>
                </div>
                :
                <div class="presale-countdown__time">
                  <span id="second">00</span>
                  <div class="presale-countdown__text">
                    {{ lang["TEXT_257"] }}
                  </div>
                </div>
              </div>
              <div class="home-button cursor-pointer" @click="pushLink('nft')">
                <span>{{ lang["TEXT_271"] }}</span>
                <img src="@/assets/layout/images/circle-arrow.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="buy-section">
      <div class="buy-section__title">{{ lang["TEXT_272"] }}</div>
      <div class="buy-section__text">
        {{ lang["TEXT_273"] }}
      </div>

      <img src="@/assets/layout/images/buy-plant.png" />
    </div>

    <div class="nft-section">
      <div class="nft-section__title">{{ lang["TEXT_274"] }}</div>
      <section class="slider-section">
        <div class="wheel">
          <div
            class="wheel__card"
            v-for="(item, i) in [...nfts, ...nfts]"
            :key="i"
          >
            <img
              v-if="item.name == 'tomato'"
              src="@/assets/layout/images/nft-tomato.png"
            />
            <img
              v-else-if="item.name == 'guava'"
              src="@/assets/layout/images/nft-guava.png"
            />
            <img
              v-else-if="item.name == 'lemon'"
              src="@/assets/layout/images/nft-lemon.png"
            />
            <img
              v-else-if="item.name == 'orange'"
              src="@/assets/layout/images/nft-orange.png"
            />
            <img
              v-else-if="item.name == 'peach'"
              src="@/assets/layout/images/nft-peach.png"
            />
            <img
              v-else-if="item.name == 'grapefruit'"
              src="@/assets/layout/images/nft-grapefruit.png"
            />
            <img
              v-else-if="item.name == 'mango'"
              src="@/assets/layout/images/nft-mango.png"
            />
            <img
              v-else-if="item.name == 'butter'"
              src="@/assets/layout/images/nft-butter.png"
            />
            <img
              v-else-if="item.name == 'durian'"
              src="@/assets/layout/images/nft-durian.png"
            />
            <img
              v-else-if="item.name == 'kiwi'"
              src="@/assets/layout/images/nft-kiwi.png"
            />
            <img v-else src="@/assets/layout/images/nft-lemon.png" />
          </div>
        </div>

        <div class="pagination">
          <div id="prev" class="pagination-button">
            <img src="@/assets/layout/images/circle-arrow-dark.png" />
          </div>
          <div id="next" class="pagination-button pagination-right">
            <img src="@/assets/layout/images/circle-arrow-dark.png" />
          </div>
        </div>
      </section>
    </div>

    <div class="join-section">
      <p>{{ lang["TEXT_275"] }}</p>

      <div class="join-section__background">
        <img src="@/assets/layout/images/join-friendship.png" />
        <h1>JOIN THE FRIENDSHIP</h1>
      </div>
    </div>

    <div class="watch-section">
      <div class="section-wrapper">
        <h1>WATCH A SHOW</h1>

        <div id="slider-2" class="keen-slider">
          <div class="keen-slider__slide">
            <lite-youtube videoid="bTqVqk7FSmY" class="watch-video" />
          </div>
          <div class="keen-slider__slide">
            <lite-youtube videoid="bTqVqk7FSmY" class="watch-video" />
          </div>
          <div class="keen-slider__slide">
            <lite-youtube videoid="bTqVqk7FSmY" class="watch-video" />
          </div>
        </div>

        <div class="home-button watch-button">
          <span>TAKE A LOOK</span>
          <img src="@/assets/layout/images/circle-arrow-dark.png" />
        </div>
      </div>
    </div>

    <div class="home-footer">
      <div class="section-wrapper">
        <div class="home-footer__top-container">
          <img src="@/assets/layout/images/go-farm.png" style="width: 100px" />

          <div class="home-footer__list-button">
            <div class="home-button home-button-sm">
              <span>PLAY</span>
              <img src="@/assets/layout/images/circle-arrow.png" />
            </div>

            <div class="home-button home-button-sm">
              <span>WATCH</span>
              <img src="@/assets/layout/images/circle-arrow.png" />
            </div>
          </div>
        </div>

        <div class="home-footer__bottom-container">
          <img width="80" src="../../assets/layout/images/logo.png" />
          <span>©2024 azgo Limited & others. All rights reserved.</span>
          <div class="home-footer__list-logo">
            <img src="@/assets/layout/images/fb-logo.png" />
            <img src="@/assets/layout/images/ins-logo.png" />
            <img src="@/assets/layout/images/twitter-logo.png" />
            <img src="@/assets/layout/images/youtube-logo.png" />
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import Vue from "vue";

import BottomMenuComponent from "@/components/menu/BottomMenu.vue";
import TotalAndMenuComponent from "@/components/wallet/Common/TotalAndMenu.vue";
import MenuComponent from "@/components/game_mining/Menu.vue";

import ImageOrange from "@/assets/game_mining/orange.png";
import ImageDurian from "@/assets/game_mining/durian.png";
import ImageMango from "@/assets/game_mining/mango.png";
import ImageButter from "@/assets/game_mining/avocado.png";

import LoadingComponent from "@/components/Loading.vue";

import { WalletApi } from "@/api/WALLET";
import { GameMiningApi } from "@/api/GAME_MINING";
const gameMiningApi = new GameMiningApi();
const walletApi = new WalletApi();

export default Vue.extend({
  name: "App",
  components: {
    LoadingComponent,
    BottomMenuComponent,
    TotalAndMenuComponent,
    MenuComponent,
  },
  props: [
    "lang",
    "notificationCall",
    "accessTokenSave",
    "user",
    "pushLink",
    "getProfile",
    "formatPrice",
    "clickLogin",
    "pushLinkPath",
    "refreshTokenAction",
    "socket",
    "size",
    "TOKEN",
    "formatDate",
    "copy",
    "statusCode",
    "href",
    "changeLang",
  ],
  data: () => ({
    isLoadingGame: true,
    notificationEmit: null,
    coins: [],
    isLoadingCoins: false,
    totalUSDT: 0,
    isLoadingWallet: true,
    dialogShopNft: null,
    tokenMain: null,
    dialogConnectWallet: false,
    dialogConnectWalletAzgpay: false,
    isConnect: false,
    balanceFruit: null,
    nfts: [],
  }),
  async mounted() {
    await this.loadNFT();
    await this.slider1Js();
    await this.slider2Js();
    await this.gsap();
  },
  async created() {
    setTimeout(async () => {
      await this.countdown();
    }, 300);
  },
  methods: {
    async loadNFT() {
      const call = await gameMiningApi.getNFT();
      if (call.success) {
        this.nfts = call.data;
      }
    },
    async slider1Js() {
      const slider1 = new KeenSlider("#slider-1", {
        loop: true,
        breakpoints: {
          "(max-width: 1000px)": {
            slides: { perView: 3, spacing: 10 },
          },
          "(max-width: 600px)": {
            slides: { perView: 1.5, spacing: 10 },
            mode: "free",
          },
        },
        slides: {
          perView: 4,
          spacing: 20,
        },
      });

      document
        .querySelector(".slider-1-arrow-left")
        .addEventListener("click", () => {
          slider1.prev();
        });
      document
        .querySelector(".slider-1-arrow-right")
        .addEventListener("click", () => {
          slider1.next();
        });
    },
    async slider2Js() {
      const slider2 = new KeenSlider("#slider-2", {
        breakpoints: {
          "(max-width: 768px)": {
            slides: { perView: 1.5, spacing: 10 },
            mode: "free",
          },
        },
        slides: {
          perView: 3,
          spacing: 15,
        },
      });
    },
    async countdown() {
      // Set the date we're counting down to
      const countDownDate = new Date(
        this.statusCode.expiration_date_open_box
      ).getTime();
      const dayElement = document.getElementById("day");
      const hourElement = document.getElementById("hour");
      const minuteElement = document.getElementById("minute");
      const secondElement = document.getElementById("second");

      // Update the count down every 1 second
      const x = setInterval(function () {
        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24))
          .toString()
          .padStart(2, "0");
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
          .toString()
          .padStart(2, "0");
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
          .toString()
          .padStart(2, "0");
        var seconds = Math.floor((distance % (1000 * 60)) / 1000)
          .toString()
          .padStart(2, "0");

        // Display the result in the element with id="demo"
        dayElement.textContent = days;
        hourElement.textContent = hours;
        minuteElement.textContent = minutes;
        secondElement.textContent = seconds;

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(x);
          dayElement.textContent = "00";
          hourElement.textContent = "00";
          minuteElement.textContent = "00";
          secondElement.textContent = "00";
        }
      }, 1000);
    },
    async gsap() {
      gsap.registerPlugin(Draggable, Flip);

      let wheel = document.querySelector(".wheel"),
        images = gsap.utils.toArray(".wheel__card"),
        cards = gsap.utils.toArray(".wheel__card"),
        pagination = document.querySelector(".pagination"),
        slice = 360 / images.length,
        curRotation = 0,
        currentCard; // keep track of last clicked card so we can put it back

      function setup() {
        let radius = wheel.offsetWidth / 2,
          center = radius,
          DEG2RAD = Math.PI / 180;
        gsap.set(images, {
          x: (i) => center + radius * Math.sin(i * slice * DEG2RAD),
          y: (i) => center - radius * Math.cos(i * slice * DEG2RAD),
          rotation: (i) => i * slice,
          xPercent: -50,
          yPercent: -50,
        });
      }

      setup();

      window.addEventListener("resize", setup);
      const d = Draggable.create(wheel, {
        allowContextMenu: true,
        type: "rotation",
        trigger: wheel,
        inertia: true,
        zIndexBoost: false,
        onDrag: () => (isRotating = true),
        onThrowComplete: () => (isRotating = false),
        snap: {
          rotation: gsap.utils.snap(360 / images.length),
        },
      })[0];

      cards.forEach((card) => card.addEventListener("click", onClickCard));

      pagination.addEventListener("click", closeCurrentCard);

      function closeCurrentCard() {
        if (currentCard) {
          let img = pagination.querySelector("img"),
            state = Flip.getState(img);
          currentCard.appendChild(img);
          Flip.from(state, {
            ease: "power1.inOut",
            scale: true,
          });
          currentCard = null;
        }
      }

      function onClickCard(e) {
        let card = e.target,
          image = card.querySelector("img");
        if (card !== currentCard) {
          closeCurrentCard();
          currentCard = card;
          let state = Flip.getState(image);
          pagination.appendChild(image);
          Flip.from(state, {
            duration: 0.6,
            scale: true,
            ease: "power1.inOut",
          });
        } else {
          closeCurrentCard();
        }
      }

      const rotationBtnComplete = () => {
        d.update();
      };

      const prevBtn = document.getElementById("prev");
      prevBtn.addEventListener("click", () => {
        curRotation += slice;
        gsap.to(wheel, {
          duration: 0.25,
          ease: "power1.inOut",
          rotation: curRotation,
          overwrite: "auto",
          onComplete: rotationBtnComplete,
        });
      });

      const nextBtn = document.getElementById("next");
      nextBtn.addEventListener("click", () => {
        curRotation -= slice;
        gsap.to(wheel, {
          duration: 0.25,
          ease: "power1.inOut",
          rotation: curRotation,
          overwrite: "auto",
          onComplete: rotationBtnComplete,
        });
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.layout {
  // font-family: "Poppins", serif !important;
}

.v-application--wrap {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif !important;
  color: #12293d !important;
}

.section-wrapper {
  width: 80%;
  height: 100%;
  margin: auto;
}

.home-header {
  background: #1c732c;
  height: 58px;
  position: sticky;
  top: 0;
  z-index: 100;
}

.home-header__wrapper {
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-header__left {
  display: flex;
  gap: 16px;
}

.home-header__left-item {
  font-size: 16px;
  font-weight: 600;
  padding: 8px;
  color: white;
  cursor: pointer;
}

.home-header__right {
  display: flex;
  gap: 16px;
}

.theme-section {
  border-radius: 0 0 40px 40px;
  background: #1c732c;
  aspect-ratio: 1216 / 580;
  position: relative;
  margin-bottom: 15%;
}

.theme-section__image {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.ready-section {
  font-size: 36px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
}

.home-button {
  background: #ffcc00;
  color: black;
  font-weight: bold;
  display: inline-flex;
  align-items: center;

  gap: 12px;
  padding: 8px;
  font-size: 24px;
  border-radius: 40px;
  cursor: pointer;
}
.home-button > span {
  margin-left: 16px;
}

.home-button-sm {
  gap: 8px;
  padding: 4px;
  font-size: 14px !important;
  border-radius: 20px;
}
.home-button-sm > span {
  margin-left: 8px;
}
.home-button-sm > img {
  width: 24px;
  height: 24px;
}

#slider-1 {
  margin: 60px 0;
}
#slider-1 .slider-1-item {
  cursor: pointer;
}
#slider-1 .slider-1-item img {
  width: calc(100% - 5px);
}
.slider-1-arrow-left {
  position: absolute;
  top: calc(50% - 20px);
  left: -20px;
  cursor: pointer;
}
.slider-1-arrow-right {
  position: absolute;
  top: calc(50% - 20px);
  transform: rotate(180deg);
  right: -20px;
  cursor: pointer;
}

.presale-section {
  background: #1c732c;
}
.presale-wrapper {
  display: flex;
  gap: 40px;
}
.presale-left {
  width: 40%;
}
.presale-left img {
  width: 100%;
  height: 100%;
}
.presale-right {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 24px;
  padding: 24px;
}
.presale-right__theme {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.presale-right__title h1 {
  color: #ffcc00;
  font-size: 32px;
  font-weight: bold;
  margin: 0;
}
.presale-right__title p {
  color: white;
  font-size: 16px;
  margin: 0;
  margin-top: 10px;
}
.presale-countdown {
  padding: 15px 30px 50px 30px;
  background: rgba(255, 255, 255, 0.356);
  color: white;
  border: 1px solid transparent;
  background: linear-gradient(
    rgba(255, 255, 255, 0.164),
    rgba(255, 255, 255, 0.575)
  );
  background-clip: border-box;
  border-radius: 20px;
  font-size: 48px;
  font-weight: bold;
  display: flex;
  gap: 16px;
}
.presale-countdown__time {
  position: relative;
}
.presale-countdown__text {
  font-size: 20px;
  font-weight: normal;
  position: absolute;
  bottom: -24px;
  left: 50%;
  transform: translateX(-50%);
}

.buy-section {
  padding: 40px 0;
  text-align: center;
}
.buy-section img {
  width: 40%;
}
.buy-section__title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 15px;
}
.buy-section__text {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 40px;
}

.nft-section {
  padding-top: 40px;
}
.nft-section__title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}

.join-section {
  margin-top: 30px;
  text-align: center;
}
.join-section p {
  font-size: 40px;
  font-weight: bold;
}
.join-section img {
  width: 70%;
  position: absolute;
  top: -25%;
  left: 50%;
  transform: translateX(-50%);
}
.join-section__background {
  background: #1c732c;
  color: white;
  aspect-ratio: 1300 / 572;
  position: relative;
  margin-top: 12%;
}
.join-section__background h1 {
  padding-top: 35%;
  font-size: 50px;
}

.watch-section {
  background: #ffcc00;
  padding: 40px 0;
}
.watch-section h1 {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 50px;
}
.watch-button {
  background: white;
  color: #12293d;
  margin-top: 40px;
}
.watch-button img {
  transform: rotate(180deg);
}
.watch-video {
  border-radius: 20px;
}

.home-footer {
  background: #232531;
}
.home-footer__top-container {
  padding: 16px;
  text-align: center;
  border-bottom: 1px solid #34384c;
}
.home-footer__list-button {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 16px 0;
}
.home-footer__bottom-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0 30px 0;
}
.home-footer__bottom-container span {
  color: #34384c;
  font-size: 13px;
}
.home-footer__list-logo {
  display: flex;
  gap: 10px;
}

.slider-section {
  position: relative;
  width: 100%;
  aspect-ratio: 5/2;
  overflow: hidden;
}

.slider-section .wheel {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200vw;
  aspect-ratio: 1/1;
  left: 50%;
  transform: translateX(-50%);
}

.slider-section .wheel__card {
  position: absolute;
  top: 0;
  left: 0;
  width: 8%;
  cursor: pointer;
}

.slider-section img {
  width: 100%;
  border-radius: 20px;
  pointer-events: none;
  z-index: 999;
  cursor: pointer;
  position: absolute;
  will-change: transform;
}

.slider-section .pagination {
  position: absolute;
  bottom: 30%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 24px;
}

.slider-section .pagination-button {
  width: 40px;
  height: 40px;
  cursor: pointer;
  user-select: none;
}

.slider-section .pagination-button img {
  width: 40px;
  height: 40px;
}

.slider-section .pagination-right {
  transform: rotate(180deg);
}

@media screen and (max-width: 1200px) {
  .presale-left {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .section-wrapper {
    width: auto;
    margin: 0 24px;
  }
  .home-header__left-item {
    font-size: 12px;
  }
  .home-button {
    gap: 8px;
    font-size: 16px;
    border-radius: 30px;
  }
  .home-button > span {
    margin-left: 8px;
  }
  .home-button > img {
    width: 30px;
    height: 30px;
  }
  .ready-section {
    font-size: 20px;
  }
  .presale-right {
    padding: 24px 0;
  }
  .presale-countdown {
    padding: 10px 20px 40px 20px;
    font-size: 30px;
    gap: 16px;
  }
  .presale-countdown__text {
    font-size: 14px;
    font-weight: normal;
  }
  .buy-section img {
    width: 60%;
  }
  .buy-section__title {
    font-size: 20px;
  }
  .buy-section__text {
    font-size: 12px;
    padding: 0 30px;
  }
  .nft-section {
    padding: 40px 0;
  }
  .nft-section__title {
    font-size: 20px;
  }
  .join-section p {
    font-size: 20px;
  }
  .join-section__background h1 {
    font-size: 24px;
    padding-top: 32%;
  }
  .watch-section {
    text-align: center;
    padding: 20px 0;
  }
  .watch-section h1 {
    font-size: 20px;
    margin: 0 0 20px 0;
  }
  .watch-button {
    margin-top: 20px;
  }
  .home-footer__top-container {
    border-bottom: none;
    padding: 16px 0 0 0;
  }
  .home-footer__bottom-container {
    flex-direction: column;
    justify-content: center;
    gap: 15px;
  }
  .slider-section .wheel {
    width: 300vw;
  }
  .slider-section .wheel__card {
    width: 8%;
  }
  .slider-section img {
    border-radius: 10px;
  }
  .slider-section .pagination {
    bottom: 10%;
  }
}

@media screen and (max-width: 400px) {
  .slider-section {
    aspect-ratio: 2/1;
  }
  .slider-section .wheel {
    width: 400vw;
  }
  .slider-section .wheel__card {
    width: 8%;
  }
  .slider-section .pagination {
    bottom: 0;
  }
  .slider-section .pagination-button {
    width: 24px;
    height: 24px;
  }
  .slider-section .pagination-button img {
    width: 24px;
    height: 24px;
  }
}
</style>
