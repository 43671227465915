<template>
  <v-sheet style="border: 1px solid #eaecf0; border-radius: 20px">
    <div class="pa-3">
      <v-row>
        <v-col cols="6" class="d-flex justify-start align-center">
          <v-avatar size="20">
            <v-img src="@/assets/wallet/icon_history.png"></v-img>
          </v-avatar>
          <div class="ml-2 fs-large">Transactions</div>
        </v-col>
        <v-col cols="6" class="d-flex justify-end align-center">
          <v-avatar size="25" color="var(--color-wl-sub)">
            <v-icon dark size="15">mdi-chevron-up</v-icon>
          </v-avatar>
          <v-btn
            depressed
            color="var(--color-wl-sub)"
            class="ml-3 text-none fs"
            dark
            style="border-radius: 50px !important"
            small
          >
            All
            <v-avatar size="25" color="var(--color-wl-sub)">
              <v-icon dark size="15">mdi-chevron-right</v-icon>
            </v-avatar>
          </v-btn>
        </v-col>
      </v-row>

      <v-divider v-if="typeMap == 'wallet'" class="mt-3"></v-divider>

      <v-row v-if="typeMap == 'wallet'" class="mt-1" >
        <v-col cols="12">
          <v-row>
            <v-col cols="7">
              <div class="d-flex justify-start align-center">
                <v-avatar size="30" color="#F1F3F8">
                  <v-img
                    max-width="12"
                    contain
                    loading="lazy"
                    src="@/assets/wallet/food.png"
                  ></v-img>
                </v-avatar>
                <div class="ml-2">
                  <div class="font-weight-semibold">Food/Drink</div>
                  <div class="fs-small">Payment</div>
                </div>
              </div>
            </v-col>

            <v-col cols="5" class="d-flex justify-end align-center">
              <span class="font-weight-semibold">$0</span>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-row>
            <v-col cols="7">
              <div class="d-flex justify-start align-center">
                <v-avatar size="30" color="#F1F3F8">
                  <v-img
                    max-width="12"
                    contain
                    loading="lazy"
                    src="@/assets/wallet/shop.png"
                  ></v-img>
                </v-avatar>
                <div class="ml-2">
                  <div class="font-weight-semibold">Shoping</div>
                  <div class="fs-small">Payment</div>
                </div>
              </div>
            </v-col>

            <v-col cols="5" class="d-flex justify-end align-center">
              <span class="font-weight-semibold">$0</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-sheet>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: ["typeMap"],
  data: () => ({
    //
  }),
});
</script>

