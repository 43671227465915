<template>
  <v-sheet
    :width="!typeMap ? '100%' : size.onSize <= 450 ? size.onSize : 450"
    class="text-start"
    style="background-color: var(--color-bg-card)"
  >
    <v-sheet>
      <v-toolbar
        height="40"
        color="var(--color-bg-card)"
        class="none-boxShadow"
      >
        <v-btn
          style="border-radius: 50% !important"
          color="white"
          fab
          depressed
          small
          width="var(--size-icon-x-large)"
          height="var(--size-icon-x-large)"
          @click="chooseToken('deposit')"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title
          class="fs-x-large font-weight-bold d-flex justify-center"
          style="width: 90%"
          >{{ lang["TEXT_2"] }} {{ coin.symbol }}</v-toolbar-title
        >
      </v-toolbar>
    </v-sheet>

    <v-sheet color="white" style="border-radius: 30px 30px 0px 0px">
      <div class="pa-3">
        <div class="d-flex justify-center">
          <div>
            <v-btn
              depressed
              color="var(--color-blue-v2)"
              dark
              width="150"
              :style="{
                zIndex: '2',
              }"
              class="text-none border-radius--default fs font-weight-semibold"
            >
              Crypto
            </v-btn>
            <v-btn
              depressed
              color="#F1F3F8"
              width="150"
              disabled
              :style="{
                marginLeft: '-15px',
                borderRadius:
                  '0px var(--border-radius-default) var(--border-radius-default) 0px !important',
              }"
              class="text-none fs font-weight-semibold"
            >
              Cash
            </v-btn>

            <div class="mt-3 fs-small font-weight-medium text-center">
              <v-icon size="var(--size-icon-small)">mdi-history</v-icon>
              Transaction
            </div>
          </div>
        </div>

        <div class="mt-3">
          <div
            class="scroll-list--token"
            :style="{
              height: size.height / 1.5 + 'px',
            }"
          >
            <v-sheet
              @click="dialogChain = true"
              v-if="!depositView"
              color="var(--color-bg-card)"
              class="border-radius--default"
              :style="{
                cursor: 'pointer',
              }"
            >
              <div class="pa-3">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    class="fs-small font-weight-regular text-o-8"
                  >
                    <div>{{ lang["TEXT_54"] }}</div>
                  </v-col>
                  <v-col cols="10" class="font-weight-bold">
                    <div class="mt-3">{{ lang["TEXT_55"] }}</div>
                  </v-col>
                  <v-col cols="2" class="text-o-8 text-end">
                    <v-icon size="var(--size-icon-large)">
                      mdi-swap-horizontal
                    </v-icon>
                  </v-col>
                </v-row>
              </div>
            </v-sheet>

            <div
              v-else
              class="scroll-list--token"
              :style="{
                height: size.height + 'px',
              }"
            >
              <v-sheet class="d-flex justify-center text-center">
                <div class="pa-3">
                  <div>
                    <center>
                      <qrcode-vue
                        :value="depositView.address"
                        :size="200"
                        level="H"
                        style="border-radius: 10px"
                      >
                      </qrcode-vue>
                    </center>

                    <div class="d-flex justify-center">
                      <v-avatar
                        size="40"
                        style="
                          position: relative;
                          z-index: 100;
                          margin-top: -60%;
                        "
                        class="d-flex justify-center"
                      >
                        <v-img :src="coin?.image"></v-img>
                      </v-avatar>
                    </div>
                  </div>

                  <div class="mt-3 fs-small text-center">
                    <span class="font-weight-regular">
                      {{ lang["TEXT_57"] }}:
                    </span>

                    <span class="font-weight-bold">
                      {{ depositView.minimumDeposit }}
                      {{ depositView.symbolCoin }}
                    </span>
                  </div>
                </div>
              </v-sheet>

              <v-sheet
                class="mt-3 border-radius--default"
                color="var(--color-bg-card)"
              >
                <div class="pa-3">
                  <v-row
                    no-gutters
                    class="cursor-pointer"
                    @click="dialogChain = true"
                  >
                    <v-col
                      cols="12"
                      class="font-weight-regular fs-small text-o-8"
                    >
                      <div>{{ lang["TEXT_54"] }}</div>
                    </v-col>
                    <v-col cols="10" class="mt-3">
                      <div class="d-flex align-center">
                        <v-img
                          contain
                          max-width="var(--size-icon-x-large)"
                          max-height="var(--size-icon-x-large)"
                          :src="depositView?.imageChain"
                        ></v-img>
                        <span class="ml-3 font-weight-bold">
                          {{ depositView.nameChain }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="2" class="text-o-8 text-end mt-3">
                      <v-icon size="var(--size-icon-large)"
                        >mdi-swap-horizontal</v-icon
                      >
                    </v-col>
                  </v-row>

                  <v-divider class="mt-3"></v-divider>

                  <v-row no-gutters class="mt-3">
                    <v-col
                      cols="12"
                      class="font-weight-regular fs-small text-o-8"
                    >
                      <div>{{ lang["TEXT_56"] }}</div>
                    </v-col>
                    <v-col cols="10" class="mt-3">
                      <div
                        class="font-weight-bold"
                        style="word-break: break-all"
                      >
                        {{ depositView.address }}
                      </div>
                    </v-col>
                    <v-col
                      cols="2"
                      class="text-o-8 text-end mt-3 cursor-pointer d-flex justify-end align-center"
                      @click="copyAddress(depositView.address)"
                    >
                      <v-icon v-if="!isCopy" size="var(--size-icon-large)">
                        mdi-content-copy
                      </v-icon>

                      <v-icon
                        v-else
                        size="var(--size-icon-large)"
                        color="var(--color-green1)"
                      >
                        mdi-check-circle
                      </v-icon>
                    </v-col>
                  </v-row>
                </div>
              </v-sheet>
            </div>
          </div>

          <div
            class="d-flex justify-center text-center"
            v-if="isLoadingDeposit"
          >
            <div>
              <v-progress-circular
                indeterminate
                color="var(--color-main)"
              ></v-progress-circular>
              <div>{{ lang["TEXT_59"] }}</div>
            </div>
          </div>
        </div>
      </div>
    </v-sheet>

    <v-bottom-sheet
      v-model="dialogChain"
      persistent
      v-if="typeMap == 'wallet' || typeMap == 'game'"
    >
      <v-sheet height="120%" color="transparent">
        <center>
          <v-sheet max-width="450" color="transparent">
            <ChooseChainViewComponent
              :coin="coin"
              :formatPrice="formatPrice"
              :chain="chain"
              :chooseChain="deposit"
              :coinChains="coinChains"
              :dialogChain="dialogChain"
              :lang="lang"
              :isLoadingCoinChain="isLoadingCoinChain"
              :typeMap="typeMap"
              :closeChain="closeChain"
              method="deposit"
            />
          </v-sheet>
        </center>
      </v-sheet>
    </v-bottom-sheet>

    <v-overlay
      v-else
      :value="dialogChain"
      class="d-flex justify-center align-center text-center"
      :style="{
        zIndex: '1000',
      }"
    >
      <center>
        <ChooseChainViewComponent
          :coin="coin"
          :formatPrice="formatPrice"
          :chain="chain"
          :chooseChain="deposit"
          :coinChains="coinChains"
          :dialogChain="dialogChain"
          :lang="lang"
          :isLoadingCoinChain="isLoadingCoinChain"
          :typeMap="typeMap"
          :closeChain="closeChain"
          method="deposit"
        />
      </center>
    </v-overlay>
  </v-sheet>
</template>

<script lang="ts">
import Vue from "vue";
import ChooseChainViewComponent from "@/components/view/ChooseChain.vue";
import QrcodeVue from "qrcode.vue";
import { WalletApi } from "@/api/WALLET";
const walletApi = new WalletApi();
export default Vue.extend({
  props: [
    "chooseToken",
    "lang",
    "coin",
    "coinChains",
    "formatPrice",
    "size",
    "isLoadingCoinChain",
    "close",
    "refreshTokenAction",
    "notificationCall",
    "updateBalance",
    "copy",
    "typeMap",
    "typeMapPage",
    "statusCode",
    "getHashTelegram",
  ],
  components: {
    QrcodeVue,
    ChooseChainViewComponent,
  },
  data: () => ({
    depositView: null as any,
    dialogChain: true,
    isLoadingDeposit: false,
    chain: {} as any,
    isCopy: false,
    absolute: false,
  }),
  created() {},
  methods: {
    closeChain() {
      this.dialogChain = !this.dialogChain;
    },
    async copyAddress(text: string) {
      this.isCopy = true;
      await this.copy(text);

      setTimeout(() => {
        this.isCopy = false;
      }, 2000);
    },
    async deposit(chainCoin: any) {
      this.isLoadingDeposit = true;
      let call: any
      if (this.typeMapPage == "telegram") {
        call = await walletApi.depositTelegram(chainCoin._id, this.getHashTelegram());
      } else {
        const accessToken = await this.refreshTokenAction();
        call = await walletApi.deposit(chainCoin._id, accessToken);
      }

      if (call.success) {
        this.depositView = call.data;
        this.dialogChain = false;
      } else {
        this.notificationCall(this.lang[call.message]);
      }
      this.isLoadingDeposit = false;
    },
  },
});
</script>

