<template>
  <div class="text-start" style="background-color: var(--color-bg-card)">
    <v-toolbar height="40" color="var(--color-bg-card)" class="none-boxShadow">
      <v-btn
        style="border-radius: 50% !important"
        color="white"
        fab
        depressed
        width="var(--size-icon-x-large)"
        height="var(--size-icon-x-large)"
        @click="close()"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <v-toolbar-title
        class="fs-x-large font-weight-bold d-flex justify-center"
        style="width: 90%"
        >{{ lang["TEXT_290"] }}</v-toolbar-title
      >
    </v-toolbar>

    <v-sheet
      color="white"
      :height="size.height / 2"
      style="border-radius: 30px 30px 0px 0px"
    >
      <div class="pa-3">
        <v-text-field
          placeholder="Tìm Coin / Token"
          outlined
          dense
          v-model="search"
          hide-details=""
        >
          <template v-slot:append>
            <v-fade-transition leave-absolute>
              <div class="pa-1">
                <img
                  width="15"
                  height="15"
                  loading="lazy"
                  src="../../assets/wallet/search.png"
                />
              </div>
            </v-fade-transition>
          </template>
        </v-text-field>
        <div class="d-flex justify-center mt-3">
          <div
            class="scroll-list--token"
            :style="{
              height: size.height - 100 + 'px',
              width: '450px'
            }"
          >
            <div v-if="filterBy(coins_, search).length === 0">
                <div>{{lang["TEXT_291"]}}</div>
            </div>
            <v-row>
              <v-col
                cols="12"
                v-for="item in filterBy(coins_, search)"
                :key="item._id"
              >
                <v-row no-gutters>
                  <v-col cols="4">
                    <div class="d-flex justify-start align-center">
                      <v-avatar size="35" color="#E2E8F0">
                        <v-img loading="lazy" :src="item.image"></v-img>
                      </v-avatar>
                      <div class="ml-2">
                        <div class="font-weight-semibold">{{ item.name }}</div>
                        <div class="fs-small">{{ item.symbol }}</div>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    cols="4"
                    class="d-flex justify-start align-center font-weight-semibold"
                  >
                    <span class="ml-6">${{ formatPrice(item.price) }}</span>
                  </v-col>
                  <v-col cols="4">
                    <div class="float-end">
                      <v-switch
                        :style="{
                          marginTop: '0px',
                        }"
                        hide-details=""
                        dense
                        inset
                        color="#0057FF"
                      ></v-switch>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-sheet height="100"></v-sheet>
          </div>
        </div>
      </div>
    </v-sheet>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Vue2Filters from "vue2-filters";

export default Vue.extend({
  props: ["lang", "size", "coins", "formatPrice", "close"],
  mixins: [Vue2Filters.mixin],
  data: () => ({
    coins_: [] as any,
    search: "",
  }),
  created() {
    this.coins_ = this.coins;
  },
});
</script>

