<template>
  <div>
    <v-sheet color="#071B60" style="border-radius: 0px 0px 20px 20px">
      <div class="pl-3 pr-3 pb-3">
        <v-sheet class="border-radius--default" color="#0057FF" dark>
          <div class="pa-4">
            <v-row class="white--text">
              <v-col cols="8" class="font-family--plus-jakarta-sans">
                <div class="d-flex">
                  <div>{{ lang["TEXT_5"] }}</div>
                  <div class="ml-2">
                    <v-icon size="15">mdi-eye</v-icon>
                  </div>
                </div>
                <h1
                  xyz="fade flip-up front-0"
                  class="d-flex font-weight-bold square xyz-in"
                >
                  {{ formatPrice(totalUSDT) }}
                  <v-btn outlined class="ml-3 mt-3" text small depressed>
                    USD
                    <v-icon size="var(--size-icon)" class="ml-1">
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                </h1>
              </v-col>
              <v-col cols="4">
                <v-img
                  class="float-end"
                  contain
                  max-width="var(--size-icon-large)"
                  src="@/assets/wallet/reset.png"
                ></v-img>
              </v-col>
              <v-col cols="12" class="font-family--poppins">
                <div class="d-flex mt-14 align-center">
                  <v-img
                    max-width="var(--size-icon)"
                    height="var(--size-icon)"
                    src="@/assets/wallet/arrow_up.png"
                  ></v-img>
                  <div class="ml-1 fs-large">$1,816</div>
                  <div class="ml-1 fs-small">Today’s Profit</div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-sheet>
      </div>
    </v-sheet>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: {
    formatPrice: {
      type: Function,
      required: true,
    },
    lang: {
      type: Object,
    },
    changeMap: {
      type: Function,
    },
    pushLinkPath: {
      type: Function,
      required: true,
    },
    totalUSDT: {
      type: Number,
      default: 0,
    },
    clickSend: { type: Function, required: true },
  },
  data: () => ({
    urlLink: "",
  }),
  created() {
    this.urlLink = this.$route.fullPath;
  },
  methods: {
    linkAction(value: string) {
      if (value == "deposit") {
        this.pushLinkPath(`/choose-token/${value}?urlBack=${this.urlLink}`);
      } else if (value == "swap") {
        this.pushLinkPath(`/swap`);
      } else if (value == "send") {
        this.clickSend();
      }
    },
  },
});
</script>
