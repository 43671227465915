<template>
  <v-sheet  class="text-start" style="background-color: var(--color-bg-card)">
    <v-sheet>
      <v-toolbar
        height="40"
        color="var(--color-bg-card)"
        class="none-boxShadow"
      >
        <v-btn
          style="border-radius: 50% !important"
          color="white"
          fab
          @click="close()"
          depressed
          width="var(--size-icon-x-large)"
          height="var(--size-icon-x-large)"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title
          class="fs-x-large font-weight-bold d-flex justify-center"
          style="width: 90%"
        >
          Gift Box Random NFT
        </v-toolbar-title>
      </v-toolbar>
    </v-sheet>

    <v-sheet color="white" style="border-radius: 30px 30px 0px 0px">
      <div class="pa-3">
        <v-sheet color="var(--color-bg-card)" class="border-radius--default">
          <div class="pa-3">
            <v-row no-gutters>
              <v-col cols="12">
                <div class="d-flex justify-start align-center">
                  <v-avatar tile size="45">
                    <v-img
                      contain
                      src="../../assets/layout/images/nft-gift-box.png"
                    ></v-img>
                  </v-avatar>
                  <div class="ml-1">
                    <div class="fs-large font-weight-bold">
                      {{ lang["TEXT_252"] }}
                    </div>
                    <div>
                      {{ lang["TEXT_253"] }}
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-sheet>

        <v-sheet
          :style="{
            borderRadius: '20px',
          }"
          color="var(--color-bg-card)"
          class="mt-3"
        >
          <div class="pa-3">
            <v-row no-gutters>
              <v-col cols="12">
                <div class="font-weight-semibold mt-3">
                  {{ lang["TEXT_246"] }}
                </div>
              </v-col>
              <v-col cols="8" class="mt-2">
                <v-text-field
                  type="number"
                  hide-details=""
                  outlined
                  placeholder="0.00"
                  hide-spin-buttons
                  background-color="white"
                  dense
                  v-model="amount"
                  :style="{
                    borderRadius: '20px !important',
                    width: '95%',
                    backgroundColor: '#FFFFFF !important',
                  }"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="mt-2 d-flex justify-start align-center">
                <v-btn
                  depressed
                  x-large
                  class="text-none"
                  color="white"
                  width="100%"
                  :style="{
                    borderRadius: '30px !important',
                    border: '1px solid #eaecf0 !important',
                    height: '40px !important',
                  }"
                  @click="chooseToken('open_box')"
                >
                  <v-avatar size="var(--size-icon-x-large)">
                    <v-img contain :src="coin.image"></v-img>
                  </v-avatar>
                  <span class="ml-3 font-weight-bold fs">
                    {{ coin.symbol }}
                  </span>
                  <v-icon size="var(--size-icon-large)" class="ml-3"
                    >mdi-chevron-down</v-icon
                  >
                </v-btn>
              </v-col>
              <v-col cols="12" class="fs-small">
                <div class="mt-2">
                  <span class="font-weight-regular">
                    {{ lang["TEXT_24"] }}:
                  </span>
                  <span
                    :style="`color: var(--color-success)`"
                    class="font-weight-bold"
                    >{{ formatPrice(coin?.myAmount) }} {{ coin.symbol }}</span
                  >
                </div>
              </v-col>
            </v-row>
          </div>
        </v-sheet>

        <v-row no-gutters>
          <v-col cols="12">
            <div class="mt-6 fs">
              <v-row no-gutters>
                <v-col cols="6" class="font-weight-regular">
                  {{ lang["TEXT_265"] }}:
                </v-col>
                <v-col cols="6" class="text-end font-weight-semibold">
                  {{ formatPrice(statusCode.price_open_box / coin.price) }}
                  {{ coin.symbol }}
                  <span class="text-o-8"
                    >~ {{ statusCode.price_open_box }} USD</span
                  >
                </v-col>

                <v-col cols="6" class="font-weight-regular">
                  {{ lang["TEXT_266"] }}:
                </v-col>
                <v-col cols="6" class="text-end font-weight-semibold">
                  {{ formatPrice(amount) || lang["TEXT_267"] }}
                </v-col>

                <v-col cols="6" class="font-weight-regular">
                  {{ lang["TEXT_268"] }}:
                </v-col>
                <v-col cols="6" class="text-end font-weight-semibold">
                  {{
                    formatPrice(
                      Number(amount) * (statusCode.price_open_box / coin.price)
                    ) || lang["TEXT_267"]
                  }}
                  {{ coin.symbol }}
                </v-col>
              </v-row>
              <!-- <v-row no-gutters>
                <v-col cols="6" class="font-weight-regular"> Số lượng: </v-col>
                <v-col cols="6" class="text-end font-weight-semibold">
                  10
                </v-col>

                <v-col cols="6" class="font-weight-regular">
                  Tổng thanh toán:
                </v-col>
                <v-col cols="6" class="text-end font-weight-semibold">
                  10
                </v-col>

                <v-col cols="6" class="font-weight-regular"> Giá 1 NFT: </v-col>
                <v-col cols="6" class="text-end font-weight-semibold">
                  10
                </v-col>
              </v-row> -->
            </div>
          </v-col>
        </v-row>

        <v-btn
          @click="buyBox()"
          :disabled="disabled()[0] || isLoadingBuyBox"
          block
          depressed
          class="mt-3 text-none border-radius--mixed fs"
          color="var(--color-wl-sub)"
          large
          :style="{ color: 'white' }"
          >{{ isLoadingBuyBox ? lang["TEXT_73"] : lang["TEXT_247"] }}</v-btn
        >

        <div class="mt-2 error--text text-center font-weight-semibold">
          {{ disabled()[1] }}
        </div>
      </div>
    </v-sheet>
  </v-sheet>
</template>

<script lang="ts">
import Vue from "vue";
const Joi = require("joi");

import {GameMiningApi} from "@/api/GAME_MINING";
const gameMiningApi = new GameMiningApi();

export default Vue.extend({
  props: [
    "chooseToken",
    "lang",
    "coin",
    "coinChains",
    "formatPrice",
    "size",
    "isLoadingCoinChain",
    "close",
    "refreshTokenAction",
    "notificationCall",
    "updateBalance",
    "copy",
    "coins",
    "statusCode",
    "updateUser",
    "user"
  ],
  data: () => ({
    isLoading: false,
    amount: "" as number | string,
    schema: null as any,
    isLoadingBuyBox: false,
  }),
  created() {
    const schema = Joi.object({
      amount: Joi.number().integer().required().greater(0).messages({
        "number.base": this.lang["TEXT_263"],
        "number.integer": this.lang["TEXT_264"],
        "any.required": this.lang["TEXT_263"],
        'number.greater': this.lang["TEXT_264"],
      }),
    });

    this.schema = schema;
  },
  mounted() {},
  methods: {
    disabled(): [boolean, string] {
      const validation = this.schema.validate({
        amount: this.amount,
      });

      if (validation.error) {
        return [true, validation.error.details[0].message];
      }

      if (
        Number(this.amount) *
          (this.statusCode.price_open_box / this.coin.price) >
        this.coin.myAmount
      ) {
        return [true, this.lang["TEXT_107"]];
      }

      return [false, ""];
    },
    async buyBox() {
      this.isLoadingBuyBox = true;
      const accessToken = await this.refreshTokenAction();
      const call = await gameMiningApi.buyBox(this.amount, this.coin._id, accessToken);
      if(call.success) {
        this.updateUser('amountBoxNft', this.user.amountBoxNft + this.amount)
        this.updateBalance(this.coin.symbol, call.data.amountPriceNFT, '-')
        this.notificationCall(this.statusCode[call.message] ?? call.message);
        this.isLoadingBuyBox = false;
        this.amount = ""
      }
      else {
        this.notificationCall(this.statusCode[call.message] ?? call.message);
        this.isLoadingBuyBox = false;
      }
    },
  },
});
</script>

