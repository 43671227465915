var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"square xyz-in text-start",style:({
    boxShadow: 'none !important',
    borderRadius: _vm.typeMap == 'wallet' || _vm.typeMap == 'game' ? '15px 15px 0px 0px' : '15px',
  }),attrs:{"xyz":"inherit down","color":"var(--color-bg-card)","width":_vm.typeMap == 'wallet' || _vm.typeMap == 'game' ? '100%' : _vm.size.onSize > 450 ? 400 : _vm.size.onSize}},[_c('v-toolbar',{staticClass:"none-boxShadow",style:({
      borderRadius: '15px',
    }),attrs:{"color":"var(--color-bg-card)"}},[_c('v-toolbar-title',{staticClass:"fs-x-large font-weight-bold d-flex justify-center text-center text-black",style:({
        width: '100%',
      })},[_vm._v(" "+_vm._s(_vm.typeChooseToken == "from" ? _vm.lang["TEXT_70"] : _vm.lang["TEXT_71"])+" ")])],1),_c('v-sheet',{staticClass:"pa-2",style:({
      borderRadius:
        _vm.typeMap == 'wallet' || _vm.typeMap == 'game'
          ? '15px 15px 0px 0px'
          : '15px',
    }),attrs:{"color":"white"}},[_c('v-text-field',{staticClass:"mt-3",style:({
        backgroundColor: '#FFFFFF !important',
      }),attrs:{"placeholder":_vm.lang['TEXT_16'],"outlined":"","hide-details":"","dense":"","append-icon":"mdi-magnify"}}),_c('div',{staticClass:"scroll-list--token font-manrope"},_vm._l((_vm.coins),function(item){return _c('div',{key:item._id,staticClass:"mt-3 cursor-pointer",on:{"click":function($event){return _vm.clickChooseToken(item)}}},[_c('ItemCoinComponent',{attrs:{"image":item.image,"name":item.name,"symbol":item.symbol,"price":item.price,"CHANGEPCT24HOUR":item.CHANGEPCT24HOUR,"formatPrice":_vm.formatPrice,"myAmount":item.myAmount,"lang":_vm.lang,"type":"choose"}})],1)}),0)],1),_c('div',{staticClass:"d-flex justify-center pt-2 pb-2"},[_c('v-avatar',{staticClass:"cursor-pointer",style:({
        borderRadius: '10px !important',
      }),attrs:{"tile":"","size":"35","color":"var(--color-black1)"},on:{"click":function($event){return _vm.closeToken()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1),_vm._v(" . ")],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }