export default {
    lang: "en",
    LOGIN_200: "Successful login",
    SWAP_OK_200: "Conversion of _amountFrom _symbolFrom to _amountTo _symbolTo successful",
    TRANSFERS_OK_200: "Successfully transferred _amount _symbol to _email account. (Fee: _fee _symbol)",
    WITHDRAW_OK_200: "Withdrawal of _amount _symbol successful, please wait for our confirmation. (Fee: _fee _symbol)",

    UNAUTHORIZED_400: "Unauthorized",
    ERROR_MINIMUM_SWAP_400: "Please convert minimum _maximumswap _symbol",
    ERROR_BALANCE_NO_400: "Not enough _symbols to trade.",
    ERROR_AMOUNT_400: "Please enter quantity",
    ERROR_DES_400: "Invalid note",
    ERROR_MINIMUM_400: "Please enter minimum amount.",
    ERROR_MAXIMUM_400: "Please enter the maximum amount.",
    ERROR_SWAP_SAME_400: "Do not swap 2 identical coins",
    ERROR_NO_SWAP_400: "Unable to swap at this time, please try again later.",
    SESSION_EXPIRED_FE: "The session being imported has expired.",
    SERVER_400: "Error connecting to the server, please try again",
    ERROR_NETWORK_400: "There is a problem related to the Blockchain network, please try again later",
    ERROR_WALLET_400: "We couldn't find a deposit wallet for you, please try again or contact the system for support",
    ERROR_TRANSFERS_400: "Unable to transfer _symbol at this time, please try again later",
    ERROR_TRANSFERS_EMAIL_SAME_400: "Do not send money to yourself",
    ERROR_TRANSFERS_EMAIL_EXIST_400: "No money transfer account found, please check again",
    ERROR_WITHDRAW_400: "Unable to withdraw _symbol at this time, please try again later",
    INVALID_CREDENTIALS_401: "Invalid credentials",
    ERROR_SERVER2_400: "There seems to be a problem with the system, please reload the page,",

    NOT_VALID_403: "Invalid",

    TEXT_1: "Send",
    TEXT_2: "Receive",
    TEXT_3: "Swap",
    TEXT_4: "History",
    TEXT_5: "Total balance",
    TEXT_6: "Wallet assets",
    TEXT_7: "Find the token you want",

    TEXT_8: "Asset Allocation",
    TEXT_9: "Available",
    TEXT_10: "Withdrawing",
    TEXT_11: "Unavailable",
    TEXT_12: "Waiting for confirmation",

    TEXT_13: "History",
    TEXT_14: "All",

    TEXT_15: "Select Coin/Token",
    TEXT_16: "Search for coins",
    TEXT_17: "Choose net",
    TEXT_18: "Select network",
    TEXT_19: "Deposit cryptocurrency",
    TEXT_20: "Deposit address",
    TEXT_21: "Recently deposited funds",
    TEXT_22: "See more",
    TEXT_23: "Coin list",

    TEXT_24: "Balance",
    TEXT_25: "Sell",
    TEXT_27: "Buy",
    TEXT_26: "Anytime, anywhere.",
    TEXT_28: "Scale",
    TEXT_29: "Token List",
    TEXT_30: "Please enter keywords",
    TEXT_31: "Email",
    TEXT_32: "Next",
    TEXT_33: "Login",
    TEXT_34: "or",


    TEXT_35: "Please enter email",
    TEXT_36: "Sign in with Apple",
    TEXT_37: "Under maintenance",
    TEXT_38: "Login Demo",

    TEXT_39: "Home",
    TEXT_40: "Report",
    TEXT_41: "Wallet",
    TEXT_43: "Explore",
    TEXT_42: "Settings",
    TEXT_44: "Return",

    TEXT_45: "Notification",
    TEXT_46: "Sorry you can't load _symbol at the moment, please come back later.",
    TEXT_47: "Confirm",
    TEXT_48: "It seems our system is having problems, please try again or report to admin so it can be fixed, sorry for the inconvenience",
    TEXT_49: "Select network",
    TEXT_50: "Minimum deposit",
    TEXT_51: "Please note, only networks supported on the platform will be displayed. If you send via another network, your assets may be lost.",
    TEXT_52: "Completion time",
    TEXT_53: "minutes",
    TEXT_54: "Network",

    TEXT_55: "First, please select a network",
    TEXT_56: "Load address",
    TEXT_57: "Minimum deposit amount",
    TEXT_58: "Only send _symbol to this address, sending other currencies or Tokens to this address may cause you to lose coins and Tokens that cannot be recovered.",
    TEXT_59: "Loading...",
    TEXT_60: "Share",
    TEXT_61: "Word",
    TEXT_62: "You sell",
    TEXT_63: "Select Token",
    TEXT_64: "Max",
    TEXT_65: "You bought",
    TEXT_66: "Swap",
    TEXT_67: "Transaction",
    TEXT_68: "Exchange rate",

    TEXT_69: "The exchange rate may change when you execute a swap order due to market exchange rate fluctuations",
    TEXT_70: "Tokens want to sell",
    TEXT_71: "Tokens want to buy",
    TEXT_72: "Minimum conversion",
    TEXT_73: "Swaping...",
    TEXT_74: "Send via cryptocurrency network",
    TEXT_75: "Send crypto to another crypto address via cryptocurrency network",
    TEXT_76: "Send via email/ID",
    TEXT_77: "Send to system users. Easy and fast.",
    TEXT_78: "Unable to withdraw _symbol at this time, please try again later.",
    TEXT_79: "Enter Email used in the system.",
    TEXT_80: "You will send",
    TEXT_81: "Add note for recipient",
    TEXT_82: "Optional",
    TEXT_83: "Sent to system account via email. Money returned to account immediately. Refund not supported.",
    TEXT_84: "Transaction fee",
    TEXT_85: "Free",
    TEXT_86: "Account will receive",
    TEXT_87: "Minimum send",
    TEXT_89: "Money transfer",
    TEXT_90: "Unable to withdraw _symbol at this time, please try again later.",
    TEXT_91: "Please note that only sending Crypto via the above networks is supported. If you send via an unsupported network, your assets will be lost.",
    TEXT_92: "Withdrawal fee",
    TEXT_93: "Minimum withdrawal",
    TEXT_94: "Unranked",
    TEXT_95: "Max withdrawal",
    TEXT_96: "Select withdrawal network",
    TEXT_97: "Withdrawal",

    TEXT_98: "Wallet address to transfer",
    TEXT_99: "Network to send",
    TEXT_100: "Token you want to withdraw",
    TEXT_101: "Enter the wallet address to transfer",
    TEXT_102: "Withdrawal fee",
    TEXT_103: "Each network will have different fees depending on the blockchain network",
    TEXT_104: "Note: the wallet address must be in a format common to the network, for example for EVM, the first is 0x, etc. Please be careful before sending transactions",
    TEXT_105: "Max send",
    TEXT_106: "Wallet address to receive",
    TEXT_107: "Not enough balance",
    TEXT_108: "Please enter balance",
    TEXT_109: "Enter minimum _amount _symbol",
    TEXT_110: "Enter maximum _amount _symbol",
    TEXT_111: "Please enter the receiving wallet address",
    TEXT_112: "The withdrawal fee will be deducted directly from the deposit amount. Therefore, the total amount you enter (MINUS) the withdrawal fee must be > 0",
    TEXT_113: "Please enter email",
    TEXT_114: "Description must be less than 50 characters",
    TEXT_115: "Activity history",

    TEXT_116: "Withdraw money",
    TEXT_117: "Waiting",
    TEXT_118: "Successed",
    TEXT_119: "Refused to process",
    TEXT_120: "Conversion",
    TEXT_121: "Deposit",
    TEXT_122: "No data recorded",
    "ERROR_GAME_NOT_HARVESTS_400": "Harvest time not yet",
    TEXT_123: "Durian tree",
    TEXT_124: "Orange tree",
    TEXT_125: "Avocado tree",
    TEXT_126: "Free tree that harvests sweet shroud oranges.",
    TEXT_127: "Rare durian variety brings high profits.",
    TEXT_128: "A medium-sized avocado tree, yielding the main green avocados.",
    TEXT_129: "Going to harvest",
    TEXT_130: "Plant Shop",
    TEXT_131: "Farm",
    TEXT_132: "Store",
    TEXT_133: "Earn",
    TEXT_134: "Friends",
    TEXT_135: "Wallet",

    TEXT_136: "Buy now",
    TEXT_137: "Price",
    TEXT_138: "Mango",
    TEXT_139: "Durian",
    TEXT_140: "Butter",
    TEXT_141: "Orange",
    TEXT_142: "Send",
    "ERR_SEND_MISSION_VALID_400": "Invalid mission, please reload the page.",
    "ERR_SEND_MISSION_NOT_AMOUNT_400": "The amount of fruit is not enough to submit.",
    TEXT_143: "Mango tree",
    TEXT_144: "Mining game",
    TEXT_145: "See more",
    TEXT_146: "Asset",
    TEXT_147: "Hello",
    TEXT_148: "Number of _symbols",
    TEXT_149: "Minimum",

    TEXT_150: "Completion time",
    TEXT_151: "1 - 5 seconds",
    TEXT_152: "Transaction fees",
    TEXT_153: "Tokens want to transfer",
    TEXT_154: "Wallet connection",
    TEXT_155: "You will be able to connect your AZG Pay wallet to your <b>_email</b> account, the method will be performed only once",
    TEXT_156: "Reject",
    TEXT_157: "Connect",
    TEXT_158: "Connecting",
    TEXT_159: "Invite friends to receive many gifts",
    TEXT_160: "Build a strong team and make money!",
    TEXT_161: "Loading game data",
    TEXT_162: "Need _amount of _fruit for charity",
    TEXT_163: "Send now",
    TEXT_164: "Processing...",
    TEXT_165: "Task",
    TEXT_166: "Warehouse",
    TEXT_167: "Total game assets",
    TEXT_168: "1 main transaction TOKEN in the game",
    TEXT_169: "The profit this tree brings is very high",
    TEXT_170: "One free fruit",
    TEXT_171: "A mid-range fruit also brings very high profits",
    TEXT_172: "Fruit warehouse",
    TEXT_173: "Fruit garden",
    TEXT_174: "Existing",

    "ERR_BUY_EXIST_FRUIT_400": "This fruit cannot be purchased at this time.",
    "ERR_CODE1_400": "You have already purchased this fruit.",
    "ERR_CODE2_400": "Insufficient balance to purchase.",
    TEXT_175: "Top wins",
    TEXT_176: "Lucky Weel",
    "ERR_CODE3_400": "Referer does not exist.",
    TEXT_177: "Number of referrers",
    TEXT_178: "STT",
    TEXT_179: "Account name",
    TEXT_180: "Registration date",
    TEXT_181: "Total referral commission",
    TEXT_182: "Introduction link",
    TEXT_183: "Code",
    TEXT_184: "Invite friends",
    TEXT_185: "Invite friends now, receive attractive gifts",
    TEXT_186: "About statistics",
    TEXT_187: "Total referrals and rewards",
    TEXT_188: "Revenue history",
    TEXT_189: "Overview of referrals and referral profits",
    TEXT_190: "No referral history found",
    TEXT_191: 'Search',
    TEXT_192: 'Earn Tokens Now',
    TEXT_193: 'Invite friends to join the mission',
    TEXT_194: "Build a strong team and make money!",
    TEXT_195: "Tasks to do",
    TEXT_196: "Complete these tasks to get more Tokens",
    "FOLLOW_US_ON_YOUTUBE": "Subscribe to Youtube channel",
    "FOLLOW_US_ON_FACEBOOK": "Subscribe to Facebook channel",
    "FOLLOW_US_ON_TELEGRAM": "Subscribe to Telegram channel",
    TEXT_197: "Invite friends",
    TEXT_198: "There are currently no missions",
    TEXT_199: "Completed",
    TEXT_200: "Community",
    TEXT_201: "Receive",

    "ERR_CODE4_400": "Task does not exist.",
    "ERR_CODE5_400": "Please do the quest to receive the reward.",
    "ERR_CODE6_400": "You have already completed this quest.",
    TEXT_202: "Name",
    TEXT_203: "Total",
    TEXT_204: "You",
    TEXT_205: "Not owned yet",
    TEXT_206: "Sell",
    TEXT_207: "Buy",
    TEXT_208: "No Tokens found",
    "ERR_CODE7_400": "The number of fruits is not enough.",
    TEXT_209: "Fruit",
    TEXT_210: "Quantity",
    TEXT_211: 'Add',
    TEXT_212: 'Price',
    TEXT_213: 'Fee',
    TEXT_214: 'Sale list',
    TEXT_215: 'Cancel',
    TEXT_216: 'Purchase List',

    t_orange: 'Orange garden',
    t_durian: 'Durian garden',
    t_butter: 'Avocado garden',
    t_mango: 'Mango garden',
    t_kiwi: 'Kiwi garden',
    t_lemon: 'Lemon garden',
    t_grapefruit: 'Grapefruit garden',
    t_guava: 'Guava garden',
    t_peach: 'Peach garden',
    t_tomato: 'Tomato garden',


    n_orange: 'Orange',
    n_durian: 'Durian',
    n_butter: 'Butter',
    n_mango: 'Mango',
    n_kiwi: 'Kiwi',
    n_lemon: 'Lemon',
    n_grapefruit: 'Grapefruit',
    n_guava: 'Guava',
    n_peach: 'Peach',
    n_tomato: 'Tomato',

    TEXT_217: 'Loading account data',
    TEXT_218: 'Decompressing images and sounds',
    TEXT_219: 'Loading...',
    TEXT_220: 'Marketplace',
    TEXT_221: 'No items found',
    TEXT_222: 'Sell on the market',
    TEXT_223: 'Amount you want to sell',
    TEXT_224: 'Selling price',
    TEXT_225: 'For sale',
    TEXT_226: 'Buy now',
    TEXT_227: 'Amount you want to buy',
    TEXT_228: 'Buy _amount at _price',
    TEXT_229: 'Buy fruit',
    TEXT_230: 'Assets',
    TEXT_231: 'Searching...',
    "ERR_CODE8_400": 'This transaction has ended because the buyer or seller has canceled the sale.',
    "ERR_CODE9_400": "Cannot buy own transaction",
    TEXT_232: 'Successful purchase',
    TEXT_233: 'Total value',
    TEXT_234: 'Close',
    TEXT_235: 'Swap from _symbolFrom to _symbolTo',
    TEXT_236: 'Loading _symbol successfully',
    TEXT_237: 'Transfer _symbol to _user account (Fee: _fee _symbol)',
    TEXT_238: 'Withdraw _amount _symbol to wallet _address (Fee: _fee _symbol)',
    TEXT_239: 'Receive money',

    TEXT_240: 'Get _symbol from account _user',
    TEXT_241: 'Deposit and withdrawal',
    TEXT_242: 'Transfer money and receive money',
    TEXT_243: 'There are currently no transactions',
    TEXT_244: 'Marketplace',
    TEXT_245: 'Notification',
    TEXT_246: 'NFT box count',
    TEXT_247: 'Payment',

    TEXT_248: 'PLAY',
    TEXT_249: 'NFT',

    TEXT_250: 'OPEN PRE-SALE',
    TEXT_251: "Entering the NFT space to raise funds for crops",
    TEXT_252: "Random NFT gift box",
    TEXT_253: "Gift box gives you random tree NFT. Plant trees together to generate real income.",
    TEXT_254: "Date",
    TEXT_255: "Time",
    TEXT_256: "Minute",
    TEXT_257: "Seconds",
    TEXT_258: "Price per box",
    TEXT_259: "Discount",
    TEXT_260: "Open gift box",
    TEXT_261: "WALLET CONNECTION",
    TEXT_262: "NFT Tokens",
    TEXT_263: "Please enter quantity",
    TEXT_264: "Invalid quantity",
    TEXT_265: "Price of 1 NFT box",
    TEXT_266: "Purchase quantity",
    TEXT_267: "Unknown",
    TEXT_268: "Total payment",
    TEXT_269: "Are you ready to play?",


    TEXT_270: "READY",
    TEXT_271: "BUY NOW",
    TEXT_272: "Buy, Plant and Harvest",
    TEXT_273: "Satisfy your passion for farming, earn unlimited money, simply",
    TEXT_274: "Crop NFTs",
    TEXT_275: "Can't believe what's going to happen next!",
    TEXT_276: "Buy fruit",
    TEXT_277: "Buy Box NFT",
    TEXT_278: "Invalid quantity",
    TEXT_279: "Insufficient quantity",
    TEXT_280: "Invalid price entered",
    TEXT_281: "Please enter selling price",
    TEXT_282: "You have not sold any fruit yet",
    TEXT_283: "Insufficient balance",
    TEXT_284: "Number of tasks in 1 day",
    TEXT_285: "TEST Money",
    TEXT_286: "Buy - Marketplace",
    TEXT_287: "Log out",
    TEXT_289: "Join now",


    'tree_peach': "Peach tree",
    'tree_grapefruit': "Grapefruit tree",
    'tree_mango': "Mango tree",
    'tree_butter': "Avocado tree",
    'tree_kiwi': "Kiwi Tree",
    'tree_durian': "Durian tree",
    'tree_tomato': "Tomato tree",
    'tree_guava': "Guava tree",
    'tree_lemon': "Lemon tree",
    'tree_orange': "Orange tree",

    TEXT_290: "Coin/Token management",
    TEXT_291: "Your search results were not found",
    TEXT_292: "Processing from 1 minute - 6 hours",
    TEXT_293: "Ensure the network matches the withdrawal address and the deposit platform supports it, otherwise your assets may be lost",

    TEXT_294: 'AZGPAY Walet',
    TEXT_295: 'Web3 wallet',
    TEXT_296: 'Find Coin / TOKEN',
    TEXT_297: 'Language',

}