<template>
  <v-sheet
    height="800px"
    :style="{
      borderRadius: '30px 30px 0px 0px',
    }"
    class="d-flex justify-center align-center"
  >
    <center>
      <v-progress-circular
        size="150"
        color="var(--color-wl-main)"
        indeterminate
        class="mt-5"
      >
        <v-avatar size="60" tile>
          <v-img src="@/assets/game_mining/logo_game.png"></v-img>
        </v-avatar>
      </v-progress-circular>

      <div class="mt-5">
        {{ text ?? lang["TEXT_161"] }}
      </div>
    </center>
  </v-sheet>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: ["lang", 'text'],
  data: () => ({
    //
  }),
});
</script>

