var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-sheet',{staticClass:"border",style:({
    borderRadius: '15px',
  })},[_c('div',{staticClass:"pa-3"},[_c('div',{staticClass:"fs-large font-weight-semibold"},[_vm._v("Latest news")]),_c('v-row',{staticClass:"mt-2"},_vm._l((2),function(n){return _c('v-col',{key:n,attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-img',{attrs:{"height":"100%","contain":"","src":require("../../assets/wallet/news.png")}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"7"}},[_c('div',[_c('div',{staticClass:"font-weight-medium"},[_vm._v(" Analysts project 32% upside for Coinbase ")]),_c('div',{staticClass:"mt-3"},[_c('span',{style:({
                    color: 'var(--color-wl-sub)',
                  })},[_vm._v("Finbold")]),_c('v-avatar',{staticClass:"ml-2",attrs:{"color":"#494D58","size":"5"}}),_c('span',{staticClass:"ml-2",style:({
                    color: '#494D58',
                  })},[_vm._v("47min ago")])],1)])])],1)],1)}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }