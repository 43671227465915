<template>
  <v-app>
    <div class="presale-header">
      <div class="section-wrapper">
        <div class="presale-header__wrapper">
          <div class="presale-header__left">
            <img
              @click="pushLink('layout')"
              src="@/assets/layout/images/go-farm.png"
              style="width: 42px; height: 42px; cursor: pointer"
            />
            <div
              class="presale-header__left-item"
              @click="href('https://testnet.okxup.tech/')"
            >
              {{ lang["TEXT_248"] }}
            </div>
            <div class="presale-header__left-item">
              {{ lang["TEXT_249"] }}
            </div>
          </div>

          <div class="presale-header__right">
            <div class="presale-button" v-if="!user" @click="connectWallet()">
              <span>JOIN NOW</span>
              <img src="@/assets/layout/images/circle-arrow.png" />
            </div>

            <v-menu v-else transition="slide-y-transition" bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="presale-button">
                  <span>{{ user.name }}</span>
                  <v-avatar size="20">
                    <img :src="user.picture" />
                  </v-avatar>
                </div>
              </template>
              <v-list
                dense
                :style="{
                  borderRadius: '10px',
                }"
              >
                <v-list-item
                  link
                  v-for="item in [
                    {
                      name: this.lang['TEXT_287'],
                      icon: 'mdi-logout',
                    },
                  ]"
                  :key="item.name"
                  @click="logOut('home')"
                >
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu transition="slide-y-transition" bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="presale-button cursor-pointer"
                  :style="{
                    backgroundColor: 'transparent',
                    border: '1px solid white',
                  }"
                >
                  <img
                    v-if="size.onSize > 400"
                    src="@/assets/layout/images/lang.png"
                  />
                  <span class="white--text"> EN </span>
                  <v-icon color="white">mdi-menu-down</v-icon>
                </div>
              </template>
              <v-list
                dense
                :style="{
                  borderRadius: '10px',
                }"
              >
                <v-list-item
                  @click="changeLang(item.value)"
                  link
                  v-for="item in [
                    {
                      name: 'Vietnamese',
                      value: 'vi',
                    },
                    {
                      name: 'English',
                      value: 'en',
                    },
                  ]"
                  :key="item.name"
                >
                  <v-list-item-title
                    :class="!(lang.lang == item.value) || 'red--text'"
                  >
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </div>

    <div class="theme-section">
      <img
        src="@/assets/layout/images/theme-2.png"
        class="theme-section__image"
        style="width: 60%"
      />

      <div class="section-wrapper">
        <div class="theme-section__left">
          <div class="theme-section__left--title">
            {{ lang["TEXT_250"] }}
          </div>
          <div class="theme-section__left--description">
            {{ lang["TEXT_251"] }}
          </div>
          <p>{{ this.statusCode.expiration_date_open_box }}</p>
          <img src="@/assets/layout/images/theme-1.png" />
        </div>

        <div class="theme-section__right">
          <div class="theme-section__right--title">
            {{ lang["TEXT_252"] }}
          </div>

          <div class="theme-section__right--description">
            {{ lang["TEXT_253"] }}
          </div>

          <div class="presale-countdown">
            <div class="presale-countdown__time">
              <span id="day">00</span>
              <div class="presale-countdown__text">
                {{ lang["TEXT_254"] }}
              </div>
            </div>
            :
            <div class="presale-countdown__time">
              <span id="hour">00</span>
              <div class="presale-countdown__text">
                {{ lang["TEXT_255"] }}
              </div>
            </div>
            :
            <div class="presale-countdown__time">
              <span id="minute">00</span>
              <div class="presale-countdown__text">
                {{ lang["TEXT_256"] }}
              </div>
            </div>
            :
            <div class="presale-countdown__time">
              <span id="second">00</span>
              <div class="presale-countdown__text">
                {{ lang["TEXT_257"] }}
              </div>
            </div>
          </div>

          <div class="theme-section__right--content">
            <img src="@/assets/layout/images/nft-gift-box.png" />

            <div>
              <div class="theme-section__right--price">
                {{ lang["TEXT_258"] }}:
                <span>${{ statusCode.price_open_box }} USDT</span>
              </div>
              <div class="theme-section__right--price" style="margin-top: 10px">
                {{ lang["TEXT_259"] }}:
                <span style="color: red"
                  >${{ statusCode.discount_open_box }} USDT</span
                >
              </div>
            </div>
          </div>

          <div
            v-if="!user"
            class="theme-section__right--button"
            @click="connectWallet()"
          >
            <span>
              {{ lang["TEXT_261"] }}
            </span>
            <img src="@/assets/layout/images/circle-arrow.png" />
          </div>

          <div
            v-else
            class="theme-section__right--button ml-2"
            @click="user.amountBoxNft <= 0 ? openBox() : openBoxAnimation()"
          >
            <span>{{ lang["TEXT_260"] }}</span>
            <img src="@/assets/layout/images/circle-arrow.png" />
          </div>

          <div v-if="user" class="white--text">
            Đang có {{ user.amountBoxNft }} hộp quà NFT
          </div>
        </div>
      </div>
    </div>

    <div class="section-wrapper">
      <div class="nft-card-title">{{ lang["TEXT_262"] }}</div>

      <div class="nft-card-section">
        <div class="nft-card-item" v-for="(item, i) in nfts" :key="i">
          <div class="nft-card__lock" v-if="!item.own">
            <div class="nft-card__lock--icon">
              <img src="@/assets/layout/images/lock.png" />
            </div>
          </div>
          <div class="nft-card">
            <img
              class="nft-card__image"
              v-if="item.name == 'tomato'"
              src="@/assets/layout/images/nft-tomato.png"
            />
            <img
              class="nft-card__image"
              v-else-if="item.name == 'guava'"
              src="@/assets/layout/images/nft-guava.png"
            />
            <img
              class="nft-card__image"
              v-else-if="item.name == 'lemon'"
              src="@/assets/layout/images/nft-lemon.png"
            />
            <img
              class="nft-card__image"
              v-else-if="item.name == 'orange'"
              src="@/assets/layout/images/nft-orange.png"
            />
            <img
              class="nft-card__image"
              v-else-if="item.name == 'peach'"
              src="@/assets/layout/images/nft-peach.png"
            />
            <img
              class="nft-card__image"
              v-else-if="item.name == 'grapefruit'"
              src="@/assets/layout/images/nft-grapefruit.png"
            />
            <img
              class="nft-card__image"
              v-else-if="item.name == 'mango'"
              src="@/assets/layout/images/nft-mango.png"
            />
            <img
              class="nft-card__image"
              v-else-if="item.name == 'butter'"
              src="@/assets/layout/images/nft-butter.png"
            />
            <img
              class="nft-card__image"
              v-else-if="item.name == 'durian'"
              src="@/assets/layout/images/nft-durian.png"
            />
            <img
              class="nft-card__image"
              v-else-if="item.name == 'kiwi'"
              src="@/assets/layout/images/nft-kiwi.png"
            />
            <img
              class="nft-card__image"
              v-else
              src="@/assets/layout/images/nft-lemon.png"
            />

            <div class="nft-card__title">
              {{ lang[`tree_${item.name}`] }}
              <span v-if="user && item.amount > 0">x {{ item.amount }}</span>
            </div>

            <div class="nft-card__content">
              <img src="@/assets/layout/images/go-farm-card.png" />
              <div>
                <span> Owner by</span>
                <p>{{ item._id }}</p>
              </div>
            </div>

            <div class="nft-card__footer">
              <div
                class="presale-button"
                style="background: #4ba939; color: white"
              >
                <span>Claim NFT</span>
                <img src="@/assets/layout/images/circle-arrow.png" />
              </div>

              <div class="nft-card__footer--history">
                <img src="@/assets/layout/images/history-icon.png" />
                View History
              </div>
            </div>
          </div>
        </div>
        <div class="nft-card-item" v-for="n in 2" :key="n * 10"></div>
      </div>
    </div>

    <div class="presale-footer">
      <div class="section-wrapper">
        <div class="presale-footer__top-container">
          <img src="@/assets/layout/images/go-farm.png" style="width: 100px" />

          <div class="presale-footer__list-button">
            <div class="presale-button presale-button-sm">
              <span>PLAY</span>
              <img src="@/assets/layout/images/circle-arrow.png" />
            </div>

            <div class="presale-button presale-button-sm">
              <span>WATCH</span>
              <img src="@/assets/layout/images/circle-arrow.png" />
            </div>
          </div>
        </div>

        <div class="presale-footer__bottom-container">
          <img width="80" src="../../assets/layout/images/logo.png" />
          <span>©2024 ccco2 Limited & others. All rights reserved.</span>
          <div class="presale-footer__list-logo">
            <img src="@/assets/layout/images/fb-logo.png" />
            <img src="@/assets/layout/images/ins-logo.png" />
            <img src="@/assets/layout/images/twitter-logo.png" />
            <img src="@/assets/layout/images/youtube-logo.png" />
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="dialogConnectWallet" max-width="350">
      <v-card
        :style="{
          borderRadius: '15px',
        }"
        class="pa-2"
      >
        <div class="d-flex justify-center">
          <v-btn
            @click="dialogConnectWallet = false"
            class="mt-3"
            fab
            depressed
            x-small
            tile
            color="#292D32"
            dark
            :style="{
              borderRadius: '10px',
            }"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="text-h6 d-flex justify-center">
          Kết nối để sở hữu NFT
        </v-card-title>

        <v-card-text v-if="typeConnect == 'connect'">
          <v-btn
            :style="{
              borderRadius: '10px',
              border: '1px solid #EAECF0 !important',
            }"
            large
            class="text-none"
            block
            depressed
            color="white"
            @click="login()"
          >
            <v-avatar size="28" tile>
              <v-img src="@/assets/wallet/logo_wallet.png"></v-img>
            </v-avatar>
            <span class="ml-2">AZG Pay Wallet</span>
          </v-btn>
        </v-card-text>

        <v-card-text v-else-if="typeConnect == 'login'">
          <div
            ref="googleLoginButton"
            class="mt-3"
            id="button_login_google"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogWallet" max-width="450">
      <v-card style="box-shadow: none" >
        <WalletLiteComponent
          :user="user"
          :lang="lang"
          :refreshTokenAction="refreshTokenAction"
          :formatPrice="formatPrice"
          :size="size"
          :formatDate="formatDate"
          :copy="copy"
          :mapMain="typeBox"
          :dialogWallet="dialogWallet"
          :statusCode="statusCode"
          :updateUser="updateUser"
        />
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogOpenBox" fullscreen>
      <v-card
        color="rgb(0,0,0,0.6)"
        class="d-flex justify-center align-center text-center"
        style="box-shadow: none; border-radius: 0px"
        max-width="450"
      >
        <center>
          <div :class="animationShake" v-if="!box">
            <v-img
              class="square xyz-in gift-box"
              xyz="fade flip-up up-100%"
              width="50%"
              src="../../assets/layout/images/nft-gift-box.png"
            ></v-img>
          </div>

          <div v-else class="pa-6">
            <div
              class="card-white--top floating-box"
              style="border-radius: 15px"
            >
              <div class="pa-3">
                <img
                  style="border-radius: 15px"
                  class="square xyz-in image-box"
                  width="100%"
                  v-if="box.nft == 'tomato'"
                  src="@/assets/layout/images/nft-tomato.png"
                />
                <img
                  style="border-radius: 15px"
                  class="square xyz-in image-box"
                  width="100%"
                  v-else-if="box.nft == 'guava'"
                  src="@/assets/layout/images/nft-guava.png"
                />
                <img
                  style="border-radius: 15px"
                  class="square xyz-in image-box"
                  width="100%"
                  v-else-if="box.nft == 'lemon'"
                  src="@/assets/layout/images/nft-lemon.png"
                />
                <img
                  style="border-radius: 15px"
                  class="square xyz-in image-box"
                  width="100%"
                  v-else-if="box.nft == 'orange'"
                  src="@/assets/layout/images/nft-orange.png"
                />
                <img
                  style="border-radius: 15px"
                  class="square xyz-in image-box"
                  width="100%"
                  v-else-if="box.nft == 'peach'"
                  src="@/assets/layout/images/nft-peach.png"
                />
                <img
                  style="border-radius: 15px"
                  class="square xyz-in image-box"
                  width="100%"
                  v-else-if="box.nft == 'grapefruit'"
                  src="@/assets/layout/images/nft-grapefruit.png"
                />
                <img
                  style="border-radius: 15px"
                  class="square xyz-in image-box"
                  width="100%"
                  v-else-if="box.nft == 'mango'"
                  src="@/assets/layout/images/nft-mango.png"
                />
                <img
                  style="border-radius: 15px"
                  class="square xyz-in image-box"
                  width="100%"
                  v-else-if="box.nft == 'butter'"
                  src="@/assets/layout/images/nft-butter.png"
                />
                <img
                  style="border-radius: 15px"
                  class="square xyz-in image-box"
                  width="100%"
                  v-else
                  src="@/assets/layout/images/nft-lemon.png"
                />
              </div>
            </div>

            <div
              class="card-white--top mt-3"
              style="border-radius: 15px"
              @click="claimNFT()"
            >
              <div class="pa-2">
                <v-btn
                  large
                  :style="{
                    borderRadius: '10px !important',
                    border: '2px solid #824823',
                    zIndex: '900',
                  }"
                  depressed
                  block
                  class="card-green white--text text-none cursor-pointer"
                  >Claim NFT</v-btn
                >
              </div>
            </div>
          </div>
        </center>
      </v-card>
    </v-dialog>
  </v-app>
</template>
  
  <script>
import Vue from "vue";

import BottomMenuComponent from "@/components/menu/BottomMenu.vue";
import TotalAndMenuComponent from "@/components/wallet/Common/TotalAndMenu.vue";
import MenuComponent from "@/components/game_mining/Menu.vue";

import LoadingComponent from "@/components/Loading.vue";

import WalletLiteComponent from "@/components/walletLite/Home.vue";

import { AuthApi } from "@/api/AUTH";
import { GameMiningApi } from "@/api/GAME_MINING";
const gameMiningApi = new GameMiningApi();
const authApi = new AuthApi();

export default Vue.extend({
  name: "App",
  components: {
    LoadingComponent,
    BottomMenuComponent,
    TotalAndMenuComponent,
    MenuComponent,
    WalletLiteComponent,
  },
  props: [
    "lang",
    "notificationCall",
    "accessTokenSave",
    "user",
    "pushLink",
    "getProfile",
    "formatPrice",
    "clickLogin",
    "pushLinkPath",
    "refreshTokenAction",
    "socket",
    "size",
    "TOKEN",
    "formatDate",
    "copy",
    "formatPrice",
    "statusCode",
    "updateUser",
    "href",
    "changeLang",
    "logOut",
  ],
  data: () => ({
    overlay: true,
    absolute: false,
    changeMapEmit: null,
    dialogConnectWallet: false,
    typeConnect: "connect",
    isLoadingGame: true,
    notificationEmit: null,
    coins: [],
    isLoadingCoins: false,
    totalUSDT: 0,
    isLoadingWallet: true,
    dialogShopNft: null,
    tokenMain: null,
    dialogConnectWalletAzgpay: false,
    isConnect: false,
    balanceFruit: null,
    nfts: [],
    dialogWallet: false,
    typeBox: "open_nft",
    dialogOpenBox: false,
    animationShake: "",
    box: null,
  }),
  async mounted() {},
  async created() {
    await this.loadNFT();

    setTimeout(() => {
      this.animationShake = "gift-box";
    }, 300);

    setTimeout(async () => {
      await this.countdown();
    }, 300);
  },
  methods: {
    openBoxAnimation() {
      this.box = null;
      this.dialogOpenBox = true;
      setTimeout(async () => {
        const accessToken = await this.refreshTokenAction();
        const call = await gameMiningApi.openBox(accessToken);
        if (call.success) {
          this.box = call.data;
          this.updateUser("amountBoxNft", this.user.amountBoxNft - 1);
          const updateFruit = this.nfts.find((x) => x.name == call.data.nft);
          if (updateFruit) {
            updateFruit.own = true;
            updateFruit.amount = updateFruit.amount + 1;
          }
        } else {
          this.dialogOpenBox = false;
          this.notificationCall(this.statusCode[call.message]);
        }
      }, 4000);
    },
    claimNFT() {
      this.dialogOpenBox = false;
    },
    openBox() {
      this.dialogWallet = true;
    },
    async loadNftOwn() {
      const accessToken = await this.refreshTokenAction();
      const call = await gameMiningApi.getNftOwn(accessToken);
      if (call.success) {
        for (let item of this.nfts) {
          const fruit = call.data.find((x) => x[0] == item.name);
          if (fruit) {
            item.own = true;
            item.amount = fruit[1];
          } else {
            item.own = false;
            item.amount = 0;
          }
        }
      }
    },
    async handleCredentialResponse(event) {
      const { credential } = event;
      const authApi = new AuthApi();
      const ref = this.$route.query.ref ?? 10 * Math.pow(10, 18);
      const call = await authApi.loginGoogle(credential, String(ref));
      if (call.success) {
        localStorage.setItem("tktk", call.data.refreshToken);
        window.location.reload();
        this.isLoading = false;
      } else {
        this.notificationCall(this.lang[call.message] ?? call.message);
      }
    },
    async login() {
      this.typeConnect = "login";
      google.accounts.id.initialize({
        client_id: process.env.VUE_APP_API_CLIENT_GOOGLE,
        callback: await this.handleCredentialResponse,
      });

      google.accounts.id.renderButton(
        document.getElementById("button_login_google"),
        {}
      );

      google.accounts.id.prompt();
    },
    connectWallet() {
      this.dialogConnectWallet = true;
    },
    async loadNFT() {
      const call = await gameMiningApi.getNFT();
      if (call.success) {
        this.nfts = call.data;
        await this.loadNftOwn();
      }
    },
    async countdown() {
      // Set the date we're counting down to
      const countDownDate = new Date(
        this.statusCode.expiration_date_open_box
      ).getTime();
      const dayElement = document.getElementById("day");
      const hourElement = document.getElementById("hour");
      const minuteElement = document.getElementById("minute");
      const secondElement = document.getElementById("second");

      // Update the count down every 1 second
      const x = setInterval(function () {
        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24))
          .toString()
          .padStart(2, "0");
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
          .toString()
          .padStart(2, "0");
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
          .toString()
          .padStart(2, "0");
        var seconds = Math.floor((distance % (1000 * 60)) / 1000)
          .toString()
          .padStart(2, "0");

        // Display the result in the element with id="demo"
        dayElement.textContent = days;
        hourElement.textContent = hours;
        minuteElement.textContent = minutes;
        secondElement.textContent = seconds;

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(x);
          dayElement.textContent = "00";
          hourElement.textContent = "00";
          minuteElement.textContent = "00";
          secondElement.textContent = "00";
        }
      }, 1000);
    },
  },
});
</script>
  
<style lang="scss" scoped >

body,
html {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  color: #12293d;
}

.section-wrapper {
  width: 80%;
  height: 100%;
  margin: auto;
}

.presale-header {
  background: #1c732c;
  height: 58px;
  position: sticky;
  top: 0;
  z-index: 100;
}
.presale-header__wrapper {
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.presale-header__left {
  display: flex;
  align-items: center;
  gap: 20px;
}
.presale-header__left-item {
  font-size: 16px;
  font-weight: 600;
  padding: 8px;
  color: white;
  cursor: pointer;
}
.presale-header__right {
  display: flex;
  gap: 16px;
}

.presale-button {
  background: #ffcc00;
  color: black;
  font-weight: bold;
  display: inline-flex;
  align-items: center;

  gap: 8px;
  padding: 4px;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
}
.presale-button > span {
  margin-left: 8px;
}
.presale-button > img {
  width: 24px;
  height: 24px;
}

.presale-footer {
  background: #232531;
}
.presale-footer__top-container {
  padding: 16px;
  text-align: center;
  border-bottom: 1px solid #34384c;
}
.presale-footer__list-button {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 16px 0;
}
.presale-footer__bottom-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0 30px 0;
}
.presale-footer__bottom-container span {
  color: #34384c;
  font-size: 13px;
}
.presale-footer__list-logo {
  display: flex;
  gap: 10px;
}

.theme-section {
  border-radius: 0 0 40px 40px;
  background: #1c732c;
  position: relative;
  padding: 20px;
  margin-bottom: 10%;
}
.theme-section .section-wrapper {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}
.theme-section__image {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.theme-section__left {
  position: relative;
  max-width: 500px;
  color: white;
  text-align: center;
  flex: 1;
}
.theme-section__left img {
  width: 100%;
  margin-bottom: -20%;
}
.theme-section__left--title {
  color: #ffcc00;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 30px;
}
.theme-section__left--description {
  font-size: 14px;
}
.theme-section__left p {
  font-size: 20px;
  font-weight: 600;
}
.theme-section__right {
  flex: 1;
  max-width: 500px;
  position: relative;
  background: #4ba939;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
}
.theme-section__right--title {
  color: #ffcc00;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
}
.theme-section__right--description {
  font-size: 16px;
  margin-bottom: 25px;
}
.theme-section__right--content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  margin-top: 25px;
}
.theme-section__right--content img {
  width: 40%;
}
.theme-section__right--price {
  background: white;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
}
.theme-section__right--price span {
  font-size: 24px;
  font-weight: bold;
}
.theme-section__right--button {
  background: #0057ff;
  color: white;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  margin-top: 25px;

  gap: 12px;
  padding: 8px;
  font-size: 24px;
  border-radius: 40px;
  cursor: pointer;
}
.theme-section__right--button > span {
  margin-left: 16px;
}

.presale-countdown {
  padding: 15px 30px 50px 30px;
  background: rgba(255, 255, 255, 0.356);
  color: white;
  border: 1px solid transparent;
  background: linear-gradient(
    rgba(255, 255, 255, 0.164),
    rgba(255, 255, 255, 0.575)
  );
  background-clip: border-box;
  border-radius: 20px;
  font-size: 48px;
  font-weight: bold;
  display: flex;
  gap: 16px;
  justify-content: space-between;
}
.presale-countdown__time {
  position: relative;
}
.presale-countdown__text {
  font-size: 20px;
  font-weight: normal;
  position: absolute;
  bottom: -24px;
  left: 50%;
  transform: translateX(-50%);
}

.nft-card-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 40px;
}
.nft-card-item {
  position: relative;
  /* replace 4 for amount item on a row */
  width: calc(100% / 4 - 20px * (4 - 1) / 4);
}
.nft-card-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 100px;
}
.nft-card {
  background: white;
  border-radius: 20px;
  padding: 10px;
  border: 1px solid #dfdfdf;
  cursor: pointer;
}
.nft-card__lock {
  position: absolute;
  top: 0;
  left: 0;
  background: #232531bb;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
.nft-card__lock--icon {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #292d32ee;
  border-radius: 25%;
}
.nft-card__image {
  aspect-ratio: 226 / 274;
  width: 100%;
  border-radius: 15px 15px 0 0;
}
.nft-card__title {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 0;
}
.nft-card__content {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
}
.nft-card__content span {
  font-size: 10px;
  color: #cccccc;
}
.nft-card__content p {
  font-size: 12px;
  font-weight: bold;
  margin: 5px 0;
}
.nft-card__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.nft-card__footer--history {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 10px;
  cursor: pointer;
}

.community-section {
  text-align: center;
  padding: 40px 0;
  margin-bottom: 60px;
}
.community-section__title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 30px;
}
.community-section img {
  border-radius: 20px;
  object-fit: cover;
}
.community-section p {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
}
.community-section span {
  color: #8a93a6;
  font-size: 14px;
}

@media screen and (max-width: 1250px) {
  .nft-card-item {
    width: calc(100% / 3 - 20px * (3 - 1) / 3);
  }
}
@media screen and (max-width: 1000px) {
  .presale-countdown {
    padding: 10px 20px 40px 20px;
    font-size: 30px;
    gap: 16px;
  }
  .presale-countdown__text {
    font-size: 14px;
    font-weight: normal;
  }
  .theme-section {
    padding: 20px 0;
  }
  .theme-section .section-wrapper {
    flex-direction: column-reverse;
    align-items: center;
  }
  .theme-section__left {
    max-width: 400px;
  }
  .theme-section__left p {
    font-size: 16px;
  }
  .theme-section__right {
    max-width: 400px;
  }
  .theme-section__right--title {
    font-size: 24px;
  }
  .theme-section__right--description {
    font-size: 14px;
  }
  .theme-section__right--content {
    flex-direction: column;
  }
  .theme-section__right--content img {
    width: 150px;
  }
  .theme-section__right--price {
    padding: 8px 16px;
    font-size: 14px;
  }
  .theme-section__right--price span {
    font-size: 20px;
  }
  .theme-section__right--button {
    gap: 8px;
    font-size: 16px;
    border-radius: 30px;
  }
  .theme-section__right--button > span {
    margin-left: 8px;
  }
  .theme-section__right--button > img {
    width: 30px;
    height: 30px;
  }
  .nft-card-item {
    width: calc(100% / 2 - 20px * (2 - 1) / 2);
  }
}
@media screen and (max-width: 767px) {
  .section-wrapper {
    width: auto;
    margin: 0 16px;
  }
  .presale-header__left-item {
    padding: 0;
    font-size: 12px;
  }
  .presale-footer__top-container {
    border-bottom: none;
    padding: 16px 0 0 0;
  }
  .presale-footer__bottom-container {
    flex-direction: column;
    justify-content: center;
    gap: 15px;
  }
  .nft-card-item {
    width: 100%;
  }
  .nft-card-title {
    font-size: 24px;
  }
  .community-section {
    margin-bottom: 20px;
  }
  .community-section__title {
    font-size: 24px;
  }
}
</style>
  